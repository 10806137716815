export const books = [
    {
        title: 'Ship of Magic',
        author: 'Robin Hobb',
        authorLink: 'https://www.goodreads.com/author/show/25307.Robin_Hobb',
        year: '1999',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1360507722l/45100._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/45100.Ship_of_Magic',
        ratingsAvg: '4.13',
        ratingsNumber: '62623',
        rating: '5',
    },
    {
        title: "Assassin's Quest",
        author: 'Robin Hobb',
        authorLink: 'https://www.goodreads.com/author/show/25307.Robin_Hobb',
        year: '2002',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1481883253l/33396914._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/33396914-assassin-s-quest',
        ratingsAvg: '4.17',
        ratingsNumber: '117946',
        rating: '4',
    },
    {
        title: 'Royal Assassin',
        author: 'Robin Hobb',
        authorLink: 'https://www.goodreads.com/author/show/25307.Robin_Hobb',
        year: '2002',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1428234219l/25300956._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/25300956-royal-assassin',
        ratingsAvg: '4.22',
        ratingsNumber: '150238',
        rating: '5',
    },
    {
        title: "Assassin's Apprentice",
        author: 'Robin Hobb',
        authorLink: 'https://www.goodreads.com/author/show/25307.Robin_Hobb',
        year: '1996',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1464570795l/77197._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/77197.Assassin_s_Apprentice',
        ratingsAvg: '4.15',
        ratingsNumber: '234918',
        rating: '0',
    },
    {
        title: 'Tipping the Velvet',
        author: 'Sarah Waters',
        authorLink: 'https://www.goodreads.com/author/show/25334.Sarah_Waters',
        year: '2000',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1425892206l/25104465._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25104465-tipping-the-velvet',
        ratingsAvg: '4.01',
        ratingsNumber: '58260',
        rating: '5',
    },
    {
        title: "The Places I've Cried in Public",
        author: 'Holly Bourne',
        authorLink: 'https://www.goodreads.com/author/show/6561227.Holly_Bourne',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1552657491l/44004352._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/44004352-the-places-i-ve-cried-in-public',
        ratingsAvg: '4.13',
        ratingsNumber: '4288',
        rating: '5',
    },
    {
        title: 'Thinking, Fast and Slow',
        author: 'Daniel Kahneman',
        authorLink: 'https://www.goodreads.com/author/show/72401.Daniel_Kahneman',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1317793965l/11468377._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/11468377-thinking-fast-and-slow',
        ratingsAvg: '4.16',
        ratingsNumber: '308726',
        rating: '3',
    },
    {
        title: '...And a Happy New Year?',
        author: 'Holly Bourne',
        authorLink: 'https://www.goodreads.com/author/show/6561227.Holly_Bourne',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1470213119l/30360631._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/30360631-and-a-happy-new-year',
        ratingsAvg: '3.98',
        ratingsNumber: '2014',
        rating: '4',
    },
    {
        title: 'A Head Full of Ghosts',
        author: 'Paul Tremblay',
        authorLink: 'https://www.goodreads.com/author/show/648612.Paul_Tremblay',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1415678368l/23019294._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23019294-a-head-full-of-ghosts',
        ratingsAvg: '3.81',
        ratingsNumber: '45888',
        rating: '4',
    },
    {
        title: 'We Have Always Lived in the Castle',
        author: 'Shirley Jackson',
        authorLink: 'https://www.goodreads.com/author/show/13388.Shirley_Jackson',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1415357189l/89724._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/89724.We_Have_Always_Lived_in_the_Castle',
        ratingsAvg: '3.99',
        ratingsNumber: '117326',
        rating: '3',
    },
    {
        title: 'Alone',
        author: 'Scott Sigler',
        authorLink: 'https://www.goodreads.com/author/show/114504.Scott_Sigler',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1450301053l/28227834._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28227834-alone',
        ratingsAvg: '4.16',
        ratingsNumber: '1744',
        rating: '4',
    },
    {
        title: 'Alight',
        author: 'Scott Sigler',
        authorLink: 'https://www.goodreads.com/author/show/114504.Scott_Sigler',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1439830983l/25489021._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25489021-alight',
        ratingsAvg: '4.17',
        ratingsNumber: '2699',
        rating: '4',
    },
    {
        title: 'Alive',
        author: 'Scott Sigler',
        authorLink: 'https://www.goodreads.com/author/show/114504.Scott_Sigler',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1428086029l/23278532._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23278532-alive',
        ratingsAvg: '3.71',
        ratingsNumber: '9114',
        rating: '4',
    },
    {
        title: "What's a Girl Gotta Do?",
        author: 'Holly Bourne',
        authorLink: 'https://www.goodreads.com/author/show/6561227.Holly_Bourne',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1464090976l/29740718._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/29740718-what-s-a-girl-gotta-do',
        ratingsAvg: '4.25',
        ratingsNumber: '4609',
        rating: '4',
    },
    {
        title: 'How Hard Can Love Be?',
        author: 'Holly Bourne',
        authorLink: 'https://www.goodreads.com/author/show/6561227.Holly_Bourne',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1447326926l/26255907._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/26255907-how-hard-can-love-be',
        ratingsAvg: '4.15',
        ratingsNumber: '5640',
        rating: '4',
    },
    {
        title: 'Am I Normal Yet?',
        author: 'Holly Bourne',
        authorLink: 'https://www.goodreads.com/author/show/6561227.Holly_Bourne',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1429354198l/23592235._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23592235-am-i-normal-yet',
        ratingsAvg: '4.23',
        ratingsNumber: '11467',
        rating: '4',
    },
    {
        title: 'If It Bleeds',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2020',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1571399830l/46015758._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/46015758-if-it-bleeds',
        ratingsAvg: '4.10',
        ratingsNumber: '33106',
        rating: '2',
    },
    {
        title: 'Resistance is Futile',
        author: 'Jenny T. Colgan',
        authorLink: 'https://www.goodreads.com/author/show/7914032.Jenny_T_Colgan',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1423772993l/23503575._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23503575-resistance-is-futile',
        ratingsAvg: '3.16',
        ratingsNumber: '1166',
        rating: '1',
    },
    {
        title: 'Coffin, Scarcely Used',
        author: 'Colin Watson',
        authorLink: 'https://www.goodreads.com/author/show/50259.Colin_Watson',
        year: null,
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/38650721-coffin-scarcely-used',
        ratingsAvg: '3.71',
        ratingsNumber: '910',
        rating: '0',
    },
    {
        title: "I'm Thinking of Ending Things",
        author: 'Iain Reid',
        authorLink: 'https://www.goodreads.com/author/show/4112760.Iain_Reid',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1529610169l/40605223._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/40605223-i-m-thinking-of-ending-things',
        ratingsAvg: '3.55',
        ratingsNumber: '55303',
        rating: '2',
    },
    {
        title: 'Gingerbread',
        author: 'Helen Oyeyemi',
        authorLink: 'https://www.goodreads.com/author/show/80808.Helen_Oyeyemi',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1549556368l/40634915._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/40634915-gingerbread',
        ratingsAvg: '3.06',
        ratingsNumber: '9397',
        rating: '5',
    },
    {
        title: 'The Tailor',
        author: 'Leigh Bardugo',
        authorLink: 'https://www.goodreads.com/author/show/4575289.Leigh_Bardugo',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1369842991l/17997353._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/17997353-the-tailor',
        ratingsAvg: '3.75',
        ratingsNumber: '11345',
        rating: '3',
    },
    {
        title: 'Taskmaster: 220 Extraordinary Tasks for Ordinary People',
        author: 'Alex Horne',
        authorLink: 'https://www.goodreads.com/author/show/428102.Alex_Horne',
        year: '2018',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/38458407-taskmaster',
        ratingsAvg: '4.28',
        ratingsNumber: '197',
        rating: '4',
    },
    {
        title: 'King of Scars',
        author: 'Leigh Bardugo',
        authorLink: 'https://www.goodreads.com/author/show/4575289.Leigh_Bardugo',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1525110825l/36307634._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/36307634-king-of-scars',
        ratingsAvg: '4.21',
        ratingsNumber: '43912',
        rating: '4',
    },
    {
        title: 'Crooked Kingdom',
        author: 'Leigh Bardugo',
        authorLink: 'https://www.goodreads.com/author/show/4575289.Leigh_Bardugo',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1456172607l/22299763._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22299763-crooked-kingdom',
        ratingsAvg: '4.58',
        ratingsNumber: '194674',
        rating: '4',
    },
    {
        title: 'Six of Crows',
        author: 'Leigh Bardugo',
        authorLink: 'https://www.goodreads.com/author/show/4575289.Leigh_Bardugo',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1459349344l/23437156._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23437156-six-of-crows',
        ratingsAvg: '4.43',
        ratingsNumber: '314752',
        rating: '5',
    },
    {
        title: 'Ruin and Rising',
        author: 'Leigh Bardugo',
        authorLink: 'https://www.goodreads.com/author/show/4575289.Leigh_Bardugo',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1392398530l/14061957._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/14061957-ruin-and-rising',
        ratingsAvg: '4.09',
        ratingsNumber: '154941',
        rating: '3',
    },
    {
        title: 'Siege and Storm',
        author: 'Leigh Bardugo',
        authorLink: 'https://www.goodreads.com/author/show/4575289.Leigh_Bardugo',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1362166252l/14061955._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/14061955-siege-and-storm',
        ratingsAvg: '3.98',
        ratingsNumber: '188880',
        rating: '2',
    },
    {
        title: 'Shadow and Bone',
        author: 'Leigh Bardugo',
        authorLink: 'https://www.goodreads.com/author/show/4575289.Leigh_Bardugo',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1339533695l/10194157._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10194157-shadow-and-bone',
        ratingsAvg: '4.00',
        ratingsNumber: '306365',
        rating: '4',
    },
    {
        title: 'The Ten Thousand Doors of January',
        author: 'Alix E. Harrow',
        authorLink: 'https://www.goodreads.com/author/show/9823112.Alix_E_Harrow',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1548174710l/43521657._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/43521657-the-ten-thousand-doors-of-january',
        ratingsAvg: '4.09',
        ratingsNumber: '44819',
        rating: '3',
    },
    {
        title: 'Sons of Destiny',
        author: 'Darren Shan',
        authorLink: 'https://www.goodreads.com/author/show/5816.Darren_Shan',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1344270002l/8948._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/8948.Sons_of_Destiny',
        ratingsAvg: '4.21',
        ratingsNumber: '19185',
        rating: '1',
    },
    {
        title: '500 Miles from You',
        author: 'Jenny Colgan',
        authorLink: 'https://www.goodreads.com/author/show/39272.Jenny_Colgan',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1577761140l/48748054._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/48748054-500-miles-from-you',
        ratingsAvg: '3.92',
        ratingsNumber: '4888',
        rating: '3',
    },
    {
        title: 'Lord of the Shadows',
        author: 'Darren Shan',
        authorLink: 'https://www.goodreads.com/author/show/5816.Darren_Shan',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/8949.Lord_of_the_Shadows',
        ratingsAvg: '4.22',
        ratingsNumber: '20009',
        rating: '1',
    },
    {
        title: 'The Lake of Souls',
        author: 'Darren Shan',
        authorLink: 'https://www.goodreads.com/author/show/5816.Darren_Shan',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1255988654l/8951._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/8951.The_Lake_of_Souls',
        ratingsAvg: '4.19',
        ratingsNumber: '21055',
        rating: '1',
    },
    {
        title: 'Killers of the Dawn',
        author: 'Darren Shan',
        authorLink: 'https://www.goodreads.com/author/show/5816.Darren_Shan',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/8952.Killers_of_the_Dawn',
        ratingsAvg: '4.23',
        ratingsNumber: '22790',
        rating: '1',
    },
    {
        title: 'Allies of the Night',
        author: 'Darren Shan',
        authorLink: 'https://www.goodreads.com/author/show/5816.Darren_Shan',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1560059486l/8966._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/8966.Allies_of_the_Night',
        ratingsAvg: '4.19',
        ratingsNumber: '23346',
        rating: '1',
    },
    {
        title: 'The Vampire Prince',
        author: 'Darren Shan',
        authorLink: 'https://www.goodreads.com/author/show/5816.Darren_Shan',
        year: '2004',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/8968.The_Vampire_Prince',
        ratingsAvg: '4.23',
        ratingsNumber: '26542',
        rating: '1',
    },
    {
        title: 'Hunters of the Dusk',
        author: 'Darren Shan',
        authorLink: 'https://www.goodreads.com/author/show/5816.Darren_Shan',
        year: '2005',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/8963.Hunters_of_the_Dusk',
        ratingsAvg: '4.17',
        ratingsNumber: '24233',
        rating: '1',
    },
    {
        title: 'The Colored Museum',
        author: 'George C. Wolfe',
        authorLink: 'https://www.goodreads.com/author/show/227548.George_C_Wolfe',
        year: '1994',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/511139.The_Colored_Museum',
        ratingsAvg: '4.16',
        ratingsNumber: '532',
        rating: '4',
    },
    {
        title: 'Trials of Death',
        author: 'Darren Shan',
        authorLink: 'https://www.goodreads.com/author/show/5816.Darren_Shan',
        year: '2008',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/8967.Trials_of_Death',
        ratingsAvg: '4.25',
        ratingsNumber: '27940',
        rating: '1',
    },
    {
        title: 'Vampire Mountain',
        author: 'Darren Shan',
        authorLink: 'https://www.goodreads.com/author/show/5816.Darren_Shan',
        year: '2003',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1255813254l/8960._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/8960.Vampire_Mountain',
        ratingsAvg: '4.16',
        ratingsNumber: '30007',
        rating: '2',
    },
    {
        title: 'Tunnels of Blood',
        author: 'Darren Shan',
        authorLink: 'https://www.goodreads.com/author/show/5816.Darren_Shan',
        year: '2003',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/8957.Tunnels_of_Blood',
        ratingsAvg: '4.14',
        ratingsNumber: '33070',
        rating: '2',
    },
    {
        title: "The Vampire's Assistant",
        author: 'Darren Shan',
        authorLink: 'https://www.goodreads.com/author/show/5816.Darren_Shan',
        year: '2002',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/8965.The_Vampire_s_Assistant',
        ratingsAvg: '4.12',
        ratingsNumber: '38310',
        rating: '2',
    },
    {
        title: 'A Living Nightmare',
        author: 'Darren Shan',
        authorLink: 'https://www.goodreads.com/author/show/5816.Darren_Shan',
        year: '2002',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/8962.A_Living_Nightmare',
        ratingsAvg: '4.07',
        ratingsNumber: '50568',
        rating: '2',
    },
    {
        title: 'Red, White & Royal Blue',
        author: 'Casey McQuiston',
        authorLink: 'https://www.goodreads.com/author/show/17949486.Casey_McQuiston',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1566742512l/41150487._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/41150487-red-white-royal-blue',
        ratingsAvg: '4.23',
        ratingsNumber: '167314',
        rating: '2',
    },
    {
        title: 'The Flatshare',
        author: "Beth O'Leary",
        authorLink: 'https://www.goodreads.com/author/show/13038484.Beth_O_Leary',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1552471375l/36478784._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/36478784-the-flatshare',
        ratingsAvg: '4.12',
        ratingsNumber: '109335',
        rating: '1',
    },
    {
        title: 'The Signature of All Things',
        author: 'Elizabeth Gilbert',
        authorLink: 'https://www.goodreads.com/author/show/11679.Elizabeth_Gilbert',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1560901426l/17465453._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/17465453-the-signature-of-all-things',
        ratingsAvg: '3.84',
        ratingsNumber: '95748',
        rating: '3',
    },
    {
        title: 'Eat, Pray, Love',
        author: 'Elizabeth Gilbert',
        authorLink: 'https://www.goodreads.com/author/show/11679.Elizabeth_Gilbert',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1503066414l/19501._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/19501.Eat_Pray_Love',
        ratingsAvg: '3.57',
        ratingsNumber: '1473966',
        rating: '1',
    },
    {
        title: 'The Endless Beach',
        author: 'Jenny Colgan',
        authorLink: 'https://www.goodreads.com/author/show/39272.Jenny_Colgan',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1522664528l/36300733._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/36300733-the-endless-beach',
        ratingsAvg: '3.92',
        ratingsNumber: '10385',
        rating: '0',
    },
    {
        title: 'The Cafe by the Sea',
        author: 'Jenny Colgan',
        authorLink: 'https://www.goodreads.com/author/show/39272.Jenny_Colgan',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1482127493l/32497580._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/32497580-the-cafe-by-the-sea',
        ratingsAvg: '3.88',
        ratingsNumber: '18364',
        rating: '0',
    },
    {
        title: 'We Are Okay',
        author: 'Nina LaCour',
        authorLink: 'https://www.goodreads.com/author/show/2889003.Nina_LaCour',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1471899036l/28243032._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28243032-we-are-okay',
        ratingsAvg: '3.95',
        ratingsNumber: '47334',
        rating: '3',
    },
    {
        title: 'The Bookish Life of Nina Hill',
        author: 'Abbi Waxman',
        authorLink: 'https://www.goodreads.com/author/show/15958858.Abbi_Waxman',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1541774944l/42379022._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/42379022-the-bookish-life-of-nina-hill',
        ratingsAvg: '3.83',
        ratingsNumber: '52749',
        rating: '1',
    },
    {
        title: 'The Unhoneymooners',
        author: 'Christina Lauren',
        authorLink: 'https://www.goodreads.com/author/show/6556689.Christina_Lauren',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1548201335l/42201431._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/42201431-the-unhoneymooners',
        ratingsAvg: '3.98',
        ratingsNumber: '114564',
        rating: '4',
    },
    {
        title: 'Verity',
        author: 'Colleen Hoover',
        authorLink: 'https://www.goodreads.com/author/show/5430144.Colleen_Hoover',
        year: null,
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/41957126-verity',
        ratingsAvg: '4.32',
        ratingsNumber: '157163',
        rating: '3',
    },
    {
        title: 'Consent: A Memoir of Unwanted Attention',
        author: 'Donna Freitas',
        authorLink: 'https://www.goodreads.com/author/show/19106.Donna_Freitas',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1545153928l/43209280._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/43209280-consent',
        ratingsAvg: '3.88',
        ratingsNumber: '565',
        rating: '3',
    },
    {
        title: 'Do You Mind If I Cancel?',
        author: 'Gary Janetti',
        authorLink: 'https://www.goodreads.com/author/show/7710004.Gary_Janetti',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1563766567l/43263670._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/43263670-do-you-mind-if-i-cancel',
        ratingsAvg: '3.49',
        ratingsNumber: '8509',
        rating: '3',
    },
    {
        title: 'Dear Girls: Intimate Tales, Untold Secrets, & Advice for Living Your Best Life',
        author: 'Ali Wong',
        authorLink: 'https://www.goodreads.com/author/show/19001530.Ali_Wong',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1553635552l/44600621._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/44600621-dear-girls',
        ratingsAvg: '3.90',
        ratingsNumber: '50020',
        rating: '2',
    },
    {
        title: 'The Invited',
        author: 'Jennifer  McMahon',
        authorLink: 'https://www.goodreads.com/author/show/29471.Jennifer_McMahon',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1541824130l/40065317._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/40065317-the-invited',
        ratingsAvg: '3.79',
        ratingsNumber: '13898',
        rating: '2',
    },
    {
        title: 'The Institute',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1549241208l/43798285._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/43798285-the-institute',
        ratingsAvg: '4.20',
        ratingsNumber: '126384',
        rating: '4',
    },
    {
        title: 'The Unhoneymooners',
        author: 'Christina Lauren',
        authorLink: 'https://www.goodreads.com/author/show/6556689.Christina_Lauren',
        year: null,
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/52709482-the-unhoneymooners',
        ratingsAvg: '3.98',
        ratingsNumber: '114564',
        rating: '1',
    },
    {
        title: 'City of Girls',
        author: 'Elizabeth Gilbert',
        authorLink: 'https://www.goodreads.com/author/show/11679.Elizabeth_Gilbert',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1545498817l/42135029._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/42135029-city-of-girls',
        ratingsAvg: '4.04',
        ratingsNumber: '155051',
        rating: '4',
    },
    {
        title: 'My Sister, the Serial Killer',
        author: 'Oyinkan Braithwaite',
        authorLink: 'https://www.goodreads.com/author/show/14713014.Oyinkan_Braithwaite',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1523366732l/38819868._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/38819868-my-sister-the-serial-killer',
        ratingsAvg: '3.74',
        ratingsNumber: '148985',
        rating: '3',
    },
    {
        title: 'The Swallows',
        author: 'Lisa Lutz',
        authorLink: 'https://www.goodreads.com/author/show/74189.Lisa_Lutz',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1553520339l/43744294._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/43744294-the-swallows',
        ratingsAvg: '3.69',
        ratingsNumber: '5559',
        rating: '5',
    },
    {
        title: 'Ninth House',
        author: 'Leigh Bardugo',
        authorLink: 'https://www.goodreads.com/author/show/4575289.Leigh_Bardugo',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1553102141l/43263680._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/43263680-ninth-house',
        ratingsAvg: '4.05',
        ratingsNumber: '86059',
        rating: '4',
    },
    {
        title: 'Fl\u00fcsternde H\u00e4nde',
        author: 'Sherryl Jordan',
        authorLink: 'https://www.goodreads.com/author/show/68871.Sherryl_Jordan',
        year: '2003',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/118763.Fl_sternde_H_nde',
        ratingsAvg: '4.22',
        ratingsNumber: '4265',
        rating: '1',
    },
    {
        title: 'Blanca & Roja',
        author: 'Anna-Marie McLemore',
        authorLink: 'https://www.goodreads.com/author/show/6434877.Anna_Marie_McLemore',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1516986822l/36952596._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/36952596-blanca-roja',
        ratingsAvg: '3.80',
        ratingsNumber: '2866',
        rating: '5',
    },
    {
        title: 'Little Women',
        author: 'Louisa May Alcott',
        authorLink: 'https://www.goodreads.com/author/show/1315.Louisa_May_Alcott',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1562690475l/1934._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/1934.Little_Women',
        ratingsAvg: '4.09',
        ratingsNumber: '1680530',
        rating: '4',
    },
    {
        title: 'Marriage and other Games',
        author: 'Veronica Henry',
        authorLink: 'https://www.goodreads.com/author/show/505129.Veronica_Henry',
        year: '2009',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/5556808-marriage-and-other-games',
        ratingsAvg: '4.02',
        ratingsNumber: '506',
        rating: '1',
    },
    {
        title: 'The Birthday Party',
        author: 'Veronica Henry',
        authorLink: 'https://www.goodreads.com/author/show/505129.Veronica_Henry',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1415582847l/9131903._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/9131903-the-birthday-party',
        ratingsAvg: '3.87',
        ratingsNumber: '539',
        rating: '1',
    },
    {
        title: 'An Eligible Bachelor',
        author: 'Veronica Henry',
        authorLink: 'https://www.goodreads.com/author/show/505129.Veronica_Henry',
        year: '2005',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1803388.An_Eligible_Bachelor',
        ratingsAvg: '4.00',
        ratingsNumber: '925',
        rating: '2',
    },
    {
        title: 'The Forever House',
        author: 'Veronica Henry',
        authorLink: 'https://www.goodreads.com/author/show/505129.Veronica_Henry',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1489432147l/33939262._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/33939262-the-forever-house',
        ratingsAvg: '4.21',
        ratingsNumber: '1921',
        rating: '2',
    },
    {
        title: 'A Night on the Orient Express',
        author: 'Veronica Henry',
        authorLink: 'https://www.goodreads.com/author/show/505129.Veronica_Henry',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1372598969l/17170102._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/17170102-a-night-on-the-orient-express',
        ratingsAvg: '3.88',
        ratingsNumber: '2358',
        rating: '1',
    },
    {
        title: 'The Long Weekend',
        author: 'Veronica Henry',
        authorLink: 'https://www.goodreads.com/author/show/505129.Veronica_Henry',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1333038605l/13565469._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13565469-the-long-weekend',
        ratingsAvg: '3.99',
        ratingsNumber: '2418',
        rating: '2',
    },
    {
        title: 'Christmas at the Beach Hut',
        author: 'Veronica Henry',
        authorLink: 'https://www.goodreads.com/author/show/505129.Veronica_Henry',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1549266729l/40542822._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/40542822-christmas-at-the-beach-hut',
        ratingsAvg: '4.35',
        ratingsNumber: '1660',
        rating: '3',
    },
    {
        title: 'The Beach Hut Next Door',
        author: 'Veronica Henry',
        authorLink: 'https://www.goodreads.com/author/show/505129.Veronica_Henry',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1400754885l/18804899._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18804899-the-beach-hut-next-door',
        ratingsAvg: '4.13',
        ratingsNumber: '1410',
        rating: '2',
    },
    {
        title: 'How to Find Love in a Bookshop',
        author: 'Veronica Henry',
        authorLink: 'https://www.goodreads.com/author/show/505129.Veronica_Henry',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1530293032l/38821046._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/38821046-how-to-find-love-in-a-bookshop',
        ratingsAvg: '3.89',
        ratingsNumber: '16389',
        rating: '2',
    },
    {
        title: 'The Beach Hut',
        author: 'Veronica Henry',
        authorLink: 'https://www.goodreads.com/author/show/505129.Veronica_Henry',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1275323057l/6887697._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6887697-the-beach-hut',
        ratingsAvg: '3.96',
        ratingsNumber: '2751',
        rating: '3',
    },
    {
        title: 'The Haunting of Hill House',
        author: 'Shirley Jackson',
        authorLink: 'https://www.goodreads.com/author/show/13388.Shirley_Jackson',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327871336l/89717._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/89717.The_Haunting_of_Hill_House',
        ratingsAvg: '3.84',
        ratingsNumber: '161431',
        rating: '3',
    },
    {
        title: 'The Bookshop on the Shore',
        author: 'Jenny Colgan',
        authorLink: 'https://www.goodreads.com/author/show/39272.Jenny_Colgan',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1541010114l/41716692._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/41716692-the-bookshop-on-the-shore',
        ratingsAvg: '3.92',
        ratingsNumber: '14554',
        rating: '4',
    },
    {
        title: 'The Stone Sky',
        author: 'N.K. Jemisin',
        authorLink: 'https://www.goodreads.com/author/show/2917917.N_K_Jemisin',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1478547421l/31817749._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/31817749-the-stone-sky',
        ratingsAvg: '4.32',
        ratingsNumber: '72203',
        rating: '2',
    },
    {
        title: 'The Obelisk Gate',
        author: 'N.K. Jemisin',
        authorLink: 'https://www.goodreads.com/author/show/2917917.N_K_Jemisin',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1578523237l/26228034._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/26228034-the-obelisk-gate',
        ratingsAvg: '4.29',
        ratingsNumber: '89083',
        rating: '2',
    },
    {
        title: 'The Ocean at the End of the Lane',
        author: 'Neil Gaiman',
        authorLink: 'https://www.goodreads.com/author/show/1221698.Neil_Gaiman',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1497098563l/15783514._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/15783514-the-ocean-at-the-end-of-the-lane',
        ratingsAvg: '4.00',
        ratingsNumber: '480334',
        rating: '3',
    },
    {
        title: 'Three Women',
        author: 'Lisa Taddeo',
        authorLink: 'https://www.goodreads.com/author/show/3497436.Lisa_Taddeo',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1560679612l/42201100._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/42201100-three-women',
        ratingsAvg: '3.69',
        ratingsNumber: '75879',
        rating: '2',
    },
    {
        title: "Lilith's Brood",
        author: 'Octavia E. Butler',
        authorLink: 'https://www.goodreads.com/author/show/29535.Octavia_E_Butler',
        year: '2000',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/60926.Lilith_s_Brood',
        ratingsAvg: '4.35',
        ratingsNumber: '14834',
        rating: '4',
    },
    {
        title: 'The Mermaid',
        author: 'Christina Henry',
        authorLink: 'https://www.goodreads.com/author/show/3409936.Christina_Henry',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1510155570l/36358268._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/36358268-the-mermaid',
        ratingsAvg: '3.67',
        ratingsNumber: '4802',
        rating: '3',
    },
    {
        title: 'Good Omens: The Nice and Accurate Prophecies of Agnes Nutter, Witch',
        author: 'Terry Pratchett',
        authorLink: 'https://www.goodreads.com/author/show/1654.Terry_Pratchett',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1392528568l/12067._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/12067.Good_Omens',
        ratingsAvg: '4.24',
        ratingsNumber: '542694',
        rating: '3',
    },
    {
        title: 'The Seven Husbands of Evelyn Hugo',
        author: 'Taylor Jenkins Reid',
        authorLink: 'https://www.goodreads.com/author/show/6572605.Taylor_Jenkins_Reid',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1498169036l/32620332._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/32620332-the-seven-husbands-of-evelyn-hugo',
        ratingsAvg: '4.35',
        ratingsNumber: '221251',
        rating: '2',
    },
    {
        title: 'One True Loves',
        author: 'Taylor Jenkins Reid',
        authorLink: 'https://www.goodreads.com/author/show/6572605.Taylor_Jenkins_Reid',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1559960507l/27189194._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/27189194-one-true-loves',
        ratingsAvg: '4.03',
        ratingsNumber: '34245',
        rating: '1',
    },
    {
        title: 'Maybe in Another Life',
        author: 'Taylor Jenkins Reid',
        authorLink: 'https://www.goodreads.com/author/show/6572605.Taylor_Jenkins_Reid',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1424970519l/23492661._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23492661-maybe-in-another-life',
        ratingsAvg: '3.76',
        ratingsNumber: '46904',
        rating: '1',
    },
    {
        title: 'The Long Way to a Small, Angry Planet',
        author: 'Becky Chambers',
        authorLink: 'https://www.goodreads.com/author/show/8389735.Becky_Chambers',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1405532474l/22733729._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22733729-the-long-way-to-a-small-angry-planet',
        ratingsAvg: '4.15',
        ratingsNumber: '75430',
        rating: '4',
    },
    {
        title: 'House of Salt and Sorrows',
        author: 'Erin A. Craig',
        authorLink: 'https://www.goodreads.com/author/show/17892078.Erin_A_Craig',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1544071699l/39679076._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/39679076-house-of-salt-and-sorrows',
        ratingsAvg: '3.94',
        ratingsNumber: '26640',
        rating: '5',
    },
    {
        title: 'The Fates Divide',
        author: 'Veronica Roth',
        authorLink: 'https://www.goodreads.com/author/show/4039811.Veronica_Roth',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1513195268l/35820633._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/35820633-the-fates-divide',
        ratingsAvg: '3.92',
        ratingsNumber: '21245',
        rating: '3',
    },
    {
        title: "Ender's Game",
        author: 'Orson Scott Card',
        authorLink: 'https://www.goodreads.com/author/show/589.Orson_Scott_Card',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1408303130l/375802._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/375802.Ender_s_Game',
        ratingsAvg: '4.30',
        ratingsNumber: '1130593',
        rating: '4',
    },
    {
        title: 'Allegiant',
        author: 'Veronica Roth',
        authorLink: 'https://www.goodreads.com/author/show/4039811.Veronica_Roth',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1395582745l/18710190._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18710190-allegiant',
        ratingsAvg: '3.62',
        ratingsNumber: '826297',
        rating: '1',
    },
    {
        title: 'Carve the Mark',
        author: 'Veronica Roth',
        authorLink: 'https://www.goodreads.com/author/show/4039811.Veronica_Roth',
        year: '2017',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/30117284-carve-the-mark',
        ratingsAvg: '3.77',
        ratingsNumber: '61558',
        rating: '3',
    },
    {
        title: 'Run Away',
        author: 'Harlan Coben',
        authorLink: 'https://www.goodreads.com/author/show/24689.Harlan_Coben',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1554354561l/44781614._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/44781614-run-away',
        ratingsAvg: '4.05',
        ratingsNumber: '44257',
        rating: '1',
    },
    {
        title: 'The Mother-in-Law',
        author: 'Sally Hepworth',
        authorLink: 'https://www.goodreads.com/author/show/8123141.Sally_Hepworth',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1560186318l/46251368._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/46251368-the-mother-in-law',
        ratingsAvg: '3.97',
        ratingsNumber: '70263',
        rating: '3',
    },
    {
        title: 'Mystery Man',
        author: 'Kristen Ashley',
        authorLink: 'https://www.goodreads.com/author/show/2958084.Kristen_Ashley',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1483487036l/12299419._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/12299419-mystery-man',
        ratingsAvg: '4.23',
        ratingsNumber: '66268',
        rating: '1',
    },
    {
        title: 'The Silent Patient',
        author: 'Alex Michaelides',
        authorLink: 'https://www.goodreads.com/author/show/17621440.Alex_Michaelides',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1582759969l/40097951._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/40097951-the-silent-patient',
        ratingsAvg: '4.09',
        ratingsNumber: '505007',
        rating: '2',
    },
    {
        title: 'Daisy Jones & The Six',
        author: 'Taylor Jenkins Reid',
        authorLink: 'https://www.goodreads.com/author/show/6572605.Taylor_Jenkins_Reid',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1580255154l/40597810._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/40597810-daisy-jones-the-six',
        ratingsAvg: '4.20',
        ratingsNumber: '270988',
        rating: '3',
    },
    {
        title: 'The Mother-in-Law',
        author: 'Sally Hepworth',
        authorLink: 'https://www.goodreads.com/author/show/8123141.Sally_Hepworth',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1546452406l/39863405._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/39863405-the-mother-in-law',
        ratingsAvg: '3.97',
        ratingsNumber: '70263',
        rating: '0',
    },
    {
        title: 'Uprooted',
        author: 'Naomi Novik',
        authorLink: 'https://www.goodreads.com/author/show/8730.Naomi_Novik',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1550135418l/22544764._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22544764-uprooted',
        ratingsAvg: '4.06',
        ratingsNumber: '166424',
        rating: '4',
    },
    {
        title: 'Recursion',
        author: 'Blake Crouch',
        authorLink: 'https://www.goodreads.com/author/show/442240.Blake_Crouch',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1543687940l/42046112._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/42046112-recursion',
        ratingsAvg: '4.15',
        ratingsNumber: '108630',
        rating: '3',
    },
    {
        title: 'The Beauty',
        author: 'Aliya Whiteley',
        authorLink: 'https://www.goodreads.com/author/show/891354.Aliya_Whiteley',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1411147769l/23250725._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23250725-the-beauty',
        ratingsAvg: '3.65',
        ratingsNumber: '1600',
        rating: '3',
    },
    {
        title: 'Lost Boy: The True Story of Captain Hook',
        author: 'Christina Henry',
        authorLink: 'https://www.goodreads.com/author/show/3409936.Christina_Henry',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1505240721l/32828538._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/32828538-lost-boy',
        ratingsAvg: '4.20',
        ratingsNumber: '12604',
        rating: '4',
    },
    {
        title: 'The Girl in Red',
        author: 'Christina Henry',
        authorLink: 'https://www.goodreads.com/author/show/3409936.Christina_Henry',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1545249589l/42881101._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/42881101-the-girl-in-red',
        ratingsAvg: '3.71',
        ratingsNumber: '7537',
        rating: '3',
    },
    {
        title: 'Gods of Jade and Shadow',
        author: 'Silvia Moreno-Garcia',
        authorLink: 'https://www.goodreads.com/author/show/4088550.Silvia_Moreno_Garcia',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1543268579l/36510722._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/36510722-gods-of-jade-and-shadow',
        ratingsAvg: '3.90',
        ratingsNumber: '22600',
        rating: '1',
    },
    {
        title: 'Nine Perfect Strangers',
        author: 'Liane Moriarty',
        authorLink: 'https://www.goodreads.com/author/show/322069.Liane_Moriarty',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1529076901l/39280445._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/39280445-nine-perfect-strangers',
        ratingsAvg: '3.51',
        ratingsNumber: '241159',
        rating: '2',
    },
    {
        title: 'The Hookup',
        author: 'Kristen Ashley',
        authorLink: 'https://www.goodreads.com/author/show/2958084.Kristen_Ashley',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1511060888l/36408499._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/36408499-the-hookup',
        ratingsAvg: '4.13',
        ratingsNumber: '18445',
        rating: '1',
    },
    {
        title: 'All Our Wrong Todays',
        author: 'Elan Mastai',
        authorLink: 'https://www.goodreads.com/author/show/14587932.Elan_Mastai',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1465316482l/27405006._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/27405006-all-our-wrong-todays',
        ratingsAvg: '3.76',
        ratingsNumber: '21615',
        rating: '4',
    },
    {
        title: 'The Bone Clocks',
        author: 'David Mitchell',
        authorLink: 'https://www.goodreads.com/author/show/6538289.David_Mitchell',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1398205538l/20819685._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20819685-the-bone-clocks',
        ratingsAvg: '3.83',
        ratingsNumber: '85665',
        rating: '3',
    },
    {
        title: 'The Merry Spinster: Tales of Everyday Horror',
        author: 'Mallory Ortberg',
        authorLink: 'https://www.goodreads.com/author/show/7811262.Mallory_Ortberg',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1515148254l/35035160._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/35035160-the-merry-spinster',
        ratingsAvg: '3.32',
        ratingsNumber: '5300',
        rating: '3',
    },
    {
        title: 'True North: Discover Your Authentic Leadership',
        author: 'Bill George',
        authorLink: 'https://www.goodreads.com/author/show/62125.Bill_George',
        year: null,
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/107420.True_North',
        ratingsAvg: '3.80',
        ratingsNumber: '2506',
        rating: '1',
    },
    {
        title: 'The Great Alone',
        author: 'Kristin Hannah',
        authorLink: 'https://www.goodreads.com/author/show/54493.Kristin_Hannah',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1501852423l/34912895._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34912895-the-great-alone',
        ratingsAvg: '4.34',
        ratingsNumber: '375830',
        rating: '2',
    },
    {
        title: 'The Fifth Season',
        author: 'N.K. Jemisin',
        authorLink: 'https://www.goodreads.com/author/show/2917917.N_K_Jemisin',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1386803701l/19161852._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/19161852-the-fifth-season',
        ratingsAvg: '4.28',
        ratingsNumber: '147970',
        rating: '3',
    },
    {
        title: 'Not That Bad: Dispatches from Rape Culture',
        author: 'Roxane Gay',
        authorLink: 'https://www.goodreads.com/author/show/3360355.Roxane_Gay',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1509748434l/36381052._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/36381052-not-that-bad',
        ratingsAvg: '4.40',
        ratingsNumber: '14153',
        rating: '5',
    },
    {
        title: 'The Great Alone',
        author: 'Kristin Hannah',
        authorLink: 'https://www.goodreads.com/author/show/54493.Kristin_Hannah',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1521954798l/34927828._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34927828-the-great-alone',
        ratingsAvg: '4.34',
        ratingsNumber: '375830',
        rating: '2',
    },
    {
        title: 'Run Away',
        author: 'Harlan Coben',
        authorLink: 'https://www.goodreads.com/author/show/24689.Harlan_Coben',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1533567545l/40697540._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/40697540-run-away',
        ratingsAvg: '4.05',
        ratingsNumber: '44257',
        rating: '0',
    },
    {
        title: 'Trust Exercise',
        author: 'Susan Choi',
        authorLink: 'https://www.goodreads.com/author/show/76672.Susan_Choi',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1541606703l/40046059._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/40046059-trust-exercise',
        ratingsAvg: '3.13',
        ratingsNumber: '20389',
        rating: '1',
    },
    {
        title: 'The Great Believers',
        author: 'Rebecca Makkai',
        authorLink: 'https://www.goodreads.com/author/show/3134707.Rebecca_Makkai',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1513005580l/36739329._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/36739329-the-great-believers',
        ratingsAvg: '4.21',
        ratingsNumber: '74642',
        rating: '5',
    },
    {
        title: "I Was Told There'd Be Cake: Essays",
        author: 'Sloane Crosley',
        authorLink: 'https://www.goodreads.com/author/show/994873.Sloane_Crosley',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1311282474l/2195289._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/2195289.I_Was_Told_There_d_Be_Cake',
        ratingsAvg: '3.46',
        ratingsNumber: '36246',
        rating: '1',
    },
    {
        title: 'Queenie',
        author: 'Candice Carty-Williams',
        authorLink: 'https://www.goodreads.com/author/show/17323922.Candice_Carty_Williams',
        year: '2019',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1539882662l/36586697._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/36586697-queenie',
        ratingsAvg: '3.87',
        ratingsNumber: '68882',
        rating: '3',
    },
    {
        title: 'The Authentics',
        author: 'Abdi Nazemian',
        authorLink: 'https://www.goodreads.com/author/show/8158265.Abdi_Nazemian',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1497097306l/31371275._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/31371275-the-authentics',
        ratingsAvg: '3.57',
        ratingsNumber: '710',
        rating: '1',
    },
    {
        title: 'The Vegetarian',
        author: 'Han Kang',
        authorLink: 'https://www.goodreads.com/author/show/4119155.Han_Kang',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1478196580l/25489025._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25489025-the-vegetarian',
        ratingsAvg: '3.58',
        ratingsNumber: '92458',
        rating: '2',
    },
    {
        title: 'The Final Empire',
        author: 'Brandon Sanderson',
        authorLink: 'https://www.goodreads.com/author/show/38550.Brandon_Sanderson',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1480717416l/68428._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/68428.The_Final_Empire',
        ratingsAvg: '4.45',
        ratingsNumber: '401340',
        rating: '3',
    },
    {
        title: 'The Well of Ascension',
        author: 'Brandon Sanderson',
        authorLink: 'https://www.goodreads.com/author/show/38550.Brandon_Sanderson',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1480717596l/68429._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/68429.The_Well_of_Ascension',
        ratingsAvg: '4.36',
        ratingsNumber: '285086',
        rating: '5',
    },
    {
        title: 'The Hero of Ages',
        author: 'Brandon Sanderson',
        authorLink: 'https://www.goodreads.com/author/show/38550.Brandon_Sanderson',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1480717763l/2767793._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/2767793-the-hero-of-ages',
        ratingsAvg: '4.48',
        ratingsNumber: '265308',
        rating: '3',
    },
    {
        title: 'Words of Radiance',
        author: 'Brandon Sanderson',
        authorLink: 'https://www.goodreads.com/author/show/38550.Brandon_Sanderson',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1507307927l/17332218._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/17332218-words-of-radiance',
        ratingsAvg: '4.75',
        ratingsNumber: '207013',
        rating: '4',
    },
    {
        title: 'Oathbringer',
        author: 'Brandon Sanderson',
        authorLink: 'https://www.goodreads.com/author/show/38550.Brandon_Sanderson',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1507307833l/34002132._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34002132-oathbringer',
        ratingsAvg: '4.59',
        ratingsNumber: '126539',
        rating: '3',
    },
    {
        title: 'The Way of Kings',
        author: 'Brandon Sanderson',
        authorLink: 'https://www.goodreads.com/author/show/38550.Brandon_Sanderson',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1388184640l/7235533._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7235533-the-way-of-kings',
        ratingsAvg: '4.63',
        ratingsNumber: '302396',
        rating: '3',
    },
    {
        title: 'On Beauty',
        author: 'Zadie Smith',
        authorLink: 'https://www.goodreads.com/author/show/2522.Zadie_Smith',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1598196388l/3679._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/3679.On_Beauty',
        ratingsAvg: '3.74',
        ratingsNumber: '67974',
        rating: '3',
    },
    {
        title: 'Roots: The Saga of an American Family',
        author: 'Alex Haley',
        authorLink: 'https://www.goodreads.com/author/show/17434.Alex_Haley',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1329398936l/546018._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/546018.Roots',
        ratingsAvg: '4.43',
        ratingsNumber: '146001',
        rating: '5',
    },
    {
        title: 'Faithful',
        author: 'Alice Hoffman',
        authorLink: 'https://www.goodreads.com/author/show/3502.Alice_Hoffman',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1478771334l/29430788._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/29430788-faithful',
        ratingsAvg: '3.98',
        ratingsNumber: '29085',
        rating: '1',
    },
    {
        title: 'The Witch Elm',
        author: 'Tana French',
        authorLink: 'https://www.goodreads.com/author/show/138825.Tana_French',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1527311195l/39720991._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/39720991-the-witch-elm',
        ratingsAvg: '3.55',
        ratingsNumber: '66141',
        rating: '1',
    },
    {
        title: 'The Marriage of Opposites',
        author: 'Alice Hoffman',
        authorLink: 'https://www.goodreads.com/author/show/3502.Alice_Hoffman',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1438581390l/23492741._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23492741-the-marriage-of-opposites',
        ratingsAvg: '3.89',
        ratingsNumber: '50478',
        rating: '5',
    },
    {
        title: 'The Broken Girls',
        author: 'Simone St. James',
        authorLink: 'https://www.goodreads.com/author/show/4981568.Simone_St_James',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1497406195l/35305625._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/35305625-the-broken-girls',
        ratingsAvg: '4.04',
        ratingsNumber: '50261',
        rating: '2',
    },
    {
        title: 'Elevation',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1542040067l/38355410._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/38355410-elevation',
        ratingsAvg: '3.68',
        ratingsNumber: '79946',
        rating: '2',
    },
    {
        title: 'The Hunger',
        author: 'Alma Katsu',
        authorLink: 'https://www.goodreads.com/author/show/3387293.Alma_Katsu',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1497803277l/30285766._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/30285766-the-hunger',
        ratingsAvg: '3.65',
        ratingsNumber: '14036',
        rating: '3',
    },
    {
        title: 'Red Clocks',
        author: 'Leni Zumas',
        authorLink: 'https://www.goodreads.com/author/show/803005.Leni_Zumas',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1532092617l/40881738._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/40881738-red-clocks',
        ratingsAvg: '3.68',
        ratingsNumber: '18418',
        rating: '4',
    },
    {
        title: 'Baby Teeth',
        author: 'Zoje Stage',
        authorLink: 'https://www.goodreads.com/author/show/16943823.Zoje_Stage',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1528990927l/35410511._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/35410511-baby-teeth',
        ratingsAvg: '3.63',
        ratingsNumber: '36605',
        rating: '5',
    },
    {
        title: 'Sadie',
        author: 'Courtney Summers',
        authorLink: 'https://www.goodreads.com/author/show/1487748.Courtney_Summers',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1556559178l/34810320._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34810320-sadie',
        ratingsAvg: '4.12',
        ratingsNumber: '63271',
        rating: '2',
    },
    {
        title: 'Sometimes I Lie',
        author: 'Alice Feeney',
        authorLink: 'https://www.goodreads.com/author/show/15904689.Alice_Feeney',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1519037612l/34275231._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34275231-sometimes-i-lie',
        ratingsAvg: '3.82',
        ratingsNumber: '91174',
        rating: '3',
    },
    {
        title: 'The Outsider',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1524596540l/36124936._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/36124936-the-outsider',
        ratingsAvg: '3.98',
        ratingsNumber: '188985',
        rating: '3',
    },
    {
        title: 'The Wife Between Us',
        author: 'Greer Hendricks',
        authorLink: 'https://www.goodreads.com/author/show/16935182.Greer_Hendricks',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1496862122l/34189556._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34189556-the-wife-between-us',
        ratingsAvg: '3.84',
        ratingsNumber: '222488',
        rating: '1',
    },
    {
        title: 'The Wedding Date',
        author: 'Jasmine Guillory',
        authorLink: 'https://www.goodreads.com/author/show/16287225.Jasmine_Guillory',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1515694480l/35259631._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/35259631-the-wedding-date',
        ratingsAvg: '3.58',
        ratingsNumber: '74240',
        rating: '1',
    },
    {
        title: 'The Museum of Extraordinary Things',
        author: 'Alice Hoffman',
        authorLink: 'https://www.goodreads.com/author/show/3502.Alice_Hoffman',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1392575125l/18144053._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18144053-the-museum-of-extraordinary-things',
        ratingsAvg: '3.74',
        ratingsNumber: '45865',
        rating: '3',
    },
    {
        title: 'Practical Magic',
        author: 'Alice Hoffman',
        authorLink: 'https://www.goodreads.com/author/show/3502.Alice_Hoffman',
        year: '2003',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1490354120l/22896._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22896.Practical_Magic',
        ratingsAvg: '3.77',
        ratingsNumber: '74807',
        rating: '3',
    },
    {
        title: 'Matchmaking for Beginners',
        author: 'Maddie Dawson',
        authorLink: 'https://www.goodreads.com/author/show/3440140.Maddie_Dawson',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1521617391l/36439333._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/36439333-matchmaking-for-beginners',
        ratingsAvg: '3.97',
        ratingsNumber: '46736',
        rating: '1',
    },
    {
        title: 'An Excess Male',
        author: 'Maggie Shen King',
        authorLink: 'https://www.goodreads.com/author/show/16222012.Maggie_Shen_King',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1491587537l/33544902._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/33544902-an-excess-male',
        ratingsAvg: '3.68',
        ratingsNumber: '2221',
        rating: '3',
    },
    {
        title: 'The Terror',
        author: 'Dan Simmons',
        authorLink: 'https://www.goodreads.com/author/show/2687.Dan_Simmons',
        year: '2007',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/3974.The_Terror',
        ratingsAvg: '4.06',
        ratingsNumber: '42513',
        rating: '5',
    },
    {
        title: 'The Rules of Magic',
        author: 'Alice Hoffman',
        authorLink: 'https://www.goodreads.com/author/show/3502.Alice_Hoffman',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1492802012l/34037113._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34037113-the-rules-of-magic',
        ratingsAvg: '3.97',
        ratingsNumber: '67405',
        rating: '3',
    },
    {
        title: 'Sharp Objects',
        author: 'Gillian Flynn',
        authorLink: 'https://www.goodreads.com/author/show/2383.Gillian_Flynn',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1475695315l/18045891._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18045891-sharp-objects',
        ratingsAvg: '3.98',
        ratingsNumber: '677660',
        rating: '2',
    },
    {
        title: 'The Song of Achilles',
        author: 'Madeline Miller',
        authorLink: 'https://www.goodreads.com/author/show/176372.Madeline_Miller',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1331154660l/11250317._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/11250317-the-song-of-achilles',
        ratingsAvg: '4.35',
        ratingsNumber: '227315',
        rating: '4',
    },
    {
        title: 'Galatea',
        author: 'Madeline Miller',
        authorLink: 'https://www.goodreads.com/author/show/176372.Madeline_Miller',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1375741967l/18162954._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18162954-galatea',
        ratingsAvg: '3.91',
        ratingsNumber: '6945',
        rating: '3',
    },
    {
        title: 'A Separation',
        author: 'Katie Kitamura',
        authorLink: 'https://www.goodreads.com/author/show/2808688.Katie_Kitamura',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1484325706l/30407998._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/30407998-a-separation',
        ratingsAvg: '2.90',
        ratingsNumber: '10812',
        rating: '1',
    },
    {
        title: 'Ilium',
        author: 'Dan Simmons',
        authorLink: 'https://www.goodreads.com/author/show/2687.Dan_Simmons',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1390894862l/3973._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/3973.Ilium',
        ratingsAvg: '4.03',
        ratingsNumber: '25755',
        rating: '3',
    },
    {
        title: 'The Woman in the Window',
        author: 'A.J. Finn',
        authorLink: 'https://www.goodreads.com/author/show/7992137.A_J_Finn',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1496341857l/34848682._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34848682-the-woman-in-the-window',
        ratingsAvg: '3.94',
        ratingsNumber: '452657',
        rating: '2',
    },
    {
        title: 'The Paying Guests',
        author: 'Sarah Waters',
        authorLink: 'https://www.goodreads.com/author/show/25334.Sarah_Waters',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1396671788l/20821087._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20821087-the-paying-guests',
        ratingsAvg: '3.43',
        ratingsNumber: '72745',
        rating: '1',
    },
    {
        title: 'An American Marriage',
        author: 'Tayari Jones',
        authorLink: 'https://www.goodreads.com/author/show/292970.Tayari_Jones',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1491493625l/33590210._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/33590210-an-american-marriage',
        ratingsAvg: '3.96',
        ratingsNumber: '257921',
        rating: '2',
    },
    {
        title: 'How to Be a Woman',
        author: 'Caitlin Moran',
        authorLink: 'https://www.goodreads.com/author/show/939363.Caitlin_Moran',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1405909800l/10600242._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10600242-how-to-be-a-woman',
        ratingsAvg: '3.73',
        ratingsNumber: '99084',
        rating: '3',
    },
    {
        title: 'Horrorst\u00f6r',
        author: 'Grady Hendrix',
        authorLink: 'https://www.goodreads.com/author/show/4826394.Grady_Hendrix',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1414314217l/13129925._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13129925-horrorst-r',
        ratingsAvg: '3.64',
        ratingsNumber: '28909',
        rating: '1',
    },
    {
        title: 'Losing It',
        author: 'Emma Rathbone',
        authorLink: 'https://www.goodreads.com/author/show/3355313.Emma_Rathbone',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1453061832l/27774757._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/27774757-losing-it',
        ratingsAvg: '2.50',
        ratingsNumber: '2905',
        rating: '1',
    },
    {
        title: 'How to Build a Girl',
        author: 'Caitlin Moran',
        authorLink: 'https://www.goodreads.com/author/show/939363.Caitlin_Moran',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1410131752l/20525628._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20525628-how-to-build-a-girl',
        ratingsAvg: '3.72',
        ratingsNumber: '30666',
        rating: '5',
    },
    {
        title: 'Force of Nature',
        author: 'Jane Harper',
        authorLink: 'https://www.goodreads.com/author/show/556546.Jane_Harper',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1501790749l/34275222._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34275222-force-of-nature',
        ratingsAvg: '3.84',
        ratingsNumber: '68183',
        rating: '2',
    },
    {
        title: 'The Three-Body Problem',
        author: 'Liu Cixin',
        authorLink: 'https://www.goodreads.com/author/show/5780686.Liu_Cixin',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1415428227l/20518872._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20518872-the-three-body-problem',
        ratingsAvg: '4.06',
        ratingsNumber: '147879',
        rating: '5',
    },
    {
        title: 'Educated',
        author: 'Tara Westover',
        authorLink: 'https://www.goodreads.com/author/show/16121977.Tara_Westover',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1506026635l/35133922._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/35133922-educated',
        ratingsAvg: '4.47',
        ratingsNumber: '793967',
        rating: '5',
    },
    {
        title: 'The Astonishing Color of After',
        author: 'Emily X.R. Pan',
        authorLink: 'https://www.goodreads.com/author/show/15450609.Emily_X_R_Pan',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1516893525l/35604686._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/35604686-the-astonishing-color-of-after',
        ratingsAvg: '4.11',
        ratingsNumber: '19883',
        rating: '2',
    },
    {
        title: 'Circe',
        author: 'Madeline Miller',
        authorLink: 'https://www.goodreads.com/author/show/176372.Madeline_Miller',
        year: '2018',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1565909496l/35959740._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/35959740-circe',
        ratingsAvg: '4.26',
        ratingsNumber: '341438',
        rating: '4',
    },
    {
        title: 'The Grip of It',
        author: 'Jac Jemc',
        authorLink: 'https://www.goodreads.com/author/show/2850412.Jac_Jemc',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1497915798l/31574739._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/31574739-the-grip-of-it',
        ratingsAvg: '3.24',
        ratingsNumber: '6953',
        rating: '1',
    },
    {
        title: 'The Girl Before',
        author: 'J.P. Delaney',
        authorLink: 'https://www.goodreads.com/author/show/14719991.J_P_Delaney',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1485972587l/28016509._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28016509-the-girl-before',
        ratingsAvg: '3.69',
        ratingsNumber: '121125',
        rating: '1',
    },
    {
        title: 'Rabbit Cake',
        author: 'Annie  Hartnett',
        authorLink: 'https://www.goodreads.com/author/show/15565382.Annie_Hartnett',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1464994910l/30304223._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/30304223-rabbit-cake',
        ratingsAvg: '3.97',
        ratingsNumber: '8052',
        rating: '5',
    },
    {
        title: 'Borne',
        author: 'Jeff VanderMeer',
        authorLink: 'https://www.goodreads.com/author/show/33919.Jeff_VanderMeer',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1477487850l/31451186._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/31451186-borne',
        ratingsAvg: '3.93',
        ratingsNumber: '24302',
        rating: '2',
    },
    {
        title: 'The Power',
        author: 'Naomi Alderman',
        authorLink: 'https://www.goodreads.com/author/show/118226.Naomi_Alderman',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1462814013l/29751398._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/29751398-the-power',
        ratingsAvg: '3.81',
        ratingsNumber: '158665',
        rating: '2',
    },
    {
        title: 'Mischling',
        author: 'Affinity Konar',
        authorLink: 'https://www.goodreads.com/author/show/2894415.Affinity_Konar',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1470023717l/28664920._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28664920-mischling',
        ratingsAvg: '3.84',
        ratingsNumber: '12662',
        rating: '1',
    },
    {
        title: 'Chemistry',
        author: 'Weike Wang',
        authorLink: 'https://www.goodreads.com/author/show/15679271.Weike_Wang',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1479350390l/31684925._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/31684925-chemistry',
        ratingsAvg: '3.72',
        ratingsNumber: '15274',
        rating: '2',
    },
    {
        title: 'Hello From the Gillespies',
        author: 'Monica McInerney',
        authorLink: 'https://www.goodreads.com/author/show/47105.Monica_McInerney',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1414346748l/20893378._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20893378-hello-from-the-gillespies',
        ratingsAvg: '3.83',
        ratingsNumber: '5239',
        rating: '2',
    },
    {
        title: 'Left Neglected',
        author: 'Lisa Genova',
        authorLink: 'https://www.goodreads.com/author/show/978484.Lisa_Genova',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1285414621l/8492768._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/8492768-left-neglected',
        ratingsAvg: '3.97',
        ratingsNumber: '69428',
        rating: '1',
    },
    {
        title: 'The Mist',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1331317781l/813214._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/813214.The_Mist',
        ratingsAvg: '3.95',
        ratingsNumber: '113103',
        rating: '1',
    },
    {
        title: 'Magpie Murders',
        author: 'Anthony Horowitz',
        authorLink: 'https://www.goodreads.com/author/show/32590.Anthony_Horowitz',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1504599398l/32075854._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/32075854-magpie-murders',
        ratingsAvg: '3.93',
        ratingsNumber: '67360',
        rating: '2',
    },
    {
        title: 'Saving Grace',
        author: 'Jane Green',
        authorLink: 'https://www.goodreads.com/author/show/12915.Jane_Green',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1415428645l/21853667._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/21853667-saving-grace',
        ratingsAvg: '3.49',
        ratingsNumber: '13205',
        rating: '1',
    },
    {
        title: 'A Thousand Acres',
        author: 'Jane Smiley',
        authorLink: 'https://www.goodreads.com/author/show/1339.Jane_Smiley',
        year: '2003',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/41193.A_Thousand_Acres',
        ratingsAvg: '3.80',
        ratingsNumber: '61144',
        rating: '3',
    },
    {
        title: 'Some Luck',
        author: 'Jane Smiley',
        authorLink: 'https://www.goodreads.com/author/show/1339.Jane_Smiley',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1413128987l/20587777._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20587777-some-luck',
        ratingsAvg: '3.61',
        ratingsNumber: '18294',
        rating: '3',
    },
    {
        title: 'All Grown Up',
        author: 'Jami Attenberg',
        authorLink: 'https://www.goodreads.com/author/show/215765.Jami_Attenberg',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1474640655l/30971707._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/30971707-all-grown-up',
        ratingsAvg: '3.41',
        ratingsNumber: '14026',
        rating: '2',
    },
    {
        title: 'After I Do',
        author: 'Taylor Jenkins Reid',
        authorLink: 'https://www.goodreads.com/author/show/6572605.Taylor_Jenkins_Reid',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1446128207l/18775359._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18775359-after-i-do',
        ratingsAvg: '4.02',
        ratingsNumber: '23744',
        rating: '1',
    },
    {
        title: 'All In',
        author: 'Jennifer Lynn Barnes',
        authorLink: 'https://www.goodreads.com/author/show/164187.Jennifer_Lynn_Barnes',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1446265230l/24849026._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/24849026-all-in',
        ratingsAvg: '4.44',
        ratingsNumber: '9961',
        rating: '1',
    },
    {
        title: 'One of Us Is Lying',
        author: 'Karen M. McManus',
        authorLink: 'https://www.goodreads.com/author/show/15127507.Karen_M_McManus',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1490084494l/32571395._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/32571395-one-of-us-is-lying',
        ratingsAvg: '4.05',
        ratingsNumber: '215139',
        rating: '1',
    },
    {
        title: 'Killer Instinct',
        author: 'Jennifer Lynn Barnes',
        authorLink: 'https://www.goodreads.com/author/show/164187.Jennifer_Lynn_Barnes',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1391636942l/20409231._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20409231-killer-instinct',
        ratingsAvg: '4.37',
        ratingsNumber: '13929',
        rating: '1',
    },
    {
        title: 'The Naturals',
        author: 'Jennifer Lynn Barnes',
        authorLink: 'https://www.goodreads.com/author/show/164187.Jennifer_Lynn_Barnes',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1374862751l/13597723._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13597723-the-naturals',
        ratingsAvg: '4.16',
        ratingsNumber: '28300',
        rating: '1',
    },
    {
        title: 'Sleeping Beauties',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1510335748l/34466922._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34466922-sleeping-beauties',
        ratingsAvg: '3.73',
        ratingsNumber: '64671',
        rating: '3',
    },
    {
        title: 'Into the Water',
        author: 'Paula Hawkins',
        authorLink: 'https://www.goodreads.com/author/show/1063732.Paula_Hawkins',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1494055413l/33151805._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/33151805-into-the-water',
        ratingsAvg: '3.58',
        ratingsNumber: '281392',
        rating: '3',
    },
    {
        title: 'Little Fires Everywhere',
        author: 'Celeste Ng',
        authorLink: 'https://www.goodreads.com/author/show/164692.Celeste_Ng',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1522684533l/34273236._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34273236-little-fires-everywhere',
        ratingsAvg: '4.10',
        ratingsNumber: '710880',
        rating: '3',
    },
    {
        title: 'Almost Midnight',
        author: 'Rainbow Rowell',
        authorLink: 'https://www.goodreads.com/author/show/4208569.Rainbow_Rowell',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1508232928l/35269543._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/35269543-almost-midnight',
        ratingsAvg: '4.12',
        ratingsNumber: '9928',
        rating: '3',
    },
    {
        title: 'The Atlas of Love',
        author: 'Laurie Frankel',
        authorLink: 'https://www.goodreads.com/author/show/300536.Laurie_Frankel',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1315801946l/8865353._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/8865353-the-atlas-of-love',
        ratingsAvg: '3.64',
        ratingsNumber: '2166',
        rating: '2',
    },
    {
        title: 'Goodbye for Now',
        author: 'Laurie Frankel',
        authorLink: 'https://www.goodreads.com/author/show/300536.Laurie_Frankel',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1338409189l/13155271._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13155271-goodbye-for-now',
        ratingsAvg: '3.59',
        ratingsNumber: '4146',
        rating: '2',
    },
    {
        title: 'This Is How It Always Is',
        author: 'Laurie Frankel',
        authorLink: 'https://www.goodreads.com/author/show/300536.Laurie_Frankel',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1459324661l/29740478._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/29740478-this-is-how-it-always-is',
        ratingsAvg: '4.25',
        ratingsNumber: '111040',
        rating: '4',
    },
    {
        title: 'My Not So Perfect Life',
        author: 'Sophie Kinsella',
        authorLink: 'https://www.goodreads.com/author/show/6160.Sophie_Kinsella',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1498911887l/30688763._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/30688763-my-not-so-perfect-life',
        ratingsAvg: '3.80',
        ratingsNumber: '94259',
        rating: '2',
    },
    {
        title: 'The City & the City',
        author: 'China Mi\u00e9ville',
        authorLink: 'https://www.goodreads.com/author/show/33918.China_Mi_ville',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1320475957l/4703581._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/4703581-the-city-the-city',
        ratingsAvg: '3.92',
        ratingsNumber: '53228',
        rating: '4',
    },
    {
        title: 'Version Control',
        author: 'Dexter Palmer',
        authorLink: 'https://www.goodreads.com/author/show/3039800.Dexter_Palmer',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1439837238l/25733442._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25733442-version-control',
        ratingsAvg: '3.74',
        ratingsNumber: '6013',
        rating: '2',
    },
    {
        title: 'Station Eleven',
        author: 'Emily St. John Mandel',
        authorLink: 'https://www.goodreads.com/author/show/2786093.Emily_St_John_Mandel',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1451446835l/20170404._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20170404-station-eleven',
        ratingsAvg: '4.05',
        ratingsNumber: '321485',
        rating: '3',
    },
    {
        title: 'Carry On',
        author: 'Rainbow Rowell',
        authorLink: 'https://www.goodreads.com/author/show/4208569.Rainbow_Rowell',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1481729252l/32768522._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/32768522-carry-on',
        ratingsAvg: '4.22',
        ratingsNumber: '204946',
        rating: '3',
    },
    {
        title: 'The Bookshop on the Corner',
        author: 'Jenny Colgan',
        authorLink: 'https://www.goodreads.com/author/show/39272.Jenny_Colgan',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1465668472l/28372019._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28372019-the-bookshop-on-the-corner',
        ratingsAvg: '3.85',
        ratingsNumber: '51321',
        rating: '2',
    },
    {
        title: 'We Love You, Charlie Freeman',
        author: 'Kaitlyn Greenidge',
        authorLink: 'https://www.goodreads.com/author/show/14131894.Kaitlyn_Greenidge',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1456068262l/25810630._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25810630-we-love-you-charlie-freeman',
        ratingsAvg: '3.48',
        ratingsNumber: '3009',
        rating: '2',
    },
    {
        title: 'Sweetbitter',
        author: 'Stephanie Danler',
        authorLink: 'https://www.goodreads.com/author/show/9824300.Stephanie_Danler',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1448999786l/26192646._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/26192646-sweetbitter',
        ratingsAvg: '3.29',
        ratingsNumber: '55252',
        rating: '2',
    },
    {
        title: 'The Great American Whatever',
        author: 'Tim Federle',
        authorLink: 'https://www.goodreads.com/author/show/5446689.Tim_Federle',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1433439493l/25663382._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25663382-the-great-american-whatever',
        ratingsAvg: '3.70',
        ratingsNumber: '6061',
        rating: '2',
    },
    {
        title: 'Art Girls Are Easy',
        author: 'Julie Klausner',
        authorLink: 'https://www.goodreads.com/author/show/3026523.Julie_Klausner',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1356080538l/16131102._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/16131102-art-girls-are-easy',
        ratingsAvg: '2.93',
        ratingsNumber: '175',
        rating: '1',
    },
    {
        title: 'Beauty Queens',
        author: 'Libba Bray',
        authorLink: 'https://www.goodreads.com/author/show/2526.Libba_Bray',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1289410187l/9464733._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/9464733-beauty-queens',
        ratingsAvg: '3.61',
        ratingsNumber: '50034',
        rating: '4',
    },
    {
        title: 'Thirteen Reasons Why',
        author: 'Jay Asher',
        authorLink: 'https://www.goodreads.com/author/show/569269.Jay_Asher',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1555345043l/29844228._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/29844228-thirteen-reasons-why',
        ratingsAvg: '3.92',
        ratingsNumber: '772227',
        rating: '3',
    },
    {
        title: 'Ready Player One',
        author: 'Ernest Cline',
        authorLink: 'https://www.goodreads.com/author/show/31712.Ernest_Cline',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1500930947l/9969571._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/9969571-ready-player-one',
        ratingsAvg: '4.26',
        ratingsNumber: '859833',
        rating: '1',
    },
    {
        title: 'We Eat Our Own',
        author: 'Kea Wilson',
        authorLink: 'https://www.goodreads.com/author/show/14559259.Kea_Wilson',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1466344191l/27276249._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/27276249-we-eat-our-own',
        ratingsAvg: '3.33',
        ratingsNumber: '852',
        rating: '2',
    },
    {
        title: 'Eleanor Oliphant Is Completely Fine',
        author: 'Gail Honeyman',
        authorLink: 'https://www.goodreads.com/author/show/14558709.Gail_Honeyman',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1493724347l/31434883._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/31434883-eleanor-oliphant-is-completely-fine',
        ratingsAvg: '4.28',
        ratingsNumber: '707012',
        rating: '5',
    },
    {
        title: 'La Belle Sauvage',
        author: 'Philip Pullman',
        authorLink: 'https://www.goodreads.com/author/show/3618.Philip_Pullman',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1498930382l/34128219._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34128219-la-belle-sauvage',
        ratingsAvg: '4.15',
        ratingsNumber: '77726',
        rating: '2',
    },
    {
        title: 'The Wangs vs. the World',
        author: 'Jade Chang',
        authorLink: 'https://www.goodreads.com/author/show/2131108.Jade_Chang',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1451493647l/28114515._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28114515-the-wangs-vs-the-world',
        ratingsAvg: '3.30',
        ratingsNumber: '14438',
        rating: '2',
    },
    {
        title: 'Lucy and Linh',
        author: 'Alice Pung',
        authorLink: 'https://www.goodreads.com/author/show/576550.Alice_Pung',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1452362204l/25695574._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25695574-lucy-and-linh',
        ratingsAvg: '3.82',
        ratingsNumber: '3409',
        rating: '2',
    },
    {
        title: 'The Hating Game',
        author: 'Sally  Thorne',
        authorLink: 'https://www.goodreads.com/author/show/18002228.Sally_Thorne',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1467138679l/25883848._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25883848-the-hating-game',
        ratingsAvg: '4.14',
        ratingsNumber: '145379',
        rating: '1',
    },
    {
        title: 'Dark Matter',
        author: 'Blake Crouch',
        authorLink: 'https://www.goodreads.com/author/show/442240.Blake_Crouch',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1472119680l/27833670._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/27833670-dark-matter',
        ratingsAvg: '4.09',
        ratingsNumber: '259094',
        rating: '2',
    },
    {
        title: 'Exit, Pursued by a Bear',
        author: 'E.K. Johnston',
        authorLink: 'https://www.goodreads.com/author/show/7418796.E_K_Johnston',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1449491313l/25528801._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25528801-exit-pursued-by-a-bear',
        ratingsAvg: '3.92',
        ratingsNumber: '10588',
        rating: '2',
    },
    {
        title: 'Crosstalk',
        author: 'Connie Willis',
        authorLink: 'https://www.goodreads.com/author/show/14032.Connie_Willis',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1458672086l/25430248._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25430248-crosstalk',
        ratingsAvg: '3.52',
        ratingsNumber: '10039',
        rating: '1',
    },
    {
        title: 'American Gods',
        author: 'Neil Gaiman',
        authorLink: 'https://www.goodreads.com/author/show/1221698.Neil_Gaiman',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1462924585l/30165203._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/30165203-american-gods',
        ratingsAvg: '4.11',
        ratingsNumber: '757924',
        rating: '4',
    },
    {
        title: 'Moonglow',
        author: 'Michael Chabon',
        authorLink: 'https://www.goodreads.com/author/show/2715.Michael_Chabon',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1457526101l/26795307._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/26795307-moonglow',
        ratingsAvg: '3.81',
        ratingsNumber: '29470',
        rating: '3',
    },
    {
        title: 'The Wishing Game',
        author: 'Patrick Redmond',
        authorLink: 'https://www.goodreads.com/author/show/169027.Patrick_Redmond',
        year: '2003',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/472101.The_Wishing_Game',
        ratingsAvg: '3.92',
        ratingsNumber: '1225',
        rating: '0',
    },
    {
        title: 'Lolita',
        author: 'Vladimir Nabokov',
        authorLink: 'https://www.goodreads.com/author/show/5152.Vladimir_Nabokov',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1304552931l/11013199._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/11013199-lolita',
        ratingsAvg: '3.89',
        ratingsNumber: '662667',
        rating: '4',
    },
    {
        title: 'Lolita',
        author: 'Vladimir Nabokov',
        authorLink: 'https://www.goodreads.com/author/show/5152.Vladimir_Nabokov',
        year: '1995',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1377756377l/7604._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7604.Lolita',
        ratingsAvg: '3.89',
        ratingsNumber: '662667',
        rating: '3',
    },
    {
        title: 'We Need to Talk About Kevin',
        author: 'Lionel Shriver',
        authorLink: 'https://www.goodreads.com/author/show/45922.Lionel_Shriver',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327865017l/80660._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/80660.We_Need_to_Talk_About_Kevin',
        ratingsAvg: '4.05',
        ratingsNumber: '161873',
        rating: '4',
    },
    {
        title: 'The Natural Way of Things',
        author: 'Charlotte Wood',
        authorLink: 'https://www.goodreads.com/author/show/342188.Charlotte_Wood',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1436420572l/25876358._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25876358-the-natural-way-of-things',
        ratingsAvg: '3.53',
        ratingsNumber: '10864',
        rating: '4',
    },
    {
        title: 'The Dry',
        author: 'Jane Harper',
        authorLink: 'https://www.goodreads.com/author/show/556546.Jane_Harper',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1456113132l/27824826._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/27824826-the-dry',
        ratingsAvg: '4.02',
        ratingsNumber: '155215',
        rating: '1',
    },
    {
        title: 'Holidays on Ice',
        author: 'David Sedaris',
        authorLink: 'https://www.goodreads.com/author/show/2849.David_Sedaris',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1328330843l/4136._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/4136.Holidays_on_Ice',
        ratingsAvg: '3.92',
        ratingsNumber: '109207',
        rating: '4',
    },
    {
        title: 'The Idiot',
        author: 'Elif Batuman',
        authorLink: 'https://www.goodreads.com/author/show/39846.Elif_Batuman',
        year: '2017',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1474782288l/30962053._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/30962053-the-idiot',
        ratingsAvg: '3.64',
        ratingsNumber: '26336',
        rating: '4',
    },
    {
        title: 'A Wizard of Earthsea',
        author: 'Ursula K. Le Guin',
        authorLink: 'https://www.goodreads.com/author/show/874602.Ursula_K_Le_Guin',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1353424536l/13642._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13642.A_Wizard_of_Earthsea',
        ratingsAvg: '3.99',
        ratingsNumber: '240725',
        rating: '4',
    },
    {
        title: 'Harry Potter and the Cursed Child: Parts One and Two',
        author: 'John Tiffany',
        authorLink: 'https://www.goodreads.com/author/show/5042201.John_Tiffany',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1470082995l/29056083._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/29056083-harry-potter-and-the-cursed-child',
        ratingsAvg: '3.62',
        ratingsNumber: '700846',
        rating: '2',
    },
    {
        title: 'The Color of Magic',
        author: 'Terry Pratchett',
        authorLink: 'https://www.goodreads.com/author/show/1654.Terry_Pratchett',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1407111017l/34497._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34497.The_Color_of_Magic',
        ratingsAvg: '3.99',
        ratingsNumber: '315933',
        rating: '3',
    },
    {
        title: 'Dreams of Gods and Monsters',
        author: 'Laini Taylor',
        authorLink: 'https://www.goodreads.com/author/show/324620.Laini_Taylor',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1461353810l/13618440._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13618440-dreams-of-gods-and-monsters',
        ratingsAvg: '4.15',
        ratingsNumber: '87958',
        rating: '0',
    },
    {
        title: 'Night of Cake & Puppets',
        author: 'Laini Taylor',
        authorLink: 'https://www.goodreads.com/author/show/324620.Laini_Taylor',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1380644215l/18360128._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18360128-night-of-cake-puppets',
        ratingsAvg: '4.32',
        ratingsNumber: '17960',
        rating: '5',
    },
    {
        title: 'In a Dark, Dark Wood',
        author: 'Ruth Ware',
        authorLink: 'https://www.goodreads.com/author/show/9013543.Ruth_Ware',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1429521615l/23346377._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23346377-in-a-dark-dark-wood',
        ratingsAvg: '3.70',
        ratingsNumber: '224999',
        rating: '2',
    },
    {
        title: 'I Had a Nice Time And Other Lies...: How to find love  sh*t like that',
        author: 'The Betches',
        authorLink: 'https://www.goodreads.com/author/show/6459702.The_Betches',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1456682713l/25814203._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25814203-i-had-a-nice-time-and-other-lies',
        ratingsAvg: '3.68',
        ratingsNumber: '1387',
        rating: '2',
    },
    {
        title: 'The Subtle Art of Not Giving a F*ck: A Counterintuitive Approach to Living a Good Life',
        author: 'Mark Manson',
        authorLink: 'https://www.goodreads.com/author/show/8529755.Mark_Manson',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1465761302l/28257707._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28257707-the-subtle-art-of-not-giving-a-f-ck',
        ratingsAvg: '3.94',
        ratingsNumber: '528212',
        rating: '4',
    },
    {
        title: 'The Fireman',
        author: 'Joe Hill',
        authorLink: 'https://www.goodreads.com/author/show/88506.Joe_Hill',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1469316385l/25816688._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25816688-the-fireman',
        ratingsAvg: '3.90',
        ratingsNumber: '50893',
        rating: '1',
    },
    {
        title: "He's Just Not That Into You: The No-Excuses Truth to Understanding Guys",
        author: 'Greg Behrendt',
        authorLink: 'https://www.goodreads.com/author/show/6842.Greg_Behrendt',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/10412.He_s_Just_Not_That_Into_You',
        ratingsAvg: '3.66',
        ratingsNumber: '61628',
        rating: '3',
    },
    {
        title: "My Best Friend's Exorcism",
        author: 'Grady Hendrix',
        authorLink: 'https://www.goodreads.com/author/show/4826394.Grady_Hendrix',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1456208235l/26118005._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/26118005-my-best-friend-s-exorcism',
        ratingsAvg: '3.96',
        ratingsNumber: '25636',
        rating: '2',
    },
    {
        title: 'Marked in Flesh',
        author: 'Anne Bishop',
        authorLink: 'https://www.goodreads.com/author/show/26897.Anne_Bishop',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1438874868l/22062202._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22062202-marked-in-flesh',
        ratingsAvg: '4.28',
        ratingsNumber: '23367',
        rating: '1',
    },
    {
        title: 'All the Birds in the Sky',
        author: 'Charlie Jane Anders',
        authorLink: 'https://www.goodreads.com/author/show/4918514.Charlie_Jane_Anders',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1429225322l/25372801._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25372801-all-the-birds-in-the-sky',
        ratingsAvg: '3.59',
        ratingsNumber: '37515',
        rating: '3',
    },
    {
        title: 'Behind Closed Doors',
        author: 'B.A. Paris',
        authorLink: 'https://www.goodreads.com/author/show/14425604.B_A_Paris',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1458854462l/29437949._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/29437949-behind-closed-doors',
        ratingsAvg: '3.95',
        ratingsNumber: '302415',
        rating: '2',
    },
    {
        title: 'Lilac Girls',
        author: 'Martha Hall Kelly',
        authorLink: 'https://www.goodreads.com/author/show/14160478.Martha_Hall_Kelly',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1462850258l/25893693._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25893693-lilac-girls',
        ratingsAvg: '4.28',
        ratingsNumber: '212859',
        rating: '3',
    },
    {
        title: 'Eligible: A Modern Retelling of Pride & Prejudice',
        author: 'Curtis Sittenfeld',
        authorLink: 'https://www.goodreads.com/author/show/6429.Curtis_Sittenfeld',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1460477855l/25852870._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25852870-eligible',
        ratingsAvg: '3.61',
        ratingsNumber: '76823',
        rating: '2',
    },
    {
        title: 'Small Great Things',
        author: 'Jodi Picoult',
        authorLink: 'https://www.goodreads.com/author/show/7128.Jodi_Picoult',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1468057481l/28587957._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28587957-small-great-things',
        ratingsAvg: '4.34',
        ratingsNumber: '265417',
        rating: '1',
    },
    {
        title: 'The Nest',
        author: "Cynthia D'Aprix Sweeney",
        authorLink: 'https://www.goodreads.com/author/show/393731.Cynthia_D_Aprix_Sweeney',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1461354827l/25781157._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25781157-the-nest',
        ratingsAvg: '3.42',
        ratingsNumber: '155360',
        rating: '4',
    },
    {
        title: 'Black Iris',
        author: 'Elliot Wake',
        authorLink: 'https://www.goodreads.com/author/show/15254487.Elliot_Wake',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1420737066l/18829666._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18829666-black-iris',
        ratingsAvg: '3.72',
        ratingsNumber: '3682',
        rating: '3',
    },
    {
        title: 'Buffering: Unshared Tales of a Life Fully Loaded',
        author: 'Hannah Hart',
        authorLink: 'https://www.goodreads.com/author/show/7276000.Hannah_Hart',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1463949051l/28217831._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28217831-buffering',
        ratingsAvg: '4.26',
        ratingsNumber: '10197',
        rating: '4',
    },
    {
        title: 'Cam Girl',
        author: 'Elliot Wake',
        authorLink: 'https://www.goodreads.com/author/show/15254487.Elliot_Wake',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1441208688l/23430483._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23430483-cam-girl',
        ratingsAvg: '3.69',
        ratingsNumber: '1788',
        rating: '2',
    },
    {
        title: 'Burned',
        author: 'Karen Marie Moning',
        authorLink: 'https://www.goodreads.com/author/show/48206.Karen_Marie_Moning',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1407503200l/12444297._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/12444297-burned',
        ratingsAvg: '4.17',
        ratingsNumber: '32747',
        rating: '1',
    },
    {
        title: 'Faefever',
        author: 'Karen Marie Moning',
        authorLink: 'https://www.goodreads.com/author/show/48206.Karen_Marie_Moning',
        year: '2008',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/2702704-faefever',
        ratingsAvg: '4.31',
        ratingsNumber: '91291',
        rating: '2',
    },
    {
        title: 'Dreamfever',
        author: 'Karen Marie Moning',
        authorLink: 'https://www.goodreads.com/author/show/48206.Karen_Marie_Moning',
        year: '2009',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/6050298-dreamfever',
        ratingsAvg: '4.41',
        ratingsNumber: '90383',
        rating: '2',
    },
    {
        title: 'Shadowfever',
        author: 'Karen Marie Moning',
        authorLink: 'https://www.goodreads.com/author/show/48206.Karen_Marie_Moning',
        year: '2011',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/7304203-shadowfever',
        ratingsAvg: '4.41',
        ratingsNumber: '97825',
        rating: '3',
    },
    {
        title: 'Bloodfever',
        author: 'Karen Marie Moning',
        authorLink: 'https://www.goodreads.com/author/show/48206.Karen_Marie_Moning',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1302566396l/344262._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/344262.Bloodfever',
        ratingsAvg: '4.28',
        ratingsNumber: '94543',
        rating: '2',
    },
    {
        title: 'Darkfever',
        author: 'Karen Marie Moning',
        authorLink: 'https://www.goodreads.com/author/show/48206.Karen_Marie_Moning',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1392579949l/112750._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/112750.Darkfever',
        ratingsAvg: '4.09',
        ratingsNumber: '155110',
        rating: '2',
    },
    {
        title: 'The Amazing Adventures of Kavalier & Clay',
        author: 'Michael Chabon',
        authorLink: 'https://www.goodreads.com/author/show/2715.Michael_Chabon',
        year: '2001',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1503806495l/3985._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/3985.The_Amazing_Adventures_of_Kavalier_Clay',
        ratingsAvg: '4.18',
        ratingsNumber: '183967',
        rating: '5',
    },
    {
        title: 'Truly Madly Guilty',
        author: 'Liane Moriarty',
        authorLink: 'https://www.goodreads.com/author/show/322069.Liane_Moriarty',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1491249206l/26247008._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/26247008-truly-madly-guilty',
        ratingsAvg: '3.57',
        ratingsNumber: '236375',
        rating: '3',
    },
    {
        title: 'All the Light We Cannot See',
        author: 'Anthony Doerr',
        authorLink: 'https://www.goodreads.com/author/show/28186.Anthony_Doerr',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1451445646l/18143977._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18143977-all-the-light-we-cannot-see',
        ratingsAvg: '4.33',
        ratingsNumber: '1023366',
        rating: '4',
    },
    {
        title: 'A Gathering of Shadows',
        author: 'V.E. Schwab',
        authorLink: 'https://www.goodreads.com/author/show/7168230.V_E_Schwab',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1429627728l/20764879._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20764879-a-gathering-of-shadows',
        ratingsAvg: '4.26',
        ratingsNumber: '106769',
        rating: '3',
    },
    {
        title: 'Days of Blood & Starlight',
        author: 'Laini Taylor',
        authorLink: 'https://www.goodreads.com/author/show/324620.Laini_Taylor',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1461353798l/12812550._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/12812550-days-of-blood-starlight',
        ratingsAvg: '4.15',
        ratingsNumber: '138624',
        rating: '3',
    },
    {
        title: 'Daughter of Smoke & Bone',
        author: 'Laini Taylor',
        authorLink: 'https://www.goodreads.com/author/show/324620.Laini_Taylor',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1461353773l/8490112._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/8490112-daughter-of-smoke-bone',
        ratingsAvg: '4.01',
        ratingsNumber: '310473',
        rating: '3',
    },
    {
        title: 'In Her Shoes',
        author: 'Jennifer Weiner',
        authorLink: 'https://www.goodreads.com/author/show/9212.Jennifer_Weiner',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1435252471l/14758._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/14758.In_Her_Shoes',
        ratingsAvg: '3.79',
        ratingsNumber: '198112',
        rating: '3',
    },
    {
        title: 'Fangirl',
        author: 'Rainbow Rowell',
        authorLink: 'https://www.goodreads.com/author/show/4208569.Rainbow_Rowell',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1355886270l/16068905._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/16068905-fangirl',
        ratingsAvg: '4.05',
        ratingsNumber: '574427',
        rating: '2',
    },
    {
        title: 'The Help',
        author: 'Kathryn Stockett',
        authorLink: 'https://www.goodreads.com/author/show/1943477.Kathryn_Stockett',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1572940430l/4667024._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/4667024-the-help',
        ratingsAvg: '4.47',
        ratingsNumber: '2162550',
        rating: '5',
    },
    {
        title: 'Can You Keep a Secret?',
        author: 'Sophie Kinsella',
        authorLink: 'https://www.goodreads.com/author/show/6160.Sophie_Kinsella',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1330464293l/33724._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/33724.Can_You_Keep_a_Secret_',
        ratingsAvg: '3.84',
        ratingsNumber: '376525',
        rating: '2',
    },
    {
        title: 'A Darker Shade of Magic',
        author: 'V.E. Schwab',
        authorLink: 'https://www.goodreads.com/author/show/7168230.V_E_Schwab',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1400322851l/22055262._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22055262-a-darker-shade-of-magic',
        ratingsAvg: '4.07',
        ratingsNumber: '200972',
        rating: '2',
    },
    {
        title: 'Remember Me?',
        author: 'Sophie Kinsella',
        authorLink: 'https://www.goodreads.com/author/show/6160.Sophie_Kinsella',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1276963901l/1358844._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/1358844.Remember_Me_',
        ratingsAvg: '3.78',
        ratingsNumber: '220827',
        rating: '1',
    },
    {
        title: 'The Undomestic Goddess',
        author: 'Sophie Kinsella',
        authorLink: 'https://www.goodreads.com/author/show/6160.Sophie_Kinsella',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1546431135l/33722._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/33722.The_Undomestic_Goddess',
        ratingsAvg: '3.84',
        ratingsNumber: '258530',
        rating: '2',
    },
    {
        title: 'Finding Audrey',
        author: 'Sophie Kinsella',
        authorLink: 'https://www.goodreads.com/author/show/6160.Sophie_Kinsella',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1466835008l/23305614._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23305614-finding-audrey',
        ratingsAvg: '3.77',
        ratingsNumber: '67730',
        rating: '3',
    },
    {
        title: 'Summer at Little Beach Street Bakery',
        author: 'Jenny Colgan',
        authorLink: 'https://www.goodreads.com/author/show/39272.Jenny_Colgan',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1414643214l/23477296._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23477296-summer-at-little-beach-street-bakery',
        ratingsAvg: '3.87',
        ratingsNumber: '12198',
        rating: '1',
    },
    {
        title: 'On Tour',
        author: 'Zoe Sugg',
        authorLink: 'https://www.goodreads.com/author/show/8316649.Zoe_Sugg',
        year: null,
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/27275625-on-tour',
        ratingsAvg: '3.82',
        ratingsNumber: '22527',
        rating: '1',
    },
    {
        title: 'The Royal We',
        author: 'Heather Cocks',
        authorLink: 'https://www.goodreads.com/author/show/1141204.Heather_Cocks',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1421107274l/22875451._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22875451-the-royal-we',
        ratingsAvg: '3.82',
        ratingsNumber: '48499',
        rating: '1',
    },
    {
        title: 'What Alice Forgot',
        author: 'Liane Moriarty',
        authorLink: 'https://www.goodreads.com/author/show/322069.Liane_Moriarty',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1377159022l/6469165._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6469165-what-alice-forgot',
        ratingsAvg: '4.08',
        ratingsNumber: '356508',
        rating: '3',
    },
    {
        title: "The Husband's Secret",
        author: 'Liane Moriarty',
        authorLink: 'https://www.goodreads.com/author/show/322069.Liane_Moriarty',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1460577225l/17802724._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/17802724-the-husband-s-secret',
        ratingsAvg: '3.94',
        ratingsNumber: '531875',
        rating: '4',
    },
    {
        title: 'Dangerous Girls',
        author: 'Abigail Haas',
        authorLink: 'https://www.goodreads.com/author/show/6551240.Abigail_Haas',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1356513050l/16074758._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/16074758-dangerous-girls',
        ratingsAvg: '4.07',
        ratingsNumber: '20903',
        rating: '2',
    },
    {
        title: 'Darkfever',
        author: 'Karen Marie Moning',
        authorLink: 'https://www.goodreads.com/author/show/48206.Karen_Marie_Moning',
        year: '2008',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1451694.Darkfever',
        ratingsAvg: '4.09',
        ratingsNumber: '155110',
        rating: '2',
    },
    {
        title: 'Dangerous Boys',
        author: 'Abigail Haas',
        authorLink: 'https://www.goodreads.com/author/show/6551240.Abigail_Haas',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1401967915l/19732381._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/19732381-dangerous-boys',
        ratingsAvg: '3.69',
        ratingsNumber: '4026',
        rating: '1',
    },
    {
        title: 'Twilight / Life and Death',
        author: 'Stephenie Meyer',
        authorLink: 'https://www.goodreads.com/author/show/941441.Stephenie_Meyer',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1506725125l/26860475._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/26860475-twilight-life-and-death',
        ratingsAvg: '3.43',
        ratingsNumber: '26608',
        rating: '1',
    },
    {
        title: 'This Song Will Save Your Life',
        author: 'Leila Sales',
        authorLink: 'https://www.goodreads.com/author/show/3390621.Leila_Sales',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1362753915l/15777621._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/15777621-this-song-will-save-your-life',
        ratingsAvg: '3.92',
        ratingsNumber: '25699',
        rating: '3',
    },
    {
        title: 'Red Queen',
        author: 'Christina Henry',
        authorLink: 'https://www.goodreads.com/author/show/3409936.Christina_Henry',
        year: '2016',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1453056797l/27246122._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/27246122-red-queen',
        ratingsAvg: '3.83',
        ratingsNumber: '7466',
        rating: '2',
    },
    {
        title: 'Alice',
        author: 'Christina Henry',
        authorLink: 'https://www.goodreads.com/author/show/3409936.Christina_Henry',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1531783537l/23398606._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23398606-alice',
        ratingsAvg: '3.86',
        ratingsNumber: '22856',
        rating: '3',
    },
    {
        title: 'On Tour',
        author: 'Zoe Sugg',
        authorLink: 'https://www.goodreads.com/author/show/8316649.Zoe_Sugg',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1438283224l/23208406._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23208406-on-tour',
        ratingsAvg: '3.82',
        ratingsNumber: '22527',
        rating: '1',
    },
    {
        title: 'Binge',
        author: 'Tyler Oakley',
        authorLink: 'https://www.goodreads.com/author/show/8301425.Tyler_Oakley',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1439787297l/25246752._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25246752-binge',
        ratingsAvg: '4.21',
        ratingsNumber: '18695',
        rating: '2',
    },
    {
        title: 'Why Not Me?',
        author: 'Mindy Kaling',
        authorLink: 'https://www.goodreads.com/author/show/194416.Mindy_Kaling',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1442548684l/22716447._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22716447-why-not-me',
        ratingsAvg: '3.91',
        ratingsNumber: '176370',
        rating: '2',
    },
    {
        title: 'Everything, Everything',
        author: 'Nicola Yoon',
        authorLink: 'https://www.goodreads.com/author/show/7353006.Nicola_Yoon',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1450515891l/18692431._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18692431-everything-everything',
        ratingsAvg: '4.05',
        ratingsNumber: '378434',
        rating: '3',
    },
    {
        title: 'Simon vs. the Homo Sapiens Agenda',
        author: 'Becky Albertalli',
        authorLink: 'https://www.goodreads.com/author/show/7579036.Becky_Albertalli',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1402915678l/19547856._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/19547856-simon-vs-the-homo-sapiens-agenda',
        ratingsAvg: '4.29',
        ratingsNumber: '287915',
        rating: '3',
    },
    {
        title: 'Circus Mirandus',
        author: 'Cassie Beasley',
        authorLink: 'https://www.goodreads.com/author/show/8016035.Cassie_Beasley',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1462663576l/23281919._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23281919-circus-mirandus',
        ratingsAvg: '4.09',
        ratingsNumber: '8897',
        rating: '5',
    },
    {
        title: 'The Girl on the Train',
        author: 'Paula Hawkins',
        authorLink: 'https://www.goodreads.com/author/show/1063732.Paula_Hawkins',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1574805682l/22557272._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22557272-the-girl-on-the-train',
        ratingsAvg: '3.93',
        ratingsNumber: '2109484',
        rating: '2',
    },
    {
        title: 'Watashitachi no Shiawase na Jikan',
        author: 'Gong Jiyoung',
        authorLink: 'https://www.goodreads.com/author/show/13919686.Gong_Jiyoung',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1263230203l/7543231._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7543231-watashitachi-no-shiawase-na-jikan',
        ratingsAvg: '4.44',
        ratingsNumber: '1520',
        rating: '2',
    },
    {
        title: 'Royal Wedding',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1418786285l/23215471._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23215471-royal-wedding',
        ratingsAvg: '3.92',
        ratingsNumber: '14063',
        rating: '3',
    },
    {
        title: 'Valentine Princess',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/23222.Valentine_Princess',
        ratingsAvg: '3.67',
        ratingsNumber: '9989',
        rating: '1',
    },
    {
        title: 'Sweet Sixteen Princess',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1578791081l/93732._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/93732.Sweet_Sixteen_Princess',
        ratingsAvg: '3.66',
        ratingsNumber: '10306',
        rating: '1',
    },
    {
        title: 'The Princess Present',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2004',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/93739.The_Princess_Present',
        ratingsAvg: '3.62',
        ratingsNumber: '11093',
        rating: '1',
    },
    {
        title: 'Blackout. Morgen ist es zu sp\u00e4t',
        author: 'Marc Elsberg',
        authorLink: 'https://www.goodreads.com/author/show/5352000.Marc_Elsberg',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1332421033l/13113378._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13113378-blackout-morgen-ist-es-zu-sp-t',
        ratingsAvg: '3.95',
        ratingsNumber: '8726',
        rating: '1',
    },
    {
        title: 'The Martian',
        author: 'Andy Weir',
        authorLink: 'https://www.goodreads.com/author/show/6540057.Andy_Weir',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1413706054l/18007564._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18007564-the-martian',
        ratingsAvg: '4.40',
        ratingsNumber: '816678',
        rating: '3',
    },
    {
        title: 'Specials',
        author: 'Scott Westerfeld',
        authorLink: 'https://www.goodreads.com/author/show/13957.Scott_Westerfeld',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/24765.Specials',
        ratingsAvg: '3.76',
        ratingsNumber: '168464',
        rating: '2',
    },
    {
        title: 'Pretties',
        author: 'Scott Westerfeld',
        authorLink: 'https://www.goodreads.com/author/show/13957.Scott_Westerfeld',
        year: '2005',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/24768.Pretties',
        ratingsAvg: '3.85',
        ratingsNumber: '230250',
        rating: '1',
    },
    {
        title: 'Uglies',
        author: 'Scott Westerfeld',
        authorLink: 'https://www.goodreads.com/author/show/13957.Scott_Westerfeld',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1443904172l/24770._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/24770.Uglies',
        ratingsAvg: '3.86',
        ratingsNumber: '559905',
        rating: '1',
    },
    {
        title: "The Time Traveler's Wife",
        author: 'Audrey Niffenegger',
        authorLink: 'https://www.goodreads.com/author/show/498072.Audrey_Niffenegger',
        year: '2003',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1437728815l/14050._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/14050.The_Time_Traveler_s_Wife',
        ratingsAvg: '3.97',
        ratingsNumber: '1573097',
        rating: '2',
    },
    {
        title: 'The Running Man',
        author: 'Richard Bachman',
        authorLink: 'https://www.goodreads.com/author/show/5858.Richard_Bachman',
        year: '1999',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1333160557l/11607._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/11607.The_Running_Man',
        ratingsAvg: '3.87',
        ratingsNumber: '102672',
        rating: '2',
    },
    {
        title: 'Next',
        author: 'Michael Crichton',
        authorLink: 'https://www.goodreads.com/author/show/5194.Michael_Crichton',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1554225620l/7661._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7661.Next',
        ratingsAvg: '3.52',
        ratingsNumber: '66770',
        rating: '2',
    },
    {
        title: 'Beautiful Bastard',
        author: 'Christina Lauren',
        authorLink: 'https://www.goodreads.com/author/show/6556689.Christina_Lauren',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1362258921l/16102004._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/16102004-beautiful-bastard',
        ratingsAvg: '4.01',
        ratingsNumber: '152831',
        rating: '3',
    },
    {
        title: 'Beautiful Player',
        author: 'Christina Lauren',
        authorLink: 'https://www.goodreads.com/author/show/6556689.Christina_Lauren',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1375285002l/17831614._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/17831614-beautiful-player',
        ratingsAvg: '4.33',
        ratingsNumber: '55300',
        rating: '3',
    },
    {
        title: 'Beautiful Stranger',
        author: 'Christina Lauren',
        authorLink: 'https://www.goodreads.com/author/show/6556689.Christina_Lauren',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1359073239l/16117506._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/16117506-beautiful-stranger',
        ratingsAvg: '4.20',
        ratingsNumber: '72525',
        rating: '3',
    },
    {
        title: 'The Master Magician',
        author: 'Charlie N. Holmberg',
        authorLink: 'https://www.goodreads.com/author/show/7801879.Charlie_N_Holmberg',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1422130439l/23485372._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23485372-the-master-magician',
        ratingsAvg: '3.86',
        ratingsNumber: '28926',
        rating: '2',
    },
    {
        title: 'The Glass Magician',
        author: 'Charlie N. Holmberg',
        authorLink: 'https://www.goodreads.com/author/show/7801879.Charlie_N_Holmberg',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1410466975l/22341276._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22341276-the-glass-magician',
        ratingsAvg: '3.90',
        ratingsNumber: '36019',
        rating: '2',
    },
    {
        title: 'The Paper Magician',
        author: 'Charlie N. Holmberg',
        authorLink: 'https://www.goodreads.com/author/show/7801879.Charlie_N_Holmberg',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1405618531l/20727654._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20727654-the-paper-magician',
        ratingsAvg: '3.65',
        ratingsNumber: '67807',
        rating: '3',
    },
    {
        title: 'The Good Girl',
        author: 'Mary Kubica',
        authorLink: 'https://www.goodreads.com/author/show/7392948.Mary_Kubica',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1388340126l/18812405._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18812405-the-good-girl',
        ratingsAvg: '3.81',
        ratingsNumber: '196898',
        rating: '1',
    },
    {
        title: 'Silence',
        author: 'Becca Fitzpatrick',
        authorLink: 'https://www.goodreads.com/author/show/2876763.Becca_Fitzpatrick',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1362408152l/10637766._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10637766-silence',
        ratingsAvg: '4.12',
        ratingsNumber: '243728',
        rating: '1',
    },
    {
        title: 'Crescendo',
        author: 'Becca Fitzpatrick',
        authorLink: 'https://www.goodreads.com/author/show/2876763.Becca_Fitzpatrick',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1362408146l/7791997._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7791997-crescendo',
        ratingsAvg: '4.04',
        ratingsNumber: '274460',
        rating: '1',
    },
    {
        title: 'Molly Moon Stops the World',
        author: 'Georgia Byng',
        authorLink: 'https://www.goodreads.com/author/show/371202.Georgia_Byng',
        year: '2005',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/799073.Molly_Moon_Stops_the_World',
        ratingsAvg: '3.94',
        ratingsNumber: '7857',
        rating: '2',
    },
    {
        title: "Molly Moon's Incredible Book of Hypnotism",
        author: 'Georgia Byng',
        authorLink: 'https://www.goodreads.com/author/show/371202.Georgia_Byng',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1330953305l/807968._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/807968.Molly_Moon_s_Incredible_Book_of_Hypnotism',
        ratingsAvg: '3.89',
        ratingsNumber: '22104',
        rating: '3',
    },
    {
        title: 'Me and Earl and the Dying Girl',
        author: 'Jesse Andrews',
        authorLink: 'https://www.goodreads.com/author/show/5227163.Jesse_Andrews',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1337216932l/12700353._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/12700353-me-and-earl-and-the-dying-girl',
        ratingsAvg: '3.55',
        ratingsNumber: '120680',
        rating: '3',
    },
    {
        title: 'Church of Marvels',
        author: 'Leslie Parry',
        authorLink: 'https://www.goodreads.com/author/show/8470839.Leslie_Parry',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1428342215l/25308876._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/25308876-church-of-marvels',
        ratingsAvg: '3.78',
        ratingsNumber: '9309',
        rating: '2',
    },
    {
        title: 'The Fault in Our Pants',
        author: 'Steve Lookner',
        authorLink: 'https://www.goodreads.com/author/show/8385066.Steve_Lookner',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1406179018l/22719230._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22719230-the-fault-in-our-pants',
        ratingsAvg: '3.97',
        ratingsNumber: '1232',
        rating: '1',
    },
    {
        title: 'On Grace:First Edition',
        author: 'Susie Schnall',
        authorLink: 'https://www.goodreads.com/author/show/7867914.Susie_Schnall',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1371848352l/18108557._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18108557-on-grace',
        ratingsAvg: '3.57',
        ratingsNumber: '623',
        rating: '1',
    },
    {
        title: 'The Night We Said Yes',
        author: 'Lauren Gibaldi',
        authorLink: 'https://www.goodreads.com/author/show/7214756.Lauren_Gibaldi',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1412947397l/23287168._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/23287168-the-night-we-said-yes',
        ratingsAvg: '3.59',
        ratingsNumber: '5026',
        rating: '1',
    },
    {
        title: 'Eleanor & Park',
        author: 'Rainbow Rowell',
        authorLink: 'https://www.goodreads.com/author/show/4208569.Rainbow_Rowell',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1341952742l/15745753._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/15745753-eleanor-park',
        ratingsAvg: '4.05',
        ratingsNumber: '859562',
        rating: '1',
    },
    {
        title: 'The Fault in Our Stars',
        author: 'John Green',
        authorLink: 'https://www.goodreads.com/author/show/1406384.John_Green',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1360206420l/11870085._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/11870085-the-fault-in-our-stars',
        ratingsAvg: '4.21',
        ratingsNumber: '3548021',
        rating: '1',
    },
    {
        title: 'Girl Online',
        author: 'Zoe Sugg',
        authorLink: 'https://www.goodreads.com/author/show/8316649.Zoe_Sugg',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1410112710l/22510983._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22510983-girl-online',
        ratingsAvg: '3.71',
        ratingsNumber: '53875',
        rating: '0',
    },
    {
        title: 'Biedermann und die Brandstifter',
        author: 'Max Frisch',
        authorLink: 'https://www.goodreads.com/author/show/6530.Max_Frisch',
        year: '1996',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1132279.Biedermann_und_die_Brandstifter',
        ratingsAvg: '3.72',
        ratingsNumber: '3990',
        rating: '0',
    },
    {
        title: 'Hush, Hush',
        author: 'Becca Fitzpatrick',
        authorLink: 'https://www.goodreads.com/author/show/2876763.Becca_Fitzpatrick',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1358261334l/6339664._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6339664-hush-hush',
        ratingsAvg: '3.97',
        ratingsNumber: '573028',
        rating: '1',
    },
    {
        title: 'Achtung, Zeitfalle!',
        author: 'Andreas Schl\u00fcter',
        authorLink: 'https://www.goodreads.com/author/show/187704.Andreas_Schl_ter',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1546162079l/3266756._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/3266756-achtung-zeitfalle',
        ratingsAvg: '3.88',
        ratingsNumber: '75',
        rating: '2',
    },
    {
        title: 'Level 4 \u2013 Die Stadt der Kinder',
        author: 'Andreas Schl\u00fcter',
        authorLink: 'https://www.goodreads.com/author/show/187704.Andreas_Schl_ter',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1546160264l/6419341._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6419341-level-4-die-stadt-der-kinder',
        ratingsAvg: '3.50',
        ratingsNumber: '452',
        rating: '1',
    },
    {
        title: 'The Strange and Beautiful Sorrows of Ava Lavender',
        author: 'Leslye Walton',
        authorLink: 'https://www.goodreads.com/author/show/6441917.Leslye_Walton',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1397110326l/18166936._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18166936-the-strange-and-beautiful-sorrows-of-ava-lavender',
        ratingsAvg: '4.06',
        ratingsNumber: '34554',
        rating: '5',
    },
    {
        title: 'Opposition',
        author: 'Jennifer L. Armentrout',
        authorLink: 'https://www.goodreads.com/author/show/4476934.Jennifer_L_Armentrout',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1398694101l/13644055._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13644055-opposition',
        ratingsAvg: '4.38',
        ratingsNumber: '64348',
        rating: '2',
    },
    {
        title: 'Origin',
        author: 'Jennifer L. Armentrout',
        authorLink: 'https://www.goodreads.com/author/show/4476934.Jennifer_L_Armentrout',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1398100888l/13644052._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13644052-origin',
        ratingsAvg: '4.37',
        ratingsNumber: '88606',
        rating: '1',
    },
    {
        title: 'Opal',
        author: 'Jennifer L. Armentrout',
        authorLink: 'https://www.goodreads.com/author/show/4476934.Jennifer_L_Armentrout',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1553011318l/13362536._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13362536-opal',
        ratingsAvg: '4.29',
        ratingsNumber: '123526',
        rating: '1',
    },
    {
        title: 'Onyx',
        author: 'Jennifer L. Armentrout',
        authorLink: 'https://www.goodreads.com/author/show/4476934.Jennifer_L_Armentrout',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1329408511l/13047090._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13047090-onyx',
        ratingsAvg: '4.28',
        ratingsNumber: '147154',
        rating: '1',
    },
    {
        title: 'Obsidian',
        author: 'Jennifer L. Armentrout',
        authorLink: 'https://www.goodreads.com/author/show/4476934.Jennifer_L_Armentrout',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1328275091l/12578077._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/12578077-obsidian',
        ratingsAvg: '4.18',
        ratingsNumber: '225379',
        rating: '1',
    },
    {
        title: 'Mr. Mercedes',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1468705326l/18775247._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18775247-mr-mercedes',
        ratingsAvg: '3.97',
        ratingsNumber: '241013',
        rating: '1',
    },
    {
        title: 'Landline',
        author: 'Rainbow Rowell',
        authorLink: 'https://www.goodreads.com/author/show/4208569.Rainbow_Rowell',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1499378092l/18081809._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18081809-landline',
        ratingsAvg: '3.55',
        ratingsNumber: '152252',
        rating: '3',
    },
    {
        title: 'A Discovery of Witches',
        author: 'Deborah Harkness',
        authorLink: 'https://www.goodreads.com/author/show/3849415.Deborah_Harkness',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1525097370l/8667848._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/8667848-a-discovery-of-witches',
        ratingsAvg: '4.00',
        ratingsNumber: '368566',
        rating: '1',
    },
    {
        title: 'Vision in Silver',
        author: 'Anne Bishop',
        authorLink: 'https://www.goodreads.com/author/show/26897.Anne_Bishop',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1404354570l/21457243._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/21457243-vision-in-silver',
        ratingsAvg: '4.25',
        ratingsNumber: '28847',
        rating: '1',
    },
    {
        title: 'Written in Red',
        author: 'Anne Bishop',
        authorLink: 'https://www.goodreads.com/author/show/26897.Anne_Bishop',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1411439502l/15711341._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/15711341-written-in-red',
        ratingsAvg: '4.26',
        ratingsNumber: '50150',
        rating: '2',
    },
    {
        title: 'Murder of Crows',
        author: 'Anne Bishop',
        authorLink: 'https://www.goodreads.com/author/show/26897.Anne_Bishop',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1371752568l/17563080._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/17563080-murder-of-crows',
        ratingsAvg: '4.28',
        ratingsNumber: '36487',
        rating: '1',
    },
    {
        title: 'The Thousand-Dollar Tan Line',
        author: 'Rob Thomas',
        authorLink: 'https://www.goodreads.com/author/show/12712.Rob_Thomas',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1393100477l/18209454._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18209454-the-thousand-dollar-tan-line',
        ratingsAvg: '3.99',
        ratingsNumber: '42090',
        rating: '3',
    },
    {
        title: 'The Enchanted',
        author: 'Rene Denfeld',
        authorLink: 'https://www.goodreads.com/author/show/56358.Rene_Denfeld',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1373991244l/18090147._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18090147-the-enchanted',
        ratingsAvg: '3.95',
        ratingsNumber: '18116',
        rating: '1',
    },
    {
        title: 'Big Little Lies',
        author: 'Liane Moriarty',
        authorLink: 'https://www.goodreads.com/author/show/322069.Liane_Moriarty',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1594851437l/19486412._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/19486412-big-little-lies',
        ratingsAvg: '4.27',
        ratingsNumber: '746900',
        rating: '5',
    },
    {
        title: 'Panic',
        author: 'Lauren Oliver',
        authorLink: 'https://www.goodreads.com/author/show/2936493.Lauren_Oliver',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1374066076l/17565845._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/17565845-panic',
        ratingsAvg: '3.56',
        ratingsNumber: '50857',
        rating: '1',
    },
    {
        title: "To All the Boys I've Loved Before",
        author: 'Jenny Han',
        authorLink: 'https://www.goodreads.com/author/show/151371.Jenny_Han',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1372086100l/15749186._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/15749186-to-all-the-boys-i-ve-loved-before',
        ratingsAvg: '4.15',
        ratingsNumber: '489783',
        rating: '1',
    },
    {
        title: 'We Were Liars',
        author: 'E. Lockhart',
        authorLink: 'https://www.goodreads.com/author/show/173491.E_Lockhart',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1402749479l/16143347._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/16143347-we-were-liars',
        ratingsAvg: '3.83',
        ratingsNumber: '464084',
        rating: '2',
    },
    {
        title: 'Never Have I Ever: My Life (So Far) Without a Date',
        author: 'Katie Heaney',
        authorLink: 'https://www.goodreads.com/author/show/6860774.Katie_Heaney',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1391409510l/17159009._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/17159009-never-have-i-ever',
        ratingsAvg: '3.53',
        ratingsNumber: '7718',
        rating: '1',
    },
    {
        title: 'Yes Please',
        author: 'Amy Poehler',
        authorLink: 'https://www.goodreads.com/author/show/7350402.Amy_Poehler',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1402815435l/20910157._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20910157-yes-please',
        ratingsAvg: '3.83',
        ratingsNumber: '429671',
        rating: '3',
    },
    {
        title: 'Away Laughing on a Fast Camel',
        author: 'Louise Rennison',
        authorLink: 'https://www.goodreads.com/author/show/65189.Louise_Rennison',
        year: '2004',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/347577.Away_Laughing_on_a_Fast_Camel',
        ratingsAvg: '4.06',
        ratingsNumber: '18580',
        rating: '4',
    },
    {
        title: 'Then He Ate My Boy Entrancers',
        author: 'Louise Rennison',
        authorLink: 'https://www.goodreads.com/author/show/65189.Louise_Rennison',
        year: '2005',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/112693.Then_He_Ate_My_Boy_Entrancers',
        ratingsAvg: '4.09',
        ratingsNumber: '17721',
        rating: '4',
    },
    {
        title: 'Dancing in My Nuddy-Pants',
        author: 'Louise Rennison',
        authorLink: 'https://www.goodreads.com/author/show/65189.Louise_Rennison',
        year: '2003',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327885836l/402015._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/402015.Dancing_in_My_Nuddy_Pants',
        ratingsAvg: '4.01',
        ratingsNumber: '22413',
        rating: '4',
    },
    {
        title: "On the Bright Side, I'm Now the Girlfriend of a Sex God",
        author: 'Louise Rennison',
        authorLink: 'https://www.goodreads.com/author/show/65189.Louise_Rennison',
        year: '2001',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/402014.On_the_Bright_Side_I_m_Now_the_Girlfriend_of_a_Sex_God',
        ratingsAvg: '3.96',
        ratingsNumber: '46306',
        rating: '4',
    },
    {
        title: 'Knocked Out by My Nunga-Nungas',
        author: 'Louise Rennison',
        authorLink: 'https://www.goodreads.com/author/show/65189.Louise_Rennison',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/112675.Knocked_Out_by_My_Nunga_Nungas',
        ratingsAvg: '3.97',
        ratingsNumber: '28662',
        rating: '4',
    },
    {
        title: 'The Sky Is Everywhere',
        author: 'Jandy Nelson',
        authorLink: 'https://www.goodreads.com/author/show/2982266.Jandy_Nelson',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1496659743l/6604794._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6604794-the-sky-is-everywhere',
        ratingsAvg: '3.99',
        ratingsNumber: '90814',
        rating: '4',
    },
    {
        title: 'The Hunger Games',
        author: 'Suzanne Collins',
        authorLink: 'https://www.goodreads.com/author/show/153394.Suzanne_Collins',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1586722975l/2767052._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/2767052-the-hunger-games',
        ratingsAvg: '4.33',
        ratingsNumber: '6372896',
        rating: '3',
    },
    {
        title: "Amanda's Wedding",
        author: 'Jenny Colgan',
        authorLink: 'https://www.goodreads.com/author/show/39272.Jenny_Colgan',
        year: '2002',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1344267316l/74482._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/74482.Amanda_s_Wedding',
        ratingsAvg: '3.32',
        ratingsNumber: '4178',
        rating: '2',
    },
    {
        title: 'The Loveliest Chocolate Shop in Paris',
        author: 'Jenny Colgan',
        authorLink: 'https://www.goodreads.com/author/show/39272.Jenny_Colgan',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1349945779l/16079967._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/16079967-the-loveliest-chocolate-shop-in-paris',
        ratingsAvg: '3.82',
        ratingsNumber: '10415',
        rating: '2',
    },
    {
        title: 'Little Beach Street Bakery',
        author: 'Jenny Colgan',
        authorLink: 'https://www.goodreads.com/author/show/39272.Jenny_Colgan',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1392740344l/18213783._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18213783-little-beach-street-bakery',
        ratingsAvg: '3.87',
        ratingsNumber: '25568',
        rating: '2',
    },
    {
        title: 'Christmas at the Cupcake Caf\u00e9',
        author: 'Jenny Colgan',
        authorLink: 'https://www.goodreads.com/author/show/39272.Jenny_Colgan',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1334077046l/13596423._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13596423-christmas-at-the-cupcake-caf',
        ratingsAvg: '3.85',
        ratingsNumber: '5953',
        rating: '2',
    },
    {
        title: 'Meet Me at the Cupcake Caf\u00e9',
        author: 'Jenny Colgan',
        authorLink: 'https://www.goodreads.com/author/show/39272.Jenny_Colgan',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1295394430l/10134852._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10134852-meet-me-at-the-cupcake-caf',
        ratingsAvg: '3.85',
        ratingsNumber: '15528',
        rating: '1',
    },
    {
        title: 'Christmas at Rosie Hopkins\u2019 Sweetshop',
        author: 'Jenny Colgan',
        authorLink: 'https://www.goodreads.com/author/show/39272.Jenny_Colgan',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1373384100l/18172194._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18172194-christmas-at-rosie-hopkins-sweetshop',
        ratingsAvg: '3.98',
        ratingsNumber: '5217',
        rating: '2',
    },
    {
        title: "Welcome to Rosie Hopkins' Sweet Shop of Dreams",
        author: 'Jenny Colgan',
        authorLink: 'https://www.goodreads.com/author/show/39272.Jenny_Colgan',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1329911574l/13006132._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13006132-welcome-to-rosie-hopkins-sweet-shop-of-dreams',
        ratingsAvg: '3.95',
        ratingsNumber: '8265',
        rating: '3',
    },
    {
        title: 'The Fine Art of Pretending',
        author: 'Rachel  Harris',
        authorLink: 'https://www.goodreads.com/author/show/5347676.Rachel_Harris',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1390799787l/17696131._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/17696131-the-fine-art-of-pretending',
        ratingsAvg: '3.71',
        ratingsNumber: '6501',
        rating: '3',
    },
    {
        title: 'The Darkest Part of the Forest',
        author: 'Holly Black',
        authorLink: 'https://www.goodreads.com/author/show/25422.Holly_Black',
        year: '2015',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1397755014l/20958632._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20958632-the-darkest-part-of-the-forest',
        ratingsAvg: '3.87',
        ratingsNumber: '52323',
        rating: '2',
    },
    {
        title: 'Jackaby',
        author: 'William Ritter',
        authorLink: 'https://www.goodreads.com/author/show/8184486.William_Ritter',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1410170969l/20312462._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20312462-jackaby',
        ratingsAvg: '3.83',
        ratingsNumber: '24231',
        rating: '1',
    },
    {
        title: "I'll Give You the Sun",
        author: 'Jandy Nelson',
        authorLink: 'https://www.goodreads.com/author/show/2982266.Jandy_Nelson',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1451382614l/20820994._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20820994-i-ll-give-you-the-sun',
        ratingsAvg: '4.14',
        ratingsNumber: '254326',
        rating: '5',
    },
    {
        title: 'The Death Cure',
        author: 'James Dashner',
        authorLink: 'https://www.goodreads.com/author/show/348878.James_Dashner',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1303997647l/7864437._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7864437-the-death-cure',
        ratingsAvg: '3.77',
        ratingsNumber: '337348',
        rating: '1',
    },
    {
        title: 'The Scorch Trials',
        author: 'James Dashner',
        authorLink: 'https://www.goodreads.com/author/show/348878.James_Dashner',
        year: '2010',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/7631105-the-scorch-trials',
        ratingsAvg: '3.91',
        ratingsNumber: '445697',
        rating: '1',
    },
    {
        title: 'The Maze Runner',
        author: 'James Dashner',
        authorLink: 'https://www.goodreads.com/author/show/348878.James_Dashner',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1375596592l/6186357._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6186357-the-maze-runner',
        ratingsAvg: '4.03',
        ratingsNumber: '1055179',
        rating: '2',
    },
    {
        title: 'The Leftovers',
        author: 'Tom Perrotta',
        authorLink: 'https://www.goodreads.com/author/show/15907.Tom_Perrotta',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1404872433l/10762469._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10762469-the-leftovers',
        ratingsAvg: '3.41',
        ratingsNumber: '43258',
        rating: '1',
    },
    {
        title: "Breakfast with Tiffany: An Uncle's Memoir",
        author: 'Edwin John Wintle',
        authorLink: 'https://www.goodreads.com/author/show/6463.Edwin_John_Wintle',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/9892.Breakfast_with_Tiffany',
        ratingsAvg: '3.55',
        ratingsNumber: '541',
        rating: '4',
    },
    {
        title: 'A Fractured Mind: My Life with Multiple Personality Disorder',
        author: 'Robert B. Oxnam',
        authorLink: 'https://www.goodreads.com/author/show/227695.Robert_B_Oxnam',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/401625.A_Fractured_Mind',
        ratingsAvg: '3.80',
        ratingsNumber: '1281',
        rating: '2',
    },
    {
        title: 'River of Blue Fire',
        author: 'Tad Williams',
        authorLink: 'https://www.goodreads.com/author/show/6587.Tad_Williams',
        year: '1999',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1309992269l/10084._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/10084.River_of_Blue_Fire',
        ratingsAvg: '4.02',
        ratingsNumber: '14764',
        rating: '3',
    },
    {
        title: 'The Battle of the Labyrinth',
        author: 'Rick Riordan',
        authorLink: 'https://www.goodreads.com/author/show/15872.Rick_Riordan',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1518607478l/2120932._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/2120932.The_Battle_of_the_Labyrinth',
        ratingsAvg: '4.39',
        ratingsNumber: '740578',
        rating: '3',
    },
    {
        title: 'The Last Olympian',
        author: 'Rick Riordan',
        authorLink: 'https://www.goodreads.com/author/show/15872.Rick_Riordan',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327924597l/4502507._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/4502507-the-last-olympian',
        ratingsAvg: '4.51',
        ratingsNumber: '667418',
        rating: '3',
    },
    {
        title: "The Titan's Curse",
        author: 'Rick Riordan',
        authorLink: 'https://www.goodreads.com/author/show/15872.Rick_Riordan',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1361038385l/561456._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/561456.The_Titan_s_Curse',
        ratingsAvg: '4.35',
        ratingsNumber: '685648',
        rating: '3',
    },
    {
        title: 'The Sea of Monsters',
        author: 'Rick Riordan',
        authorLink: 'https://www.goodreads.com/author/show/15872.Rick_Riordan',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1400602661l/28186._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28186.The_Sea_of_Monsters',
        ratingsAvg: '4.24',
        ratingsNumber: '747968',
        rating: '3',
    },
    {
        title: 'The Lightning Thief',
        author: 'Rick Riordan',
        authorLink: 'https://www.goodreads.com/author/show/15872.Rick_Riordan',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1400602609l/28187._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28187.The_Lightning_Thief',
        ratingsAvg: '4.26',
        ratingsNumber: '1990695',
        rating: '3',
    },
    {
        title: 'The Last Letter from Your Lover',
        author: 'Jojo Moyes',
        authorLink: 'https://www.goodreads.com/author/show/281810.Jojo_Moyes',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1549061291l/10163292._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10163292-the-last-letter-from-your-lover',
        ratingsAvg: '3.97',
        ratingsNumber: '75180',
        rating: '1',
    },
    {
        title: 'The One',
        author: 'Kiera Cass',
        authorLink: 'https://www.goodreads.com/author/show/2987125.Kiera_Cass',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1380212021l/15844362._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/15844362-the-one',
        ratingsAvg: '4.20',
        ratingsNumber: '379893',
        rating: '1',
    },
    {
        title: 'The Elite',
        author: 'Kiera Cass',
        authorLink: 'https://www.goodreads.com/author/show/2987125.Kiera_Cass',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1391454595l/16248068._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/16248068-the-elite',
        ratingsAvg: '4.02',
        ratingsNumber: '425041',
        rating: '1',
    },
    {
        title: 'The Selection',
        author: 'Kiera Cass',
        authorLink: 'https://www.goodreads.com/author/show/2987125.Kiera_Cass',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1322103400l/10507293._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10507293-the-selection',
        ratingsAvg: '4.13',
        ratingsNumber: '891785',
        rating: '1',
    },
    {
        title: 'One Plus One',
        author: 'Jojo Moyes',
        authorLink: 'https://www.goodreads.com/author/show/281810.Jojo_Moyes',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1403185695l/18693716._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18693716-one-plus-one',
        ratingsAvg: '3.95',
        ratingsNumber: '147602',
        rating: '3',
    },
    {
        title: 'Angus, Thongs and Full-Frontal Snogging',
        author: 'Louise Rennison',
        authorLink: 'https://www.goodreads.com/author/show/65189.Louise_Rennison',
        year: '2000',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/402013.Angus_Thongs_and_Full_Frontal_Snogging',
        ratingsAvg: '3.75',
        ratingsNumber: '129182',
        rating: '5',
    },
    {
        title: 'The Hundred-Year-Old Man Who Climbed Out of the Window and Disappeared',
        author: 'Jonas Jonasson',
        authorLink: 'https://www.goodreads.com/author/show/3222554.Jonas_Jonasson',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1334848488l/13486632._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13486632-the-hundred-year-old-man-who-climbed-out-of-the-window-and-disappeared',
        ratingsAvg: '3.82',
        ratingsNumber: '213550',
        rating: '2',
    },
    {
        title: 'Me Before You',
        author: 'Jojo Moyes',
        authorLink: 'https://www.goodreads.com/author/show/281810.Jojo_Moyes',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1357108762l/15507958._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/15507958-me-before-you',
        ratingsAvg: '4.26',
        ratingsNumber: '1147855',
        rating: '3',
    },
    {
        title: 'The Unlikely Pilgrimage of Harold Fry',
        author: 'Rachel Joyce',
        authorLink: 'https://www.goodreads.com/author/show/5309857.Rachel_Joyce',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1335816092l/13227454._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13227454-the-unlikely-pilgrimage-of-harold-fry',
        ratingsAvg: '3.92',
        ratingsNumber: '148345',
        rating: '4',
    },
    {
        title: 'The Storied Life of A.J. Fikry',
        author: 'Gabrielle Zevin',
        authorLink: 'https://www.goodreads.com/author/show/40593.Gabrielle_Zevin',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1404582137l/18293427._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18293427-the-storied-life-of-a-j-fikry',
        ratingsAvg: '3.99',
        ratingsNumber: '201832',
        rating: '4',
    },
    {
        title: "Ronia, the Robber's Daughter",
        author: 'Astrid Lindgren',
        authorLink: 'https://www.goodreads.com/author/show/410653.Astrid_Lindgren',
        year: '1985',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1451632787l/19314._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/19314.Ronia_the_Robber_s_Daughter',
        ratingsAvg: '4.28',
        ratingsNumber: '24498',
        rating: '4',
    },
    {
        title: 'Mountain of Black Glass',
        author: 'Tad Williams',
        authorLink: 'https://www.goodreads.com/author/show/6587.Tad_Williams',
        year: '2000',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1309992323l/10090._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10090.Mountain_of_Black_Glass',
        ratingsAvg: '4.06',
        ratingsNumber: '14547',
        rating: '3',
    },
    {
        title: 'Sea of Silver Light',
        author: 'Tad Williams',
        authorLink: 'https://www.goodreads.com/author/show/6587.Tad_Williams',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1587631380l/10092._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10092.Sea_of_Silver_Light',
        ratingsAvg: '4.14',
        ratingsNumber: '12957',
        rating: '4',
    },
    {
        title: 'City of Golden Shadow',
        author: 'Tad Williams',
        authorLink: 'https://www.goodreads.com/author/show/6587.Tad_Williams',
        year: '1998',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1329075235l/28695._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/28695.City_of_Golden_Shadow',
        ratingsAvg: '3.92',
        ratingsNumber: '24579',
        rating: '4',
    },
    {
        title: 'New Moon',
        author: 'Stephenie Meyer',
        authorLink: 'https://www.goodreads.com/author/show/941441.Stephenie_Meyer',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1361039440l/49041._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/49041.New_Moon',
        ratingsAvg: '3.54',
        ratingsNumber: '1465284',
        rating: '1',
    },
    {
        title: 'Breaking Dawn',
        author: 'Stephenie Meyer',
        authorLink: 'https://www.goodreads.com/author/show/941441.Stephenie_Meyer',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1361039438l/1162543._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/1162543.Breaking_Dawn',
        ratingsAvg: '3.70',
        ratingsNumber: '1323183',
        rating: '2',
    },
    {
        title: 'Eclipse',
        author: 'Stephenie Meyer',
        authorLink: 'https://www.goodreads.com/author/show/941441.Stephenie_Meyer',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1361038355l/428263._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/428263.Eclipse',
        ratingsAvg: '3.70',
        ratingsNumber: '1423670',
        rating: '1',
    },
    {
        title: 'Sezon burz',
        author: 'Andrzej Sapkowski',
        authorLink: 'https://www.goodreads.com/author/show/38569.Andrzej_Sapkowski',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1382626245l/18714223._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18714223-sezon-burz',
        ratingsAvg: '3.96',
        ratingsNumber: '22558',
        rating: '3',
    },
    {
        title: 'Pani Jeziora',
        author: 'Andrzej Sapkowski',
        authorLink: 'https://www.goodreads.com/author/show/38569.Andrzej_Sapkowski',
        year: '2001',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/68452.Pani_Jeziora',
        ratingsAvg: '4.18',
        ratingsNumber: '39534',
        rating: '2',
    },
    {
        title: 'Wie\u017ca Jask\u00f3\u0142ki',
        author: 'Andrzej Sapkowski',
        authorLink: 'https://www.goodreads.com/author/show/38569.Andrzej_Sapkowski',
        year: '2001',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/68455.Wie_a_Jask_ki',
        ratingsAvg: '4.27',
        ratingsNumber: '46525',
        rating: '2',
    },
    {
        title: 'Chrzest ognia',
        author: 'Andrzej Sapkowski',
        authorLink: 'https://www.goodreads.com/author/show/38569.Andrzej_Sapkowski',
        year: '1996',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1876770.Chrzest_ognia',
        ratingsAvg: '4.30',
        ratingsNumber: '55430',
        rating: '3',
    },
    {
        title: 'Czas pogardy',
        author: 'Andrzej Sapkowski',
        authorLink: 'https://www.goodreads.com/author/show/38569.Andrzej_Sapkowski',
        year: '1995',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/68453.Czas_pogardy',
        ratingsAvg: '4.20',
        ratingsNumber: '68387',
        rating: '3',
    },
    {
        title: 'Krew elf\u00f3w',
        author: 'Andrzej Sapkowski',
        authorLink: 'https://www.goodreads.com/author/show/38569.Andrzej_Sapkowski',
        year: '2007',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/68458.Krew_elf_w',
        ratingsAvg: '4.11',
        ratingsNumber: '105561',
        rating: '3',
    },
    {
        title: 'Miecz przeznaczenia',
        author: 'Andrzej Sapkowski',
        authorLink: 'https://www.goodreads.com/author/show/38569.Andrzej_Sapkowski',
        year: '1994',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1128437.Miecz_przeznaczenia',
        ratingsAvg: '4.29',
        ratingsNumber: '92288',
        rating: '3',
    },
    {
        title: 'Sleep',
        author: 'Haruki Murakami',
        authorLink: 'https://www.goodreads.com/author/show/3354.Haruki_Murakami',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1436622208l/6501635._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6501635-sleep',
        ratingsAvg: '3.53',
        ratingsNumber: '11643',
        rating: '2',
    },
    {
        title: 'Before I Go to Sleep',
        author: 'S.J. Watson',
        authorLink: 'https://www.goodreads.com/author/show/4470653.S_J_Watson',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1358353512l/9736930._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/9736930-before-i-go-to-sleep',
        ratingsAvg: '3.89',
        ratingsNumber: '289462',
        rating: '3',
    },
    {
        title: 'Last Sacrifice',
        author: 'Richelle Mead',
        authorLink: 'https://www.goodreads.com/author/show/137902.Richelle_Mead',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1319850978l/6527740._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6527740-last-sacrifice',
        ratingsAvg: '4.39',
        ratingsNumber: '254756',
        rating: '2',
    },
    {
        title: 'Spirit Bound',
        author: 'Richelle Mead',
        authorLink: 'https://www.goodreads.com/author/show/137902.Richelle_Mead',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1291168967l/6479259._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6479259-spirit-bound',
        ratingsAvg: '4.35',
        ratingsNumber: '259933',
        rating: '2',
    },
    {
        title: 'Frostbite',
        author: 'Richelle Mead',
        authorLink: 'https://www.goodreads.com/author/show/137902.Richelle_Mead',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1361098960l/2282133._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/2282133.Frostbite',
        ratingsAvg: '4.27',
        ratingsNumber: '309152',
        rating: '3',
    },
    {
        title: 'Blood Promise',
        author: 'Richelle Mead',
        authorLink: 'https://www.goodreads.com/author/show/137902.Richelle_Mead',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1442530605l/5996153._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/5996153-blood-promise',
        ratingsAvg: '4.32',
        ratingsNumber: '275924',
        rating: '2',
    },
    {
        title: 'Shadow Kiss',
        author: 'Richelle Mead',
        authorLink: 'https://www.goodreads.com/author/show/137902.Richelle_Mead',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1361099049l/2802316._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/2802316-shadow-kiss',
        ratingsAvg: '4.35',
        ratingsNumber: '325103',
        rating: '2',
    },
    {
        title: 'Vampire Academy',
        author: 'Richelle Mead',
        authorLink: 'https://www.goodreads.com/author/show/137902.Richelle_Mead',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1361098973l/345627._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/345627.Vampire_Academy',
        ratingsAvg: '4.12',
        ratingsNumber: '548602',
        rating: '3',
    },
    {
        title: 'Fearless',
        author: 'Cornelia Funke',
        authorLink: 'https://www.goodreads.com/author/show/15873.Cornelia_Funke',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1346927033l/9477896._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/9477896-fearless',
        ratingsAvg: '4.19',
        ratingsNumber: '5697',
        rating: '2',
    },
    {
        title: 'Reckless',
        author: 'Cornelia Funke',
        authorLink: 'https://www.goodreads.com/author/show/15873.Cornelia_Funke',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1274650443l/7823592._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7823592-reckless',
        ratingsAvg: '3.73',
        ratingsNumber: '17036',
        rating: '2',
    },
    {
        title: 'Inkdeath',
        author: 'Cornelia Funke',
        authorLink: 'https://www.goodreads.com/author/show/15873.Cornelia_Funke',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327341991l/2325825._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/2325825.Inkdeath',
        ratingsAvg: '3.93',
        ratingsNumber: '75017',
        rating: '2',
    },
    {
        title: 'Inkspell',
        author: 'Cornelia Funke',
        authorLink: 'https://www.goodreads.com/author/show/15873.Cornelia_Funke',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1395358683l/28195._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28195.Inkspell',
        ratingsAvg: '3.91',
        ratingsNumber: '109451',
        rating: '2',
    },
    {
        title: 'The Subtle Knife',
        author: 'Philip Pullman',
        authorLink: 'https://www.goodreads.com/author/show/3618.Philip_Pullman',
        year: '1997',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1505766360l/119324._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/119324.The_Subtle_Knife',
        ratingsAvg: '4.13',
        ratingsNumber: '365804',
        rating: '5',
    },
    {
        title: 'Set This House in Order',
        author: 'Matt Ruff',
        authorLink: 'https://www.goodreads.com/author/show/40577.Matt_Ruff',
        year: '2004',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/71847.Set_This_House_in_Order',
        ratingsAvg: '4.29',
        ratingsNumber: '4952',
        rating: '5',
    },
    {
        title: 'Fool on the Hill',
        author: 'Matt Ruff',
        authorLink: 'https://www.goodreads.com/author/show/40577.Matt_Ruff',
        year: '1997',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/71840.Fool_on_the_Hill',
        ratingsAvg: '4.10',
        ratingsNumber: '4189',
        rating: '4',
    },
    {
        title: 'Bad Monkeys',
        author: 'Matt Ruff',
        authorLink: 'https://www.goodreads.com/author/show/40577.Matt_Ruff',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1300479869l/71845._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/71845.Bad_Monkeys',
        ratingsAvg: '3.61',
        ratingsNumber: '9059',
        rating: '4',
    },
    {
        title: 'About a Boy',
        author: 'Nick Hornby',
        authorLink: 'https://www.goodreads.com/author/show/2929.Nick_Hornby',
        year: '2000',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1382004144l/4271._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/4271.About_a_Boy',
        ratingsAvg: '3.79',
        ratingsNumber: '125685',
        rating: '3',
    },
    {
        title: 'A Long Way Down',
        author: 'Nick Hornby',
        authorLink: 'https://www.goodreads.com/author/show/2929.Nick_Hornby',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1467861525l/10073._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10073.A_Long_Way_Down',
        ratingsAvg: '3.43',
        ratingsNumber: '80001',
        rating: '4',
    },
    {
        title: 'High Fidelity',
        author: 'Nick Hornby',
        authorLink: 'https://www.goodreads.com/author/show/2929.Nick_Hornby',
        year: '1996',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327928082l/285092._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/285092.High_Fidelity',
        ratingsAvg: '3.93',
        ratingsNumber: '173494',
        rating: '2',
    },
    {
        title: 'Inheritance',
        author: 'Christopher Paolini',
        authorLink: 'https://www.goodreads.com/author/show/8349.Christopher_Paolini',
        year: '2011',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/7664041-inheritance',
        ratingsAvg: '4.10',
        ratingsNumber: '213659',
        rating: '1',
    },
    {
        title: 'Brisingr',
        author: 'Christopher Paolini',
        authorLink: 'https://www.goodreads.com/author/show/8349.Christopher_Paolini',
        year: '2008',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/2248573.Brisingr',
        ratingsAvg: '4.05',
        ratingsNumber: '298817',
        rating: '2',
    },
    {
        title: 'Eldest',
        author: 'Christopher Paolini',
        authorLink: 'https://www.goodreads.com/author/show/8349.Christopher_Paolini',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1387119654l/45978._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/45978.Eldest',
        ratingsAvg: '3.99',
        ratingsNumber: '354748',
        rating: '3',
    },
    {
        title: 'Hidden',
        author: 'P.C. Cast',
        authorLink: 'https://www.goodreads.com/author/show/17015.P_C_Cast',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1340127630l/7933615._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7933615-hidden',
        ratingsAvg: '3.99',
        ratingsNumber: '49848',
        rating: '2',
    },
    {
        title: 'Destined',
        author: 'P.C. Cast',
        authorLink: 'https://www.goodreads.com/author/show/17015.P_C_Cast',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1406962311l/7933617._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7933617-destined',
        ratingsAvg: '4.04',
        ratingsNumber: '73584',
        rating: '2',
    },
    {
        title: 'Awakened',
        author: 'P.C. Cast',
        authorLink: 'https://www.goodreads.com/author/show/17015.P_C_Cast',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1307744664l/8652190._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/8652190-awakened',
        ratingsAvg: '4.01',
        ratingsNumber: '122888',
        rating: '2',
    },
    {
        title: 'Burned',
        author: 'P.C. Cast',
        authorLink: 'https://www.goodreads.com/author/show/17015.P_C_Cast',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327944437l/6917952._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6917952-burned',
        ratingsAvg: '3.97',
        ratingsNumber: '141818',
        rating: '2',
    },
    {
        title: 'Tempted',
        author: 'P.C. Cast',
        authorLink: 'https://www.goodreads.com/author/show/17015.P_C_Cast',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1408633041l/6262365._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6262365-tempted',
        ratingsAvg: '3.95',
        ratingsNumber: '152044',
        rating: '2',
    },
    {
        title: 'Hunted',
        author: 'P.C. Cast',
        authorLink: 'https://www.goodreads.com/author/show/17015.P_C_Cast',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1438042897l/4134071._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/4134071-hunted',
        ratingsAvg: '3.93',
        ratingsNumber: '162431',
        rating: '2',
    },
    {
        title: 'Chosen',
        author: 'P.C. Cast',
        authorLink: 'https://www.goodreads.com/author/show/17015.P_C_Cast',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1393192825l/1326258._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/1326258.Chosen',
        ratingsAvg: '3.91',
        ratingsNumber: '221968',
        rating: '2',
    },
    {
        title: 'Untamed',
        author: 'P.C. Cast',
        authorLink: 'https://www.goodreads.com/author/show/17015.P_C_Cast',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1438037020l/2866718._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/2866718-untamed',
        ratingsAvg: '3.95',
        ratingsNumber: '213482',
        rating: '2',
    },
    {
        title: 'Betrayed',
        author: 'P.C. Cast',
        authorLink: 'https://www.goodreads.com/author/show/17015.P_C_Cast',
        year: '2007',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/676924.Betrayed',
        ratingsAvg: '3.93',
        ratingsNumber: '239945',
        rating: '2',
    },
    {
        title: 'Marked',
        author: 'P.C. Cast',
        authorLink: 'https://www.goodreads.com/author/show/17015.P_C_Cast',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1317067002l/30183._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/30183.Marked',
        ratingsAvg: '3.80',
        ratingsNumber: '429824',
        rating: '3',
    },
    {
        title: 'The Awakening',
        author: 'L.J. Smith',
        authorLink: 'https://www.goodreads.com/author/show/50873.L_J_Smith',
        year: '1999',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/395922.The_Awakening',
        ratingsAvg: '3.40',
        ratingsNumber: '63997',
        rating: '2',
    },
    {
        title: "Po's Story",
        author: 'Peter Dickinson',
        authorLink: 'https://www.goodreads.com/author/show/5345.Peter_Dickinson',
        year: '1999',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/289578.Po_s_Story',
        ratingsAvg: '3.70',
        ratingsNumber: '53',
        rating: '3',
    },
    {
        title: 'The Kin',
        author: 'Peter Dickinson',
        authorLink: 'https://www.goodreads.com/author/show/5345.Peter_Dickinson',
        year: '2003',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1309203726l/289577._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/289577.The_Kin',
        ratingsAvg: '3.97',
        ratingsNumber: '762',
        rating: '2',
    },
    {
        title: "Suth's Story",
        author: 'Peter Dickinson',
        authorLink: 'https://www.goodreads.com/author/show/5345.Peter_Dickinson',
        year: '1998',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1208666.Suth_s_Story',
        ratingsAvg: '3.87',
        ratingsNumber: '153',
        rating: '3',
    },
    {
        title: "Noli's Story",
        author: 'Peter Dickinson',
        authorLink: 'https://www.goodreads.com/author/show/5345.Peter_Dickinson',
        year: '1998',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1208669.Noli_s_Story',
        ratingsAvg: '3.64',
        ratingsNumber: '91',
        rating: '3',
    },
    {
        title: 'The Plains of Passage',
        author: 'Jean M. Auel',
        authorLink: 'https://www.goodreads.com/author/show/861.Jean_M_Auel',
        year: '2002',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1324059606l/74389._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/74389.The_Plains_of_Passage',
        ratingsAvg: '3.80',
        ratingsNumber: '50345',
        rating: '2',
    },
    {
        title: 'The Mammoth Hunters',
        author: 'Jean M. Auel',
        authorLink: 'https://www.goodreads.com/author/show/861.Jean_M_Auel',
        year: '2002',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/49824.The_Mammoth_Hunters',
        ratingsAvg: '3.91',
        ratingsNumber: '61829',
        rating: '2',
    },
    {
        title: 'The Valley of Horses',
        author: 'Jean M. Auel',
        authorLink: 'https://www.goodreads.com/author/show/861.Jean_M_Auel',
        year: '2002',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1324059760l/40493._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/40493.The_Valley_of_Horses',
        ratingsAvg: '3.99',
        ratingsNumber: '77251',
        rating: '2',
    },
    {
        title: 'The Clan of the Cave Bear',
        author: 'Jean M. Auel',
        authorLink: 'https://www.goodreads.com/author/show/861.Jean_M_Auel',
        year: '2002',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1385331302l/1295._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/1295.The_Clan_of_the_Cave_Bear',
        ratingsAvg: '4.05',
        ratingsNumber: '228977',
        rating: '2',
    },
    {
        title: 'The Struggle',
        author: 'L.J. Smith',
        authorLink: 'https://www.goodreads.com/author/show/50873.L_J_Smith',
        year: '1999',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/395851.The_Struggle',
        ratingsAvg: '3.48',
        ratingsNumber: '34765',
        rating: '2',
    },
    {
        title: 'Bridget Jones: The Edge of Reason',
        author: 'Helen Fielding',
        authorLink: 'https://www.goodreads.com/author/show/3090.Helen_Fielding',
        year: '2001',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1395233618l/363752._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/363752.Bridget_Jones',
        ratingsAvg: '3.59',
        ratingsNumber: '100349',
        rating: '3',
    },
    {
        title: "Bridget Jones's Diary",
        author: 'Helen Fielding',
        authorLink: 'https://www.goodreads.com/author/show/3090.Helen_Fielding',
        year: '1999',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1558869586l/227443._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/227443.Bridget_Jones_s_Diary',
        ratingsAvg: '3.78',
        ratingsNumber: '881207',
        rating: '4',
    },
    {
        title: 'Lone Wolf',
        author: 'Jodi Picoult',
        authorLink: 'https://www.goodreads.com/author/show/7128.Jodi_Picoult',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1347278865l/13547188._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13547188-lone-wolf',
        ratingsAvg: '3.70',
        ratingsNumber: '70307',
        rating: '2',
    },
    {
        title: "Angela's Ashes",
        author: 'Frank McCourt',
        authorLink: 'https://www.goodreads.com/author/show/3347.Frank_McCourt',
        year: '2005',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/252577.Angela_s_Ashes',
        ratingsAvg: '4.11',
        ratingsNumber: '539276',
        rating: '3',
    },
    {
        title: 'The Donor',
        author: 'Helen  Fitzgerald',
        authorLink: 'https://www.goodreads.com/author/show/4341584.Helen_Fitzgerald',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1329120908l/11835876._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/11835876-the-donor',
        ratingsAvg: '3.44',
        ratingsNumber: '831',
        rating: '2',
    },
    {
        title: 'Fifty Shades Freed',
        author: 'E.L. James',
        authorLink: 'https://www.goodreads.com/author/show/4725841.E_L_James',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1336418837l/13536860._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13536860-fifty-shades-freed',
        ratingsAvg: '3.85',
        ratingsNumber: '713423',
        rating: '1',
    },
    {
        title: 'Fifty Shades Darker',
        author: 'E.L. James',
        authorLink: 'https://www.goodreads.com/author/show/4725841.E_L_James',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1358266080l/11857408._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/11857408-fifty-shades-darker',
        ratingsAvg: '3.84',
        ratingsNumber: '744669',
        rating: '1',
    },
    {
        title: 'Fifty Shades of Grey',
        author: 'E.L. James',
        authorLink: 'https://www.goodreads.com/author/show/4725841.E_L_James',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1385207843l/10818853._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10818853-fifty-shades-of-grey',
        ratingsAvg: '3.66',
        ratingsNumber: '1944997',
        rating: '1',
    },
    {
        title: 'Mansfield Park',
        author: 'Jane Austen',
        authorLink: 'https://www.goodreads.com/author/show/1265.Jane_Austen',
        year: '2003',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1397063295l/45032._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/45032.Mansfield_Park',
        ratingsAvg: '3.86',
        ratingsNumber: '282912',
        rating: '2',
    },
    {
        title: 'Sense and Sensibility',
        author: 'Jane Austen',
        authorLink: 'https://www.goodreads.com/author/show/1265.Jane_Austen',
        year: '2003',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1397245675l/14935._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/14935.Sense_and_Sensibility',
        ratingsAvg: '4.07',
        ratingsNumber: '957057',
        rating: '3',
    },
    {
        title: 'Frelseren',
        author: 'Jo Nesb\u00f8',
        authorLink: 'https://www.goodreads.com/author/show/904719.Jo_Nesb_',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1418343566l/2033352._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/2033352.Frelseren',
        ratingsAvg: '4.08',
        ratingsNumber: '34091',
        rating: '3',
    },
    {
        title: 'Phantom',
        author: 'Jo Nesb\u00f8',
        authorLink: 'https://www.goodreads.com/author/show/904719.Jo_Nesb_',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1333577303l/13256064._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13256064-phantom',
        ratingsAvg: '4.11',
        ratingsNumber: '37928',
        rating: '3',
    },
    {
        title: 'Flaggermusmannen',
        author: 'Jo Nesb\u00f8',
        authorLink: 'https://www.goodreads.com/author/show/904719.Jo_Nesb_',
        year: '2005',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1991039.Flaggermusmannen',
        ratingsAvg: '3.56',
        ratingsNumber: '85927',
        rating: '3',
    },
    {
        title: 'Panserhjerte',
        author: 'Jo Nesb\u00f8',
        authorLink: 'https://www.goodreads.com/author/show/904719.Jo_Nesb_',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1408635770l/6891433._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6891433-panserhjerte',
        ratingsAvg: '4.08',
        ratingsNumber: '47877',
        rating: '3',
    },
    {
        title: 'Nemesis',
        author: 'Jo Nesb\u00f8',
        authorLink: 'https://www.goodreads.com/author/show/904719.Jo_Nesb_',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327312660l/3522419._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/3522419-nemesis',
        ratingsAvg: '3.98',
        ratingsNumber: '62345',
        rating: '3',
    },
    {
        title: "The Devil's Star",
        author: 'Jo Nesb\u00f8',
        authorLink: 'https://www.goodreads.com/author/show/904719.Jo_Nesb_',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1328611163l/498389._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/498389.The_Devil_s_Star',
        ratingsAvg: '4.05',
        ratingsNumber: '58484',
        rating: '3',
    },
    {
        title: 'The Redbreast',
        author: 'Jo Nesb\u00f8',
        authorLink: 'https://www.goodreads.com/author/show/904719.Jo_Nesb_',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1320540474l/465226._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/465226.The_Redbreast',
        ratingsAvg: '3.93',
        ratingsNumber: '87512',
        rating: '3',
    },
    {
        title: 'The Snowman',
        author: 'Jo Nesb\u00f8',
        authorLink: 'https://www.goodreads.com/author/show/904719.Jo_Nesb_',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1355881478l/9572203._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/9572203-the-snowman',
        ratingsAvg: '4.01',
        ratingsNumber: '109770',
        rating: '3',
    },
    {
        title: 'Happy Family',
        author: 'David Safier',
        authorLink: 'https://www.goodreads.com/author/show/790341.David_Safier',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327765500l/11503106._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/11503106-happy-family',
        ratingsAvg: '3.25',
        ratingsNumber: '1852',
        rating: '1',
    },
    {
        title: 'Jesus liebt mich',
        author: 'David Safier',
        authorLink: 'https://www.goodreads.com/author/show/790341.David_Safier',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1328899217l/5956378._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/5956378-jesus-liebt-mich',
        ratingsAvg: '3.54',
        ratingsNumber: '3548',
        rating: '1',
    },
    {
        title: 'Mieses Karma',
        author: 'David Safier',
        authorLink: 'https://www.goodreads.com/author/show/790341.David_Safier',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1396737244l/3287351._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/3287351-mieses-karma',
        ratingsAvg: '3.61',
        ratingsNumber: '13341',
        rating: '2',
    },
    {
        title: "Lamb: The Gospel According to Biff, Christ's Childhood Pal",
        author: 'Christopher Moore',
        authorLink: 'https://www.goodreads.com/author/show/16218.Christopher_Moore',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1331419009l/28881._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28881.Lamb',
        ratingsAvg: '4.24',
        ratingsNumber: '151031',
        rating: '4',
    },
    {
        title: 'A Dance with Dragons',
        author: 'George R.R. Martin',
        authorLink: 'https://www.goodreads.com/author/show/346732.George_R_R_Martin',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1581625286l/10664113._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10664113-a-dance-with-dragons',
        ratingsAvg: '4.32',
        ratingsNumber: '555568',
        rating: '2',
    },
    {
        title: 'A Feast for Crows',
        author: 'George R.R. Martin',
        authorLink: 'https://www.goodreads.com/author/show/346732.George_R_R_Martin',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1429538615l/13497._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/13497.A_Feast_for_Crows',
        ratingsAvg: '4.14',
        ratingsNumber: '620574',
        rating: '2',
    },
    {
        title: 'A Storm of Swords',
        author: 'George R.R. Martin',
        authorLink: 'https://www.goodreads.com/author/show/346732.George_R_R_Martin',
        year: '2003',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1571318786l/62291._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/62291.A_Storm_of_Swords',
        ratingsAvg: '4.53',
        ratingsNumber: '665789',
        rating: '4',
    },
    {
        title: 'The Pinhoe Egg',
        author: 'Diana Wynne Jones',
        authorLink: 'https://www.goodreads.com/author/show/4260.Diana_Wynne_Jones',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1364260172l/34281._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/34281.The_Pinhoe_Egg',
        ratingsAvg: '4.07',
        ratingsNumber: '8031',
        rating: '5',
    },
    {
        title: "Conrad's Fate",
        author: 'Diana Wynne Jones',
        authorLink: 'https://www.goodreads.com/author/show/4260.Diana_Wynne_Jones',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/13759.Conrad_s_Fate',
        ratingsAvg: '4.06',
        ratingsNumber: '9410',
        rating: '3',
    },
    {
        title: 'Witch Week',
        author: 'Diana Wynne Jones',
        authorLink: 'https://www.goodreads.com/author/show/4260.Diana_Wynne_Jones',
        year: '2001',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1356548970l/47572._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/47572.Witch_Week',
        ratingsAvg: '3.92',
        ratingsNumber: '12730',
        rating: '4',
    },
    {
        title: 'The Magicians of Caprona',
        author: 'Diana Wynne Jones',
        authorLink: 'https://www.goodreads.com/author/show/4260.Diana_Wynne_Jones',
        year: '2001',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1356548904l/47525._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/47525.The_Magicians_of_Caprona',
        ratingsAvg: '3.93',
        ratingsNumber: '11205',
        rating: '4',
    },
    {
        title: 'Charmed Life',
        author: 'Diana Wynne Jones',
        authorLink: 'https://www.goodreads.com/author/show/4260.Diana_Wynne_Jones',
        year: '2000',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1396706429l/244572._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/244572.Charmed_Life',
        ratingsAvg: '3.96',
        ratingsNumber: '30125',
        rating: '4',
    },
    {
        title: 'Castle in the Air',
        author: 'Diana Wynne Jones',
        authorLink: 'https://www.goodreads.com/author/show/4260.Diana_Wynne_Jones',
        year: '2001',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/47520.Castle_in_the_Air',
        ratingsAvg: '3.89',
        ratingsNumber: '40181',
        rating: '4',
    },
    {
        title: 'The Lives of Christopher Chant',
        author: 'Diana Wynne Jones',
        authorLink: 'https://www.goodreads.com/author/show/4260.Diana_Wynne_Jones',
        year: '1998',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1356549015l/519933._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/519933.The_Lives_of_Christopher_Chant',
        ratingsAvg: '4.24',
        ratingsNumber: '20847',
        rating: '4',
    },
    {
        title: 'The Tales of Beedle the Bard',
        author: 'J.K. Rowling',
        authorLink: 'https://www.goodreads.com/author/show/1077326.J_K_Rowling',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1373467575l/3950967._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/3950967-the-tales-of-beedle-the-bard',
        ratingsAvg: '4.06',
        ratingsNumber: '406271',
        rating: '3',
    },
    {
        title: 'Harry Potter and the Order of the Phoenix',
        author: 'J.K. Rowling',
        authorLink: 'https://www.goodreads.com/author/show/1077326.J_K_Rowling',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1546910265l/2._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/2.Harry_Potter_and_the_Order_of_the_Phoenix',
        ratingsAvg: '4.50',
        ratingsNumber: '2505930',
        rating: '5',
    },
    {
        title: 'Harry Potter and the Half-Blood Prince',
        author: 'J.K. Rowling',
        authorLink: 'https://www.goodreads.com/author/show/1077326.J_K_Rowling',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1587697303l/1._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/1.Harry_Potter_and_the_Half_Blood_Prince',
        ratingsAvg: '4.57',
        ratingsNumber: '2436043',
        rating: '5',
    },
    {
        title: 'Harry Potter and the Goblet of Fire',
        author: 'J.K. Rowling',
        authorLink: 'https://www.goodreads.com/author/show/1077326.J_K_Rowling',
        year: '2002',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1554006152l/6._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6.Harry_Potter_and_the_Goblet_of_Fire',
        ratingsAvg: '4.56',
        ratingsNumber: '2592855',
        rating: '5',
    },
    {
        title: 'The Amber Spyglass',
        author: 'Philip Pullman',
        authorLink: 'https://www.goodreads.com/author/show/3618.Philip_Pullman',
        year: '2003',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1329189152l/18122._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/18122.The_Amber_Spyglass',
        ratingsAvg: '4.09',
        ratingsNumber: '297994',
        rating: '5',
    },
    {
        title: 'Harry Potter and the Deathly Hallows',
        author: 'J.K. Rowling',
        authorLink: 'https://www.goodreads.com/author/show/1077326.J_K_Rowling',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1474171184l/136251._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/136251.Harry_Potter_and_the_Deathly_Hallows',
        ratingsAvg: '4.62',
        ratingsNumber: '2809243',
        rating: '4',
    },
    {
        title: 'The Road Virus Heads North',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1300868817l/10848249._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10848249-the-road-virus-heads-north',
        ratingsAvg: '3.75',
        ratingsNumber: '987',
        rating: '4',
    },
    {
        title: 'Forever Princess',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2009',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/3474186-forever-princess',
        ratingsAvg: '4.10',
        ratingsNumber: '33924',
        rating: '3',
    },
    {
        title: 'Princess Mia',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2008',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1301153.Princess_Mia',
        ratingsAvg: '3.84',
        ratingsNumber: '22880',
        rating: '4',
    },
    {
        title: 'Princess on the Brink',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2007',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/85989.Princess_on_the_Brink',
        ratingsAvg: '3.71',
        ratingsNumber: '22524',
        rating: '3',
    },
    {
        title: 'Party Princess',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/85993.Party_Princess',
        ratingsAvg: '3.69',
        ratingsNumber: '24443',
        rating: '4',
    },
    {
        title: 'Princess in Training',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/93726.Princess_in_Training',
        ratingsAvg: '3.71',
        ratingsNumber: '29276',
        rating: '3',
    },
    {
        title: 'Princess in Pink',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2005',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/93731.Princess_in_Pink',
        ratingsAvg: '3.70',
        ratingsNumber: '33805',
        rating: '3',
    },
    {
        title: 'Princess in Waiting',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2003',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1493531966l/93729._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/93729.Princess_in_Waiting',
        ratingsAvg: '3.67',
        ratingsNumber: '41858',
        rating: '4',
    },
    {
        title: 'Princess in Love',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1271682172l/93728._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/93728.Princess_in_Love',
        ratingsAvg: '3.77',
        ratingsNumber: '50622',
        rating: '3',
    },
    {
        title: 'Princess in the Spotlight',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1389100014l/439275._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/439275.Princess_in_the_Spotlight',
        ratingsAvg: '3.68',
        ratingsNumber: '54002',
        rating: '3',
    },
    {
        title: 'I Will Always Love You',
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1344266711l/6435627._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6435627-i-will-always-love-you',
        ratingsAvg: '3.78',
        ratingsNumber: '5601',
        rating: '2',
    },
    {
        title: "Don't You Forget About Me",
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2007',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/37450.Don_t_You_Forget_About_Me',
        ratingsAvg: '3.65',
        ratingsNumber: '7628',
        rating: '2',
    },
    {
        title: 'Would I Lie to You',
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1330579607l/22186._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22186.Would_I_Lie_to_You',
        ratingsAvg: '3.59',
        ratingsNumber: '8332',
        rating: '2',
    },
    {
        title: 'Only in Your Dreams',
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1266627061l/726458._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/726458.Only_in_Your_Dreams',
        ratingsAvg: '3.67',
        ratingsNumber: '9873',
        rating: '2',
    },
    {
        title: 'Gossip Girl Boxed Set',
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2003',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/22187.Gossip_Girl_Boxed_Set',
        ratingsAvg: '3.92',
        ratingsNumber: '461',
        rating: '2',
    },
    {
        title: 'Nothing Can Keep Us Together',
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2005',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/22189.Nothing_Can_Keep_Us_Together',
        ratingsAvg: '3.64',
        ratingsNumber: '10169',
        rating: '2',
    },
    {
        title: 'It Had to Be You',
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2007',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/561402.It_Had_to_Be_You',
        ratingsAvg: '3.74',
        ratingsNumber: '11260',
        rating: '2',
    },
    {
        title: 'Nobody Does it Better',
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2005',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/22191.Nobody_Does_it_Better',
        ratingsAvg: '3.62',
        ratingsNumber: '11370',
        rating: '2',
    },
    {
        title: 'I Like It Like That',
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1588709921l/47924._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/47924.I_Like_It_Like_That',
        ratingsAvg: '3.58',
        ratingsNumber: '13423',
        rating: '2',
    },
    {
        title: "Because I'm Worth It",
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2003',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/47922.Because_I_m_Worth_It',
        ratingsAvg: '3.56',
        ratingsNumber: '15385',
        rating: '2',
    },
    {
        title: "You're the One That I Want",
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2004',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/22193.You_re_the_One_That_I_Want',
        ratingsAvg: '3.71',
        ratingsNumber: '18597',
        rating: '2',
    },
    {
        title: 'All I Want is Everything',
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2003',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/22192.All_I_Want_is_Everything',
        ratingsAvg: '3.55',
        ratingsNumber: '17730',
        rating: '2',
    },
    {
        title: 'You Know You Love Me',
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2002',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1388599269l/37451._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/37451.You_Know_You_Love_Me',
        ratingsAvg: '3.52',
        ratingsNumber: '21181',
        rating: '2',
    },
    {
        title: 'Gossip Girl',
        author: 'Cecily von Ziegesar',
        authorLink: 'https://www.goodreads.com/author/show/12665.Cecily_von_Ziegesar',
        year: '2002',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1398814332l/22188._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/22188.Gossip_Girl',
        ratingsAvg: '3.52',
        ratingsNumber: '58905',
        rating: '2',
    },
    {
        title: 'The Princess Diaries',
        author: 'Meg Cabot',
        authorLink: 'https://www.goodreads.com/author/show/11654.Meg_Cabot',
        year: '2001',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1355011082l/38980._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/38980.The_Princess_Diaries',
        ratingsAvg: '3.79',
        ratingsNumber: '233344',
        rating: '4',
    },
    {
        title: 'Under the Dome',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1511289992l/6320534._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6320534-under-the-dome',
        ratingsAvg: '3.91',
        ratingsNumber: '257084',
        rating: '4',
    },
    {
        title: '\u0648\u0642\u0635\u0635 \u0623\u062e\u0631\u0649 1408',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1393854601l/20957235._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/20957235-1408',
        ratingsAvg: '3.91',
        ratingsNumber: '6821',
        rating: '3',
    },
    {
        title: 'Der Augensammler',
        author: 'Sebastian Fitzek',
        authorLink: 'https://www.goodreads.com/author/show/190305.Sebastian_Fitzek',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327999845l/7789265._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7789265-der-augensammler',
        ratingsAvg: '4.10',
        ratingsNumber: '6891',
        rating: '3',
    },
    {
        title: 'Die Tochter der Wanderhure',
        author: 'Iny Lorentz',
        authorLink: 'https://www.goodreads.com/author/show/359856.Iny_Lorentz',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1328201532l/4744216._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/4744216-die-tochter-der-wanderhure',
        ratingsAvg: '3.74',
        ratingsNumber: '398',
        rating: '1',
    },
    {
        title: 'Das Verm\u00e4chtnis der Wanderhure',
        author: 'Iny Lorentz',
        authorLink: 'https://www.goodreads.com/author/show/359856.Iny_Lorentz',
        year: '2007',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1312006.Das_Verm_chtnis_der_Wanderhure',
        ratingsAvg: '3.72',
        ratingsNumber: '688',
        rating: '2',
    },
    {
        title: 'Die Kastellanin',
        author: 'Iny Lorentz',
        authorLink: 'https://www.goodreads.com/author/show/359856.Iny_Lorentz',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1312281.Die_Kastellanin',
        ratingsAvg: '3.71',
        ratingsNumber: '898',
        rating: '2',
    },
    {
        title: 'Die Wanderhure',
        author: 'Iny Lorentz',
        authorLink: 'https://www.goodreads.com/author/show/359856.Iny_Lorentz',
        year: '2005',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1312007.Die_Wanderhure',
        ratingsAvg: '3.75',
        ratingsNumber: '2513',
        rating: '4',
    },
    {
        title: 'Doctor Sleep',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1510335480l/16130549._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/16130549-doctor-sleep',
        ratingsAvg: '4.12',
        ratingsNumber: '195814',
        rating: '2',
    },
    {
        title: 'Joyland',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2013',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1348154483l/13596166._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13596166-joyland',
        ratingsAvg: '3.90',
        ratingsNumber: '117456',
        rating: '2',
    },
    {
        title: 'Nightmares and Dreamscapes',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '1994',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1370950676l/10588._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/10588.Nightmares_and_Dreamscapes',
        ratingsAvg: '3.94',
        ratingsNumber: '73075',
        rating: '3',
    },
    {
        title: 'The Long Walk',
        author: 'Richard Bachman',
        authorLink: 'https://www.goodreads.com/author/show/5858.Richard_Bachman',
        year: '1999',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1309212400l/9014._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/9014.The_Long_Walk',
        ratingsAvg: '4.08',
        ratingsNumber: '126493',
        rating: '4',
    },
    {
        title: 'Duma Key',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1553184687l/472343._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/472343.Duma_Key',
        ratingsAvg: '3.95',
        ratingsNumber: '100210',
        rating: '3',
    },
    {
        title: 'The Girl Who Loved Tom Gordon',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1580235339l/11564._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/11564.The_Girl_Who_Loved_Tom_Gordon',
        ratingsAvg: '3.61',
        ratingsNumber: '132866',
        rating: '3',
    },
    {
        title: 'The Lost Honor of Katharina Blum',
        author: 'Heinrich B\u00f6ll',
        authorLink: 'https://www.goodreads.com/author/show/39326.Heinrich_B_ll',
        year: '1994',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1338908394l/69880._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/69880.The_Lost_Honor_of_Katharina_Blum',
        ratingsAvg: '3.69',
        ratingsNumber: '11300',
        rating: '2',
    },
    {
        title: 'The Swarm',
        author: 'Frank Sch\u00e4tzing',
        authorLink: 'https://www.goodreads.com/author/show/38392.Frank_Sch_tzing',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1442648971l/68146._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/68146.The_Swarm',
        ratingsAvg: '4.06',
        ratingsNumber: '16356',
        rating: '3',
    },
    {
        title: 'M\u00e4ngelexemplar',
        author: 'Sarah Kuttner',
        authorLink: 'https://www.goodreads.com/author/show/627909.Sarah_Kuttner',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1296670092l/6334076._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/6334076-m-ngelexemplar',
        ratingsAvg: '3.56',
        ratingsNumber: '2936',
        rating: '2',
    },
    {
        title: 'Woyzeck',
        author: 'Georg B\u00fcchner',
        authorLink: 'https://www.goodreads.com/author/show/94147.Georg_B_chner',
        year: '1998',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1601576977l/571938._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/571938.Woyzeck',
        ratingsAvg: '3.32',
        ratingsNumber: '9122',
        rating: '3',
    },
    {
        title: 'Emil and the Detectives',
        author: 'Erich K\u00e4stner',
        authorLink: 'https://www.goodreads.com/author/show/6519.Erich_K_stner',
        year: '2001',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/67270.Emil_and_the_Detectives',
        ratingsAvg: '3.98',
        ratingsNumber: '7958',
        rating: '2',
    },
    {
        title: 'Der Sandmann',
        author: 'E.T.A. Hoffmann',
        authorLink: 'https://www.goodreads.com/author/show/7267068.E_T_A_Hoffmann',
        year: '1986',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/680596.Der_Sandmann',
        ratingsAvg: '3.71',
        ratingsNumber: '10056',
        rating: '2',
    },
    {
        title: 'Effi Briest',
        author: 'Theodor Fontane',
        authorLink: 'https://www.goodreads.com/author/show/68613.Theodor_Fontane',
        year: '1976',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1499908468l/442296._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/442296.Effi_Briest',
        ratingsAvg: '3.26',
        ratingsNumber: '9603',
        rating: '3',
    },
    {
        title: 'The Little Witch',
        author: 'Otfried Preu\u00dfler',
        authorLink: 'https://www.goodreads.com/author/show/127314.Otfried_Preu_ler',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1384615719l/316804._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/316804.The_Little_Witch',
        ratingsAvg: '4.19',
        ratingsNumber: '9095',
        rating: '2',
    },
    {
        title: 'The Little Ghost',
        author: 'Otfried Preu\u00dfler',
        authorLink: 'https://www.goodreads.com/author/show/127314.Otfried_Preu_ler',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1392994064l/316808._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/316808.The_Little_Ghost',
        ratingsAvg: '4.20',
        ratingsNumber: '5546',
        rating: '1',
    },
    {
        title: 'Die Verwandlung',
        author: 'Franz Kafka',
        authorLink: 'https://www.goodreads.com/author/show/5223.Franz_Kafka',
        year: '1986',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1567352506l/930871._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/930871.Die_Verwandlung',
        ratingsAvg: '3.82',
        ratingsNumber: '642539',
        rating: '1',
    },
    {
        title: 'Krabat',
        author: 'Otfried Preu\u00dfler',
        authorLink: 'https://www.goodreads.com/author/show/127314.Otfried_Preu_ler',
        year: '1988',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1334151882l/472626._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/472626.Krabat',
        ratingsAvg: '4.02',
        ratingsNumber: '12533',
        rating: '3',
    },
    {
        title: 'Faust, First Part',
        author: 'Johann Wolfgang von Goethe',
        authorLink: 'https://www.goodreads.com/author/show/285217.Johann_Wolfgang_von_Goethe',
        year: '1988',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/14706.Faust_First_Part',
        ratingsAvg: '3.93',
        ratingsNumber: '58234',
        rating: '2',
    },
    {
        title: 'Inkheart',
        author: 'Cornelia Funke',
        authorLink: 'https://www.goodreads.com/author/show/15873.Cornelia_Funke',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1538266636l/28194._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28194.Inkheart',
        ratingsAvg: '3.88',
        ratingsNumber: '369189',
        rating: '3',
    },
    {
        title: 'The Neverending Story',
        author: 'Michael Ende',
        authorLink: 'https://www.goodreads.com/author/show/15619.Michael_Ende',
        year: '1997',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1554230952l/27712._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/27712.The_Neverending_Story',
        ratingsAvg: '4.17',
        ratingsNumber: '161727',
        rating: '4',
    },
    {
        title: 'Perfume: The Story of a Murderer',
        author: 'Patrick S\u00fcskind',
        authorLink: 'https://www.goodreads.com/author/show/39402.Patrick_S_skind',
        year: '1987',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1409112276l/343._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/343.Perfume',
        ratingsAvg: '4.02',
        ratingsNumber: '327672',
        rating: '3',
    },
    {
        title: 'Smaragdgr\u00fcn',
        author: 'Kerstin Gier',
        authorLink: 'https://www.goodreads.com/author/show/298438.Kerstin_Gier',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1345808677l/7544603._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7544603-smaragdgr-n',
        ratingsAvg: '4.18',
        ratingsNumber: '66747',
        rating: '4',
    },
    {
        title: 'Saphirblau',
        author: 'Kerstin Gier',
        authorLink: 'https://www.goodreads.com/author/show/298438.Kerstin_Gier',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327251290l/6919272._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6919272-saphirblau',
        ratingsAvg: '4.16',
        ratingsNumber: '74996',
        rating: '2',
    },
    {
        title: 'Ruby Red',
        author: 'Kerstin Gier',
        authorLink: 'https://www.goodreads.com/author/show/298438.Kerstin_Gier',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1312036605l/8835379._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/8835379-ruby-red',
        ratingsAvg: '4.10',
        ratingsNumber: '111781',
        rating: '2',
    },
    {
        title: 'Divergent',
        author: 'Veronica Roth',
        authorLink: 'https://www.goodreads.com/author/show/4039811.Veronica_Roth',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1588455221l/13335037._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13335037-divergent',
        ratingsAvg: '4.19',
        ratingsNumber: '2904399',
        rating: '2',
    },
    {
        title: 'Insurgent',
        author: 'Veronica Roth',
        authorLink: 'https://www.goodreads.com/author/show/4039811.Veronica_Roth',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1325667729l/11735983._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/11735983-insurgent',
        ratingsAvg: '4.02',
        ratingsNumber: '1167312',
        rating: '2',
    },
    {
        title: 'Christine',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2001',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1513766289l/10629._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10629.Christine',
        ratingsAvg: '3.79',
        ratingsNumber: '201940',
        rating: '2',
    },
    {
        title: 'The High Lord',
        author: 'Trudi Canavan',
        authorLink: 'https://www.goodreads.com/author/show/15890.Trudi_Canavan',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327357942l/28251._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28251.The_High_Lord',
        ratingsAvg: '4.12',
        ratingsNumber: '51147',
        rating: '3',
    },
    {
        title: "The Magicians' Guild",
        author: 'Trudi Canavan',
        authorLink: 'https://www.goodreads.com/author/show/15890.Trudi_Canavan',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327356788l/28249._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28249.The_Magicians_Guild',
        ratingsAvg: '3.94',
        ratingsNumber: '72159',
        rating: '3',
    },
    {
        title: 'The Novice',
        author: 'Trudi Canavan',
        authorLink: 'https://www.goodreads.com/author/show/15890.Trudi_Canavan',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327357280l/28250._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/28250.The_Novice',
        ratingsAvg: '4.05',
        ratingsNumber: '51408',
        rating: '3',
    },
    {
        title: 'The Green Mile',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '1996',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1373903563l/11566._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/11566.The_Green_Mile',
        ratingsAvg: '4.45',
        ratingsNumber: '248662',
        rating: '5',
    },
    {
        title: '11/22/63',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327876792l/10644930._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10644930-11-22-63',
        ratingsAvg: '4.31',
        ratingsNumber: '419845',
        rating: '4',
    },
    {
        title: 'Carrie',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2005',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/10592.Carrie',
        ratingsAvg: '3.96',
        ratingsNumber: '519975',
        rating: '3',
    },
    {
        title: 'It',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '2002',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1309376909l/18342._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/18342.It',
        ratingsAvg: '4.24',
        ratingsNumber: '801669',
        rating: '3',
    },
    {
        title: 'The Stand',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '1990',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1213131305l/149267._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/149267.The_Stand',
        ratingsAvg: '4.34',
        ratingsNumber: '615558',
        rating: '4',
    },
    {
        title: 'The Shining',
        author: 'Stephen King',
        authorLink: 'https://www.goodreads.com/author/show/3389.Stephen_King',
        year: '1980',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1353277730l/11588._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/11588.The_Shining',
        ratingsAvg: '4.23',
        ratingsNumber: '1125831',
        rating: '5',
    },
    {
        title: 'Graceling',
        author: 'Kristin Cashore',
        authorLink: 'https://www.goodreads.com/author/show/1373880.Kristin_Cashore',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1331548394l/3236307._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/3236307-graceling',
        ratingsAvg: '4.08',
        ratingsNumber: '384457',
        rating: '3',
    },
    {
        title: 'Phantom',
        author: 'Terry Goodkind',
        authorLink: 'https://www.goodreads.com/author/show/3441.Terry_Goodkind',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1574449879l/43884._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/43884.Phantom',
        ratingsAvg: '3.99',
        ratingsNumber: '42869',
        rating: '1',
    },
    {
        title: 'Chainfire',
        author: 'Terry Goodkind',
        authorLink: 'https://www.goodreads.com/author/show/3441.Terry_Goodkind',
        year: '2005',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/43887.Chainfire',
        ratingsAvg: '3.93',
        ratingsNumber: '49412',
        rating: '1',
    },
    {
        title: 'Naked Empire',
        author: 'Terry Goodkind',
        authorLink: 'https://www.goodreads.com/author/show/3441.Terry_Goodkind',
        year: '2004',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/43893.Naked_Empire',
        ratingsAvg: '3.80',
        ratingsNumber: '50925',
        rating: '3',
    },
    {
        title: 'Soul of the Fire',
        author: 'Terry Goodkind',
        authorLink: 'https://www.goodreads.com/author/show/3441.Terry_Goodkind',
        year: '2000',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1562779000l/761732._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/761732.Soul_of_the_Fire',
        ratingsAvg: '3.85',
        ratingsNumber: '62939',
        rating: '3',
    },
    {
        title: 'The Pillars of Creation',
        author: 'Terry Goodkind',
        authorLink: 'https://www.goodreads.com/author/show/3441.Terry_Goodkind',
        year: '2002',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1516742944l/5038._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/5038.The_Pillars_of_Creation',
        ratingsAvg: '3.68',
        ratingsNumber: '55486',
        rating: '1',
    },
    {
        title: 'Faith of the Fallen',
        author: 'Terry Goodkind',
        authorLink: 'https://www.goodreads.com/author/show/3441.Terry_Goodkind',
        year: '2001',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1516509957l/121127._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/121127.Faith_of_the_Fallen',
        ratingsAvg: '4.09',
        ratingsNumber: '68134',
        rating: '3',
    },
    {
        title: 'Temple of the Winds',
        author: 'Terry Goodkind',
        authorLink: 'https://www.goodreads.com/author/show/3441.Terry_Goodkind',
        year: '2007',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/253058.Temple_of_the_Winds',
        ratingsAvg: '4.01',
        ratingsNumber: '76099',
        rating: '3',
    },
    {
        title: 'Blood of the Fold',
        author: 'Terry Goodkind',
        authorLink: 'https://www.goodreads.com/author/show/3441.Terry_Goodkind',
        year: '1997',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1562778918l/43892._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/43892.Blood_of_the_Fold',
        ratingsAvg: '3.99',
        ratingsNumber: '74243',
        rating: '1',
    },
    {
        title: 'Stone of Tears',
        author: 'Terry Goodkind',
        authorLink: 'https://www.goodreads.com/author/show/3441.Terry_Goodkind',
        year: '1995',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1478930875l/234184._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/234184.Stone_of_Tears',
        ratingsAvg: '4.09',
        ratingsNumber: '105759',
        rating: '1',
    },
    {
        title: "Wizard's First Rule",
        author: 'Terry Goodkind',
        authorLink: 'https://www.goodreads.com/author/show/3441.Terry_Goodkind',
        year: '2003',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/43889.Wizard_s_First_Rule',
        ratingsAvg: '4.12',
        ratingsNumber: '229113',
        rating: '3',
    },
    {
        title: 'Fantastic Beasts and Where to Find Them',
        author: 'Newt Scamander',
        authorLink: 'https://www.goodreads.com/author/show/19981845.Newt_Scamander',
        year: '2001',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1303738520l/41899._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/41899.Fantastic_Beasts_and_Where_to_Find_Them',
        ratingsAvg: '3.98',
        ratingsNumber: '329781',
        rating: '3',
    },
    {
        title: 'Dragon Rider',
        author: 'Cornelia Funke',
        authorLink: 'https://www.goodreads.com/author/show/15873.Cornelia_Funke',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1328004947l/100464._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/100464.Dragon_Rider',
        ratingsAvg: '3.97',
        ratingsNumber: '77334',
        rating: '4',
    },
    {
        title: 'The Golden Compass',
        author: 'Philip Pullman',
        authorLink: 'https://www.goodreads.com/author/show/3618.Philip_Pullman',
        year: '1996',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1505766203l/119322._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/119322.The_Golden_Compass',
        ratingsAvg: '3.99',
        ratingsNumber: '1283378',
        rating: '5',
    },
    {
        title: 'Eragon',
        author: 'Christopher Paolini',
        authorLink: 'https://www.goodreads.com/author/show/8349.Christopher_Paolini',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1366212852l/113436._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/113436.Eragon',
        ratingsAvg: '3.90',
        ratingsNumber: '1436243',
        rating: '3',
    },
    {
        title: 'Dead in the Family',
        author: 'Charlaine Harris',
        authorLink: 'https://www.goodreads.com/author/show/17061.Charlaine_Harris',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1290479828l/7091488._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7091488-dead-in-the-family',
        ratingsAvg: '3.89',
        ratingsNumber: '150180',
        rating: '1',
    },
    {
        title: 'Dead and Gone',
        author: 'Charlaine Harris',
        authorLink: 'https://www.goodreads.com/author/show/17061.Charlaine_Harris',
        year: '2009',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/5161066-dead-and-gone',
        ratingsAvg: '4.02',
        ratingsNumber: '173833',
        rating: '1',
    },
    {
        title: 'From Dead to Worse',
        author: 'Charlaine Harris',
        authorLink: 'https://www.goodreads.com/author/show/17061.Charlaine_Harris',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1525214511l/2233407._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/2233407.From_Dead_to_Worse',
        ratingsAvg: '4.01',
        ratingsNumber: '174189',
        rating: '1',
    },
    {
        title: 'Definitely Dead',
        author: 'Charlaine Harris',
        authorLink: 'https://www.goodreads.com/author/show/17061.Charlaine_Harris',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1468562748l/140079._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/140079.Definitely_Dead',
        ratingsAvg: '4.01',
        ratingsNumber: '189294',
        rating: '3',
    },
    {
        title: 'All Together Dead',
        author: 'Charlaine Harris',
        authorLink: 'https://www.goodreads.com/author/show/17061.Charlaine_Harris',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1417964062l/140075._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/140075.All_Together_Dead',
        ratingsAvg: '4.05',
        ratingsNumber: '186388',
        rating: '1',
    },
    {
        title: 'Dead as a Doornail',
        author: 'Charlaine Harris',
        authorLink: 'https://www.goodreads.com/author/show/17061.Charlaine_Harris',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1468562419l/170210._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/170210.Dead_as_a_Doornail',
        ratingsAvg: '4.00',
        ratingsNumber: '203400',
        rating: '2',
    },
    {
        title: 'Club Dead',
        author: 'Charlaine Harris',
        authorLink: 'https://www.goodreads.com/author/show/17061.Charlaine_Harris',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1329501590l/140082._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/140082.Club_Dead',
        ratingsAvg: '4.02',
        ratingsNumber: '219633',
        rating: '2',
    },
    {
        title: 'Dead to the World',
        author: 'Charlaine Harris',
        authorLink: 'https://www.goodreads.com/author/show/17061.Charlaine_Harris',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1468561630l/140077._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/140077.Dead_to_the_World',
        ratingsAvg: '4.12',
        ratingsNumber: '243363',
        rating: '2',
    },
    {
        title: 'Living Dead in Dallas',
        author: 'Charlaine Harris',
        authorLink: 'https://www.goodreads.com/author/show/17061.Charlaine_Harris',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1329501808l/110494._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/110494.Living_Dead_in_Dallas',
        ratingsAvg: '3.96',
        ratingsNumber: '234112',
        rating: '2',
    },
    {
        title: 'Dead Until Dark',
        author: 'Charlaine Harris',
        authorLink: 'https://www.goodreads.com/author/show/17061.Charlaine_Harris',
        year: '2001',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1468560853l/301082._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/301082.Dead_Until_Dark',
        ratingsAvg: '3.96',
        ratingsNumber: '495575',
        rating: '2',
    },
    {
        title: 'Twilight',
        author: 'Stephenie Meyer',
        authorLink: 'https://www.goodreads.com/author/show/941441.Stephenie_Meyer',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1361039443l/41865._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/41865.Twilight',
        ratingsAvg: '3.60',
        ratingsNumber: '4961618',
        rating: '2',
    },
    {
        title: 'Wuthering Heights',
        author: 'Emily Bront\u00eb',
        authorLink: 'https://www.goodreads.com/author/show/4191.Emily_Bront_',
        year: '2002',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1587655304l/6185._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6185.Wuthering_Heights',
        ratingsAvg: '3.86',
        ratingsNumber: '1341752',
        rating: '3',
    },
    {
        title: 'The Girl with the Dragon Tattoo',
        author: 'Stieg Larsson',
        authorLink: 'https://www.goodreads.com/author/show/706255.Stieg_Larsson',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327868566l/2429135._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/2429135.The_Girl_with_the_Dragon_Tattoo',
        ratingsAvg: '4.14',
        ratingsNumber: '2606523',
        rating: '4',
    },
    {
        title: 'One Day',
        author: 'David Nicholls',
        authorLink: 'https://www.goodreads.com/author/show/46118.David_Nicholls',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327873020l/6280118._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6280118-one-day',
        ratingsAvg: '3.80',
        ratingsNumber: '286605',
        rating: '2',
    },
    {
        title: "My Sister's Keeper",
        author: 'Jodi Picoult',
        authorLink: 'https://www.goodreads.com/author/show/7128.Jodi_Picoult',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1369504683l/10917._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10917.My_Sister_s_Keeper',
        ratingsAvg: '4.08',
        ratingsNumber: '1056332',
        rating: '2',
    },
    {
        title: 'Water for Elephants',
        author: 'Sara Gruen',
        authorLink: 'https://www.goodreads.com/author/show/24556.Sara_Gruen',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1494428973l/43641._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/43641.Water_for_Elephants',
        ratingsAvg: '4.09',
        ratingsNumber: '1378902',
        rating: '4',
    },
    {
        title: 'Angels & Demons',
        author: 'Dan Brown',
        authorLink: 'https://www.goodreads.com/author/show/630.Dan_Brown',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1558711679l/960._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/960.Angels_Demons',
        ratingsAvg: '3.90',
        ratingsNumber: '2674233',
        rating: '1',
    },
    {
        title: 'Room',
        author: 'Emma Donoghue',
        authorLink: 'https://www.goodreads.com/author/show/23613.Emma_Donoghue',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1554546259l/7937843._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7937843-room',
        ratingsAvg: '4.06',
        ratingsNumber: '713872',
        rating: '3',
    },
    {
        title: 'The Diary of a Young Girl',
        author: 'Anne Frank',
        authorLink: 'https://www.goodreads.com/author/show/3720.Anne_Frank',
        year: '1993',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1560816565l/48855._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/48855.The_Diary_of_a_Young_Girl',
        ratingsAvg: '4.15',
        ratingsNumber: '2739064',
        rating: '3',
    },
    {
        title: 'The Picture of Dorian Gray',
        author: 'Oscar Wilde',
        authorLink: 'https://www.goodreads.com/author/show/3565.Oscar_Wilde',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1546103428l/5297._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/5297.The_Picture_of_Dorian_Gray',
        ratingsAvg: '4.08',
        ratingsNumber: '965291',
        rating: '2',
    },
    {
        title: 'Persuasion',
        author: 'Jane Austen',
        authorLink: 'https://www.goodreads.com/author/show/1265.Jane_Austen',
        year: '2004',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/2156.Persuasion',
        ratingsAvg: '4.14',
        ratingsNumber: '524092',
        rating: '3',
    },
    {
        title: 'Emma',
        author: 'Jane Austen',
        authorLink: 'https://www.goodreads.com/author/show/1265.Jane_Austen',
        year: '2003',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1373627931l/6969._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6969.Emma',
        ratingsAvg: '4.01',
        ratingsNumber: '651507',
        rating: '3',
    },
    {
        title: 'Lord of the Flies',
        author: 'William Golding',
        authorLink: 'https://www.goodreads.com/author/show/306.William_Golding',
        year: '1999',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327869409l/7624._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7624.Lord_of_the_Flies',
        ratingsAvg: '3.69',
        ratingsNumber: '2261953',
        rating: '3',
    },
    {
        title: 'Romeo and Juliet',
        author: 'William Shakespeare',
        authorLink: 'https://www.goodreads.com/author/show/947.William_Shakespeare',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1572098085l/18135._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/18135.Romeo_and_Juliet',
        ratingsAvg: '3.75',
        ratingsNumber: '2054119',
        rating: '2',
    },
    {
        title: 'Brave New World',
        author: 'Aldous Huxley',
        authorLink: 'https://www.goodreads.com/author/show/3487.Aldous_Huxley',
        year: '1998',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1575509280l/5129._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/5129.Brave_New_World',
        ratingsAvg: '3.99',
        ratingsNumber: '1440253',
        rating: '2',
    },
    {
        title: '1984',
        author: 'George Orwell',
        authorLink: 'https://www.goodreads.com/author/show/3706.George_Orwell',
        year: '1950',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1348990566l/5470._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/5470.1984',
        ratingsAvg: '4.19',
        ratingsNumber: '3137561',
        rating: '3',
    },
    {
        title: 'Pride and Prejudice',
        author: 'Jane Austen',
        authorLink: 'https://www.goodreads.com/author/show/1265.Jane_Austen',
        year: '2000',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1320399351l/1885._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/1885.Pride_and_Prejudice',
        ratingsAvg: '4.26',
        ratingsNumber: '2995847',
        rating: '5',
    },
    {
        title: 'The Great Gatsby',
        author: 'F. Scott Fitzgerald',
        authorLink: 'https://www.goodreads.com/author/show/3190.F_Scott_Fitzgerald',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1490528560l/4671._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/4671.The_Great_Gatsby',
        ratingsAvg: '3.92',
        ratingsNumber: '3773168',
        rating: '3',
    },
    {
        title: "Alice's Adventures in Wonderland & Through the Looking-Glass",
        author: 'Lewis Carroll',
        authorLink: 'https://www.goodreads.com/author/show/8164.Lewis_Carroll',
        year: '2000',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327872220l/24213._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/24213.Alice_s_Adventures_in_Wonderland_Through_the_Looking_Glass',
        ratingsAvg: '4.06',
        ratingsNumber: '457947',
        rating: '2',
    },
    {
        title: 'Confessions of a Shopaholic',
        author: 'Sophie Kinsella',
        authorLink: 'https://www.goodreads.com/author/show/6160.Sophie_Kinsella',
        year: '2003',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1546503932l/9416._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/9416.Confessions_of_a_Shopaholic',
        ratingsAvg: '3.65',
        ratingsNumber: '647118',
        rating: '2',
    },
    {
        title: 'The Devil Wears Prada',
        author: 'Lauren Weisberger',
        authorLink: 'https://www.goodreads.com/author/show/3490.Lauren_Weisberger',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/5139.The_Devil_Wears_Prada',
        ratingsAvg: '3.75',
        ratingsNumber: '786147',
        rating: '2',
    },
    {
        title: 'House of Many Ways',
        author: 'Diana Wynne Jones',
        authorLink: 'https://www.goodreads.com/author/show/4260.Diana_Wynne_Jones',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1405010672l/5646218._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/5646218-house-of-many-ways',
        ratingsAvg: '4.05',
        ratingsNumber: '27761',
        rating: '4',
    },
    {
        title: 'The Two Towers',
        author: 'J.R.R. Tolkien',
        authorLink: 'https://www.goodreads.com/author/show/656983.J_R_R_Tolkien',
        year: '1999',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1306427354l/727800._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/727800.The_Two_Towers',
        ratingsAvg: '4.44',
        ratingsNumber: '719027',
        rating: '3',
    },
    {
        title: 'The Return of the King',
        author: 'J.R.R. Tolkien',
        authorLink: 'https://www.goodreads.com/author/show/656983.J_R_R_Tolkien',
        year: '1999',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1306539821l/727810._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/727810.The_Return_of_the_King',
        ratingsAvg: '4.53',
        ratingsNumber: '678190',
        rating: '3',
    },
    {
        title: 'Momo',
        author: 'Michael Ende',
        authorLink: 'https://www.goodreads.com/author/show/15619.Michael_Ende',
        year: null,
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1422893272l/68813._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/68813.Momo',
        ratingsAvg: '4.32',
        ratingsNumber: '53596',
        rating: '5',
    },
    {
        title: 'Die Stadt der Tr\u00e4umenden B\u00fccher',
        author: 'Walter Moers',
        authorLink: 'https://www.goodreads.com/author/show/34878.Walter_Moers',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1491113.Die_Stadt_der_Tr_umenden_B_cher',
        ratingsAvg: '4.37',
        ratingsNumber: '15905',
        rating: '5',
    },
    {
        title: "Die 13\u00bd Leben des K\u00e4pt'n Blaub\u00e4r",
        author: 'Walter Moers',
        authorLink: 'https://www.goodreads.com/author/show/34878.Walter_Moers',
        year: '2001',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1349781487l/1491130._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/1491130.Die_13_Leben_des_K_pt_n_Blaub_r',
        ratingsAvg: '4.25',
        ratingsNumber: '18549',
        rating: '5',
    },
    {
        title: 'The Fellowship of the Ring',
        author: 'J.R.R. Tolkien',
        authorLink: 'https://www.goodreads.com/author/show/656983.J_R_R_Tolkien',
        year: '1999',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1307111070l/727798._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/727798.The_Fellowship_of_the_Ring',
        ratingsAvg: '4.36',
        ratingsNumber: '2354283',
        rating: '4',
    },
    {
        title: 'I Am the Messenger',
        author: 'Markus Zusak',
        authorLink: 'https://www.goodreads.com/author/show/11466.Markus_Zusak',
        year: '2006',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1398483261l/19057._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/19057.I_Am_the_Messenger',
        ratingsAvg: '4.05',
        ratingsNumber: '136433',
        rating: '4',
    },
    {
        title: 'Das Labyrinth der Tr\u00e4umenden B\u00fccher',
        author: 'Walter Moers',
        authorLink: 'https://www.goodreads.com/author/show/34878.Walter_Moers',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1328007670l/11177312._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/11177312-das-labyrinth-der-tr-umenden-b-cher',
        ratingsAvg: '3.57',
        ratingsNumber: '4773',
        rating: '3',
    },
    {
        title: 'Der eingebildete Kranke',
        author: 'Moli\u00e8re',
        authorLink: 'https://www.goodreads.com/author/show/29837.Moli_re',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1430083704l/3291767._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/3291767-der-eingebildete-kranke',
        ratingsAvg: '3.75',
        ratingsNumber: '9111',
        rating: '3',
    },
    {
        title: "Alice's Adventures in Wonderland",
        author: 'Lewis Carroll',
        authorLink: 'https://www.goodreads.com/author/show/8164.Lewis_Carroll',
        year: '1984',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/853469.Alice_s_Adventures_in_Wonderland',
        ratingsAvg: '4.01',
        ratingsNumber: '245060',
        rating: '3',
    },
    {
        title: 'Der Schrecksenmeister',
        author: 'Walter Moers',
        authorLink: 'https://www.goodreads.com/author/show/34878.Walter_Moers',
        year: '2007',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1491117.Der_Schrecksenmeister',
        ratingsAvg: '4.18',
        ratingsNumber: '5924',
        rating: '4',
    },
    {
        title: 'Die Vermessung der Welt',
        author: 'Daniel Kehlmann',
        authorLink: 'https://www.goodreads.com/author/show/163635.Daniel_Kehlmann',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1449313699l/1526595._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/1526595.Die_Vermessung_der_Welt',
        ratingsAvg: '3.74',
        ratingsNumber: '13353',
        rating: '4',
    },
    {
        title: 'The Importance of Being Earnest',
        author: 'Oscar Wilde',
        authorLink: 'https://www.goodreads.com/author/show/3565.Oscar_Wilde',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1313522504l/122638._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/122638.The_Importance_of_Being_Earnest',
        ratingsAvg: '4.18',
        ratingsNumber: '302003',
        rating: '4',
    },
    {
        title: 'Harry Potter and the Prisoner of Azkaban',
        author: 'J.K. Rowling',
        authorLink: 'https://www.goodreads.com/author/show/1077326.J_K_Rowling',
        year: '1999',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1562747693l/464164._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/464164.Harry_Potter_and_the_Prisoner_of_Azkaban',
        ratingsAvg: '4.57',
        ratingsNumber: '2804250',
        rating: '4',
    },
    {
        title: 'Katze mit Hut',
        author: 'Simon Ruge',
        authorLink: 'https://www.goodreads.com/author/show/407509.Simon_Ruge',
        year: '2001',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/889668.Katze_mit_Hut',
        ratingsAvg: '4.51',
        ratingsNumber: '43',
        rating: '2',
    },
    {
        title: "The Hitch Hiker's Guide to the Galaxy: A Trilogy in Five Parts",
        author: 'Douglas Adams',
        authorLink: 'https://www.goodreads.com/author/show/4.Douglas_Adams',
        year: '1995',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1322913171l/372299._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/372299.The_Hitch_Hiker_s_Guide_to_the_Galaxy',
        ratingsAvg: '4.40',
        ratingsNumber: '15588',
        rating: '2',
    },
    {
        title: 'Charlie and the Chocolate Factory',
        author: 'Roald Dahl',
        authorLink: 'https://www.goodreads.com/author/show/4273.Roald_Dahl',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1550934403l/109089._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/109089.Charlie_and_the_Chocolate_Factory',
        ratingsAvg: '4.14',
        ratingsNumber: '664234',
        rating: '3',
    },
    {
        title: 'Harry Potter and the Chamber of Secrets',
        author: 'J.K. Rowling',
        authorLink: 'https://www.goodreads.com/author/show/1077326.J_K_Rowling',
        year: '1998',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1553352368l/224912._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/224912.Harry_Potter_and_the_Chamber_of_Secrets',
        ratingsAvg: '4.43',
        ratingsNumber: '2723743',
        rating: '4',
    },
    {
        title: 'Catching Fire',
        author: 'Suzanne Collins',
        authorLink: 'https://www.goodreads.com/author/show/153394.Suzanne_Collins',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1586722941l/6148028._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/6148028-catching-fire',
        ratingsAvg: '4.29',
        ratingsNumber: '2512118',
        rating: '4',
    },
    {
        title: 'Der Duft Des Sake: Roman',
        author: 'Joyce Lebra',
        authorLink: 'https://www.goodreads.com/author/show/763818.Joyce_Lebra',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1451564589l/12426669._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/12426669-der-duft-des-sake',
        ratingsAvg: '3.31',
        ratingsNumber: '816',
        rating: '2',
    },
    {
        title: 'Mockingjay',
        author: 'Suzanne Collins',
        authorLink: 'https://www.goodreads.com/author/show/153394.Suzanne_Collins',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1586722918l/7260188._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7260188-mockingjay',
        ratingsAvg: '4.04',
        ratingsNumber: '2346868',
        rating: '3',
    },
    {
        title: 'Cthulhu',
        author: 'H.P. Lovecraft',
        authorLink: 'https://www.goodreads.com/author/show/9494.H_P_Lovecraft',
        year: '2001',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1702340.Cthulhu',
        ratingsAvg: '4.01',
        ratingsNumber: '742',
        rating: '2',
    },
    {
        title: 'The Catcher in the Rye',
        author: 'J.D. Salinger',
        authorLink: 'https://www.goodreads.com/author/show/819789.J_D_Salinger',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327197188l/7967885._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7967885-the-catcher-in-the-rye',
        ratingsAvg: '3.81',
        ratingsNumber: '2734629',
        rating: '4',
    },
    {
        title: 'Der letzte Wunsch',
        author: 'Andrzej Sapkowski',
        authorLink: 'https://www.goodreads.com/author/show/38569.Andrzej_Sapkowski',
        year: '2007',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/1501477.Der_letzte_Wunsch',
        ratingsAvg: '4.13',
        ratingsNumber: '190518',
        rating: '3',
    },
    {
        title: 'The Hunger Games',
        author: 'Suzanne Collins',
        authorLink: 'https://www.goodreads.com/author/show/153394.Suzanne_Collins',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327962782l/7285601._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7285601-the-hunger-games',
        ratingsAvg: '4.33',
        ratingsNumber: '6372896',
        rating: '4',
    },
    {
        title: 'To Kill a Mockingbird',
        author: 'Harper Lee',
        authorLink: 'https://www.goodreads.com/author/show/1825.Harper_Lee',
        year: '1982',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1339392178l/37449._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/37449.To_Kill_a_Mockingbird',
        ratingsAvg: '4.28',
        ratingsNumber: '4497964',
        rating: '5',
    },
    {
        title: 'The Plays of Oscar Wilde',
        author: 'Oscar Wilde',
        authorLink: 'https://www.goodreads.com/author/show/3565.Oscar_Wilde',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1332274333l/13037945._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13037945-the-plays-of-oscar-wilde',
        ratingsAvg: '4.36',
        ratingsNumber: '1866',
        rating: '3',
    },
    {
        title: 'The Playbook',
        author: 'Barney Stinson',
        authorLink: 'https://www.goodreads.com/author/show/1967344.Barney_Stinson',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327922270l/8025809._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/8025809-the-playbook',
        ratingsAvg: '3.79',
        ratingsNumber: '3676',
        rating: '1',
    },
    {
        title: 'Fight Club',
        author: 'Chuck Palahniuk',
        authorLink: 'https://www.goodreads.com/author/show/2546.Chuck_Palahniuk',
        year: '1997',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327866366l/825840._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/825840.Fight_Club',
        ratingsAvg: '4.19',
        ratingsNumber: '498635',
        rating: '3',
    },
    {
        title: 'Spiegelzeit',
        author: 'Wolfgang Hohlbein',
        authorLink: 'https://www.goodreads.com/author/show/40343.Wolfgang_Hohlbein',
        year: '1999',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1389630184l/97250._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/97250.Spiegelzeit',
        ratingsAvg: '3.73',
        ratingsNumber: '658',
        rating: '2',
    },
    {
        title: 'The Adventures of Sherlock Holmes',
        author: 'Arthur Conan Doyle',
        authorLink: 'https://www.goodreads.com/author/show/2448.Arthur_Conan_Doyle',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1328863198l/7075707._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7075707-the-adventures-of-sherlock-holmes',
        ratingsAvg: '4.30',
        ratingsNumber: '242913',
        rating: '3',
    },
    {
        title: 'The Bro Code',
        author: 'Barney Stinson',
        authorLink: 'https://www.goodreads.com/author/show/1967344.Barney_Stinson',
        year: '2009',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/7515878-the-bro-code',
        ratingsAvg: '3.73',
        ratingsNumber: '9993',
        rating: '1',
    },
    {
        title: "Harry Potter and the Philosopher's Stone",
        author: 'J.K. Rowling',
        authorLink: 'https://www.goodreads.com/author/show/1077326.J_K_Rowling',
        year: '1997',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327190600l/72193._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/72193.Harry_Potter_and_the_Philosopher_s_Stone',
        ratingsAvg: '4.47',
        ratingsNumber: '7042902',
        rating: '5',
    },
    {
        title: 'Drehn Sie sich um, Frau Lot. Satiren aus Israel',
        author: 'Ephraim Kishon',
        authorLink: 'https://www.goodreads.com/author/show/111201.Ephraim_Kishon',
        year: '1982',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1431601561l/2149800._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/2149800.Drehn_Sie_sich_um_Frau_Lot_Satiren_aus_Israel',
        ratingsAvg: '4.17',
        ratingsNumber: '229',
        rating: '5',
    },
    {
        title: '1Q84',
        author: 'Haruki Murakami',
        authorLink: 'https://www.goodreads.com/author/show/3354.Haruki_Murakami',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1483103331l/10357575._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10357575-1q84',
        ratingsAvg: '3.92',
        ratingsNumber: '200871',
        rating: '3',
    },
    {
        title: 'Woa\u00dft du ibahapts, wia gern dass i di mog?',
        author: 'Sam McBratney',
        authorLink: 'https://www.goodreads.com/author/show/25838.Sam_McBratney',
        year: '2014',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1454000449l/1929631._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/1929631.Woa_t_du_ibahapts_wia_gern_dass_i_di_mog_',
        ratingsAvg: '4.37',
        ratingsNumber: '126051',
        rating: '0',
    },
    {
        title: 'Die Physiker',
        author: 'Friedrich D\u00fcrrenmatt',
        authorLink: 'https://www.goodreads.com/author/show/41616.Friedrich_D_rrenmatt',
        year: '2001',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1414321871l/452220._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/452220.Die_Physiker',
        ratingsAvg: '4.04',
        ratingsNumber: '17125',
        rating: '2',
    },
    {
        title: 'Die Kunst des klaren Denkens - 52 Denkfehler, die Sie besser anderen \u00fcberlassen',
        author: 'Rolf Dobelli',
        authorLink: 'https://www.goodreads.com/author/show/695505.Rolf_Dobelli',
        year: '2011',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1340313725l/13021074._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13021074-die-kunst-des-klaren-denkens---52-denkfehler-die-sie-besser-anderen-be',
        ratingsAvg: '3.86',
        ratingsNumber: '22832',
        rating: '0',
    },
    {
        title: "Maria, ihm schmeckt's nicht!",
        author: 'Jan Weiler',
        authorLink: 'https://www.goodreads.com/author/show/462511.Jan_Weiler',
        year: '2006',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/902555.Maria_ihm_schmeckt_s_nicht_',
        ratingsAvg: '3.66',
        ratingsNumber: '1630',
        rating: '2',
    },
    {
        title: 'Der kleine Prinz',
        author: 'Antoine de Saint-Exup\u00e9ry',
        authorLink: 'https://www.goodreads.com/author/show/1020792.Antoine_de_Saint_Exup_ry',
        year: '2007',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/942177.Der_kleine_Prinz',
        ratingsAvg: '4.31',
        ratingsNumber: '1361531',
        rating: '3',
    },
    {
        title: 'A Clash of Kings',
        author: 'George R.R. Martin',
        authorLink: 'https://www.goodreads.com/author/show/346732.George_R_R_Martin',
        year: '2002',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1567840212l/10572._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10572.A_Clash_of_Kings',
        ratingsAvg: '4.41',
        ratingsNumber: '787923',
        rating: '4',
    },
    {
        title: 'A Game of Thrones',
        author: 'George R.R. Martin',
        authorLink: 'https://www.goodreads.com/author/show/346732.George_R_R_Martin',
        year: '2005',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1562726234l/13496._SY160_.jpg',
        link: 'https://www.goodreads.com/book/show/13496.A_Game_of_Thrones',
        ratingsAvg: '4.45',
        ratingsNumber: '2001927',
        rating: '4',
    },
    {
        title: 'Marco Effekten',
        author: 'Jussi Adler-Olsen',
        authorLink: 'https://www.goodreads.com/author/show/1734716.Jussi_Adler_Olsen',
        year: '2012',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1350303698l/13623749._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/13623749-marco-effekten',
        ratingsAvg: '3.87',
        ratingsNumber: '14354',
        rating: '3',
    },
    {
        title: 'Journal 64',
        author: 'Jussi Adler-Olsen',
        authorLink: 'https://www.goodreads.com/author/show/1734716.Jussi_Adler_Olsen',
        year: '2010',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1424299324l/10008056._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/10008056-journal-64',
        ratingsAvg: '4.04',
        ratingsNumber: '19565',
        rating: '3',
    },
    {
        title: 'Flaskepost fra P',
        author: 'Jussi Adler-Olsen',
        authorLink: 'https://www.goodreads.com/author/show/1734716.Jussi_Adler_Olsen',
        year: '2009',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/7145565-flaskepost-fra-p',
        ratingsAvg: '4.02',
        ratingsNumber: '30159',
        rating: '3',
    },
    {
        title: 'Fasandr\u00e6berne',
        author: 'Jussi Adler-Olsen',
        authorLink: 'https://www.goodreads.com/author/show/1734716.Jussi_Adler_Olsen',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1261955287l/7145593._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/7145593-fasandr-berne',
        ratingsAvg: '3.87',
        ratingsNumber: '32754',
        rating: '3',
    },
    {
        title: 'The Keeper of Lost Causes',
        author: 'Jussi Adler-Olsen',
        authorLink: 'https://www.goodreads.com/author/show/1734716.Jussi_Adler_Olsen',
        year: '2011',
        imageUrl: 'https://s.gr-assets.com/assets/nophoto/book/111x148-bcc042a9c91a29c1d680899eff700a03.png',
        link: 'https://www.goodreads.com/book/show/10822858-the-keeper-of-lost-causes',
        ratingsAvg: '3.99',
        ratingsNumber: '65028',
        rating: '3',
    },
    {
        title: 'City of Bones',
        author: 'Cassandra Clare',
        authorLink: 'https://www.goodreads.com/author/show/150038.Cassandra_Clare',
        year: '2007',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1432730315l/256683._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/256683.City_of_Bones',
        ratingsAvg: '4.10',
        ratingsNumber: '1616546',
        rating: '3',
    },
    {
        title: 'City of Ashes',
        author: 'Cassandra Clare',
        authorLink: 'https://www.goodreads.com/author/show/150038.Cassandra_Clare',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1432730356l/1582996._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/1582996.City_of_Ashes',
        ratingsAvg: '4.17',
        ratingsNumber: '745533',
        rating: '2',
    },
    {
        title: 'City of Glass',
        author: 'Cassandra Clare',
        authorLink: 'https://www.goodreads.com/author/show/150038.Cassandra_Clare',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1369452339l/3777732._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/3777732-city-of-glass',
        ratingsAvg: '4.31',
        ratingsNumber: '794751',
        rating: '3',
    },
    {
        title: 'The Host',
        author: 'Stephenie Meyer',
        authorLink: 'https://www.goodreads.com/author/show/941441.Stephenie_Meyer',
        year: '2008',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1318009171l/1656001._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/1656001.The_Host',
        ratingsAvg: '3.84',
        ratingsNumber: '894464',
        rating: '2',
    },
    {
        title: 'Rumo & Die Wunder im Dunkeln',
        author: 'Walter Moers',
        authorLink: 'https://www.goodreads.com/author/show/34878.Walter_Moers',
        year: '2004',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1419791135l/1491119._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/1491119.Rumo_Die_Wunder_im_Dunkeln',
        ratingsAvg: '4.48',
        ratingsNumber: '9017',
        rating: '5',
    },
    {
        title: "Howl's Moving Castle",
        author: 'Diana Wynne Jones',
        authorLink: 'https://www.goodreads.com/author/show/4260.Diana_Wynne_Jones',
        year: '2009',
        imageUrl: 'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1404663071l/5985499._SX98_.jpg',
        link: 'https://www.goodreads.com/book/show/5985499-howl-s-moving-castle',
        ratingsAvg: '4.26',
        ratingsNumber: '215584',
        rating: '4',
    },
];
